<template lang="pug">
.clamp(ref="clamp" @click="$emit('click', $event)")
  div(ref="realText" :title="clamped ? text : null")
  div(hidden ref="slotContainer")
    slot
</template>

<script>
export default {
  name: "clamp",
  props: {
    lines: Number
  },
  data() {
    return {
      text: null,
      clamped: false
    };
  },
  mounted() {
    new ResizeObserver(this.calculate.bind(this)).observe(this.$refs.clamp);
    this.calculate();
    setTimeout(() => this.calculate(), 0);
    new MutationObserver(this.calculate.bind(this)).observe(
      this.$refs.slotContainer,
      {
        characterData: true,
        subtree: true
      }
    );
  },
  watch: {
    lines() {
      this.calculate();
    }
  },
  methods: {
    calculate() {
      if (!this.$refs.slotContainer || !this.$refs.realText) {
        setTimeout(() => this.calculate(), 0);
        return;
      }
      this.text = this.$refs.slotContainer.textContent;
      this.$refs.realText.textContent = "A";
      let oneLineHeight = this.$refs.realText.offsetHeight;
      this.$refs.realText.textContent = this.text;
      let totalHeight = this.$refs.realText.offsetHeight;
      if (totalHeight <= (oneLineHeight + 1) * this.lines) {
        this.clamped = false;
        return;
      }
      this.clamped = true;
      let words = this.text.split(" ");
      let lines = 0;
      let height = 0;
      for (let i = 0; i < words.length; i++) {
        let tryWords = words.slice(0, i);
        this.$refs.realText.textContent = tryWords.join(" ") + "...";
        let newHeight = this.$refs.realText.offsetHeight;
        if (newHeight > height) {
          lines++;
          height = newHeight;
          if (lines > this.lines) {
            this.$refs.realText.textContent =
              words.slice(0, i - 1).join(" ") + "...";
            return;
          }
        }
      }
    }
  }
};
</script>
