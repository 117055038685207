export default {
  namespaced: true,
  state: {
    token: null,
    memberships: [],
    totalMemberships: 0,
    allIntervals: [],
    acceptedPolicy: false,
    acceptedTerms: false
  },
  mutations: {
    setToken(state, value) {
      state.token = value;
    },
    setMemberships(state, value) {
      state.memberships = value;
    },
    setTotalMemberships(state, value) {
      state.totalMemberships = value;
    },
    addSiteToMembership(state, site) {
      const tenantMemberships = state.memberships
        .filter(
          m =>
            m.tenant.cloud_id === site.tenant.cloud_id &&
            (m.expires_at == null || Date.parse(m.expires_at) >= Date.now())
        )
        .sort((a, b) => {
          // sort by date first
          const maxDate = "275760-09-13";
          const dateA = a.expires_at || maxDate;
          const dateB = b.expires_at || maxDate;
          if (dateA > dateB) return -1;
          if (dateA < dateB) return 1;

          // then by role
          const roleA = a.role;
          const roleB = b.role;
          if (roleA < roleB) return -1;
          if (roleA > roleB) return 1;

          return 0;
        });
      if (
        !tenantMemberships.some(
          m => (m.role === "owner" || m.role === "admin") && m.sites === "all"
        )
      ) {
        // we need to add the new site to a membership
      }
    },
    acceptPolicy(state) {
      state.acceptedPolicy = true;
    },
    acceptTerms(state) {
      state.acceptedTerms = true;
    }
  }
};
