import { apiPatch } from "./api-requests";
import store from "@/store";
import defaultPrefs from "@/assets/json/default-prefs.json";
import defaultPrefsSites from "@/assets/json/default-prefs-sites.json";

let prefsUserGeneral;
let prefsSiteGeneral;
let cloudIdSite;

export function loadPrefsSiteUnik(obj) {
  prefsSiteGeneral = obj;
}

export function getPref(keys, def, obj, isSite) {
  if (isSite) {
    return store.state.sites.getPreference(keys, def);
  } else {
    return store.state.user.getPreference(keys, def);
  }
}

export function setPref(keys, value, obj, isSite) {
  let prefs = obj;

  if (!obj) {
    // when no preset prefs are send
    prefs = isSite
      ? JSON.parse(prefsSiteGeneral)
      : JSON.parse(prefsUserGeneral);
  }
  const arrKeys = keys.split(".");

  return nest(prefs, arrKeys, value);
}

export function postPrefs(keys, value, isSite) {
  const userPrefs = isSite
    ? JSON.parse(prefsSiteGeneral)
    : JSON.parse(prefsUserGeneral);
  let prefs = value;
  if (keys !== "") {
    // to change one pref at a time
    const arrKeys = keys.split(".");
    prefs = nest(userPrefs ? userPrefs : {}, arrKeys, value);
  }

  const request = isSite ? "sites/" + cloudIdSite : "user";
  if (JSON.stringify(prefs) !== userPrefs) {
    let testJson = prefs;
    testJson = JSON.stringify(testJson);
    testJson = JSON.parse(testJson);
    apiPatch(store.state.general.token, request, {
      prefs: JSON.stringify(testJson)
    })
      .then(r => {
        request.includes("user")
          ? store.state.user.savePreferences(r)
          : store.state.sites.savePreferences(r);
        return "Success";
      })
      .catch(() => {
        return "Error";
      });
  }
  return "Same" + " : " + JSON.stringify(prefs);
}

function sleep(event) {
  prefVisible(event);
}

export function prefVisible(event) {
  let visibleOrNo = document.getElementsByClassName("visibleOrNo");
  if (event !== "true") {
    visibleOrNo = document.getElementsByClassName(event);
  }
  let valueToggle = store.state.userGoals;
  if (event !== "true" && !valueToggle) {
    valueToggle = true;
  } else if (event !== "true" && valueToggle) {
    valueToggle = false;
  }
  let prefFinal;
  if (visibleOrNo.length === 0 && event !== "true") {
    setTimeout(() => {
      sleep(event);
    }, 3000);
  }
  for (let i = 0; i < visibleOrNo.length; i++) {
    let dataSet = visibleOrNo[i].dataset.content;
    dataSet = dataSet.split(".");
    let start = dataSet[0] + "." + dataSet[1];
    if (valueToggle) {
      // User
      let prefs = getPref(start, {}, null, false);
      if (prefs && Object.keys(prefs).length > 0) {
        prefFinal = prefs[dataSet[2]];
        prefFinal = prefs.visible;
      } else {
        prefFinal = true;
      }
    } else {
      // Site
      let prefs = getPref(start, {}, null, true);
      if (prefs && Object.keys(prefs).length > 0) {
        prefFinal = prefs[dataSet[2]];
        prefFinal = prefs.visible;
      } else {
        prefFinal = true;
      }
    }
    if (prefFinal === false) {
      visibleOrNo[i].classList.add("hidden");
    } else {
      visibleOrNo[i].classList.remove("hidden");
    }
  }
}

function nest(obj, keys, v) {
  if (keys.length === 1) {
    obj[keys[0]] = v;
  } else {
    const key = keys.shift();
    obj[key] = nest(typeof obj[key] === "undefined" ? {} : obj[key], keys, v);
  }

  return obj;
}

export function defaultValidator(arrKeys, prefs, site = false) {
  let def = JSON.parse(JSON.stringify(site ? defaultPrefsSites : defaultPrefs));
  let updated_prefs = prefs;
  for (let i = 0; i < arrKeys.length; ++i) {
    let k = arrKeys[i];
    if (k in def) {
      def = def[k];
    }
    if (k in updated_prefs) {
      updated_prefs = updated_prefs[k];
    }
  }

  if (
    typeof def === "object" &&
    JSON.stringify(def) !== JSON.stringify(updated_prefs)
  ) {
    for (let i = 0, l = Object.keys(def).length; i < l; i++) {
      const current_key = Object.keys(def)[i];
      if (!(current_key in updated_prefs)) {
        updated_prefs[current_key] = def[current_key];
      }
    }
    return updated_prefs;
  }
  return def;
}

export function renameTiles(fav) {
  if (!fav) {
    return [];
  }
  let array = [];
  const currentSite = store.state.sites.currentSite;
  const hasCurrentSite = JSON.stringify(currentSite) !== "{}";
  const user = store.state.user;
  const hasGestation =
    hasCurrentSite &&
    currentSite.hasGestationFeeding() &&
    user.hasGestationFeeding();
  const hasLactation =
    hasCurrentSite &&
    currentSite.hasLactationFeeding() &&
    user.hasLactationFeeding();
  const hasZootech =
    hasCurrentSite && currentSite.hasZootech() && user.hasZootech();
  for (let i = 0; i < fav.length; i++) {
    switch (fav[i]) {
      case "GesFeedingMain":
        if (hasGestation) array.push("GestationFeedingMainTile");
        break;
      case "GesVisitsMain":
        if (hasGestation) array.push("GestationVisitsMainTile");
        break;
      case "LactDayIntakesMain":
        if (hasLactation) array.push("LactationHistoryMainTile");
        break;
      case "LactSowsFedMain":
        if (hasLactation) array.push("LactationFedMainTile");
        break;
      case "FarrWeanPgtsMain":
        if (hasZootech) array.push("FarrowingPerformanceMainTile");
        break;
      case "HerdReprodMain":
        if (hasZootech) array.push("HerdReprodMainTile");
        break;
      case "LittersMain":
        if (hasZootech) array.push("LittersMainTile");
        break;
      case "ActiveSowsMain":
        if (hasZootech) array.push("ActiveSowsMainTile");
        break;
      default:
        if (
          (hasGestation && fav[i].startsWith("Gestation")) ||
          (hasLactation && fav[i].startsWith("Lactation")) ||
          (hasZootech &&
            !fav[i].startsWith("Gestation") &&
            !fav[i].startsWith("Lactation"))
        ) {
          array.push(fav[i]);
        }
        break;
    }
  }
  return array;
}
