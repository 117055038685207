<template lang="pug">
div.displayFlex.buttonGroup
    button.modal-default-button(
      @click="currentButton('simple')"
      :class="modeShow === 'simple' ? 'current-button buttonDateCurrent' : ''"
      type="button"
      role="button"
    )
      .eva.eva-grid-outline
      Tooltip(:bottom="bottom") {{ $t("performances.monitoring.simple") }}
    button.modal-default-button(
      @click="currentButton('detailed')"
      :class="modeShow === 'detailed' ? 'current-button buttonDateCurrent' : ''"
      type="button"
      role="button"
    )
      .eva.eva-keypad-outline
      Tooltip(:bottom="bottom") {{ $t("performances.monitoring.detailed") }}
    button.modal-default-button.menu-link(
      @click="currentButton('gilts')"
      :class="modeShow === 'gilts' ? 'current-button buttonDateCurrent' : ''"
      type="button"
      role="button"
    )
      cloud-icon.icon(name="pig3")
      Tooltip(:bottom="bottom") {{ $t("performances.monitoring.sections.gilts") }}
    button.modal-default-button.menu-link(
      @click="currentButton('weaning')"
      :class="modeShow === 'weaning' ? 'current-button buttonDateCurrent' : ''"
      type="button"
      role="button"
    )
      cloud-icon.icon(name="weaning")
      Tooltip(:bottom="bottom") {{ $t("performances.weaning") }}
    button.modal-default-button.menu-link(
      @click="currentButton('inventory')"
      :class="modeShow === 'inventory' ? 'current-button buttonDateCurrent' : ''"
      type="button"
      role="button"
    )
      cloud-icon.icon(name="inventory")
      Tooltip(:bottom="bottom") {{ $t("performances.inventory") }}
    button.modal-default-button.menu-link(
      @click="currentButton('reproduction')"
      :class="modeShow === 'reproduction' ? 'current-button buttonDateCurrent' : ''"
      type="button"
      role="button"
    )
      cloud-icon.icon(name="reproduction")
      Tooltip(:bottom="bottom") {{ $t("performances.reproduction") }}
    button.modal-default-button.menu-link(
      @click="currentButton('farrowing')"
      :class="modeShow === 'farrowing' ? 'current-button buttonDateCurrent' : ''"
      type="button"
      role="button"
    )
      cloud-icon.icon(name="farrowing")
      Tooltip(:bottom="bottom") {{ $t("performances.farrowing") }}
    button.modal-default-button(
      @click="currentButton('custom')"
      :class="modeShow === 'custom' ? 'current-button buttonDateCurrent' : ''"
      type="button"
      role="button"
    )
      .eva.eva-brush-outline
      Tooltip(:bottom="bottom") {{ $t("performances.monitoring.custom") }}
</template>

<script>
import Tooltip from "@/components/Tooltip.vue";

export default {
  name: "buttons-monitoring",
  components: { Tooltip },
  data() {
    return {
      modeShow: this.$user.getPreference(
        "preferences.general.monitoringDisplay"
      )
    };
  },
  props: {
    bottom: Boolean
  },
  methods: {
    currentButton(button) {
      this.modeShow = button;
      this.$user.savePreferences(
        "preferences.general.monitoringDisplay",
        button
      );
    }
  }
};
</script>

<style lang="stylus" scoped>
.displayFlex
    justify-content center
    padding-bottom 1rem
button
    display flex
    height 50px
    width 70px
    margin-left 2px
    text-align center
    justify-content center
    align-items center
    flex-wrap wrap
.rotated
    transform rotateZ(90deg)
.buttonGroup
  button
    margin 0
    padding 3px 10px
    width auto
    font-size 20px
    min-width 44px
    border-radius 0
    box-shadow none !important
    &:first-child
      border-top-left-radius 3px
      border-bottom-left-radius 3px
    &:last-child
      border-top-right-radius 3px
      border-bottom-right-radius 3px
@media screen and (--max-tablette)
  .buttonGroup button
    border-radius 0 !important
</style>
