//@ts-check
import Store from "../store/index";
import Site from "./Site";
import RequestHelper from "../functions/RequestHelper";

export default class Sites {
  constructor() {
    let sites = /** @type {Site[]} */ ([]);
    this.sites = sites;
    this.tenants = [];
    this.currentSite = /** @type {Site} */ (null);
    this.loaded = false;
    this.comparison = [];
  }

  clear() {
    let sites = /** @type {Site[]} */ ([]);
    this.sites = sites;
    this.tenants = [];
    this.currentSite = /** @type {Site} */ (null);
    this.comparison = [];
  }

  getCurrentSiteIndex() {
    return this.comparison.indexOf(this.currentSite);
  }

  /**
   * @param {Site} site
   */
  getSiteNameToDisplay(site) {
    for (let member of Store.state.general.memberships) {
      if (
        member.use_sharing_name &&
        member.tenant.cloud_id == site.tenant.cloud_id &&
        (member.sites === "all" || (site && member.sites.includes(site.id)))
      ) {
        return site.sharing_name;
      }
    }
    return site.name;
  }

  /**
   * @param {String} id
   */
  changeSite(id) {
    this.currentSite = this.sites.find(s => s.id === id);
    if (this.comparison.findIndex(i => i.id === this.currentSite.id) === -1) {
      this.comparison.push(this.currentSite);
    }
    Store.state.user.savePreferences("preferences.general.lastSiteVisited", id);
  }

  /**
   * @param {String} key
   * @param {any} [defaultValue]
   */
  getPreference(key, defaultValue) {
    if (!this.currentSite) {
      return null;
    }
    return this.currentSite.getPreference(key, defaultValue);
  }

  /**
   * @param {String} key
   * @param {any} value
   */
  async setPreference(key, value) {
    return await this.currentSite.savePreferences(key, value);
  }

  async loadFromApi() {
    if (!RequestHelper.getToken()) {
      this.loaded = true;
      return;
    }
    return await RequestHelper.getAllPages("sites/vision-web")
      .then(async definition => {
        await this.load(definition);
      })
      .catch(() => {
        this.loaded = true;
      });
  }

  async loadStaffFromApi() {
    if (!RequestHelper.getToken()) {
      this.loaded = true;
      return;
    }
    const request = await RequestHelper.getAllPages("staff/sites/vision-web");
    if (request.status) {
      this.loaded = true;
      return request;
    } else {
      return await this.load(request);
    }
  }

  /**
   * @param {any[]} tenants
   */
  async load(tenants) {
    if (!this.currentSite || Object.keys(this.currentSite).length === 0) {
      let newSites = await Promise.all(
        tenants
          .map(m => m.sites)
          .flat()
          .sort(
            (a, b) =>
              (a.name.trim().normalize("NFD") >
                b.name.trim().normalize("NFD") &&
                1) ||
              -1
          )
          .map(async s => {
            s.tenant = await tenants.find(t =>
              t.sites.some(ts => ts.cloud_id === s.cloud_id)
            );
            delete s.tenant["sites"];
            const site = new Site(s);
            if (!site.hasAccess()) return null;
            await site.initialize(s.prefs);
            return site;
          })
      );
      newSites = newSites.filter(s => s != null);
      this.sites = newSites;
      this.comparison = newSites;
      let lastSite = Store.state.user.getPreference(
        "preferences.general.lastSiteVisited"
      );
      this.tenants = await Promise.all(
        tenants
          .map(tenant => ({
            cloud_id: tenant.cloud_id,
            name: tenant.name,
            ltrack_access: tenant.ltrack_access
          }))
          .sort(
            (a, b) =>
              (a.name.trim().normalize("NFD") >
                b.name.trim().normalize("NFD") &&
                1) ||
              -1
          )
      );
      if (this.sites.length) {
        if (lastSite) {
          this.currentSite =
            this.sites.find(s => s.id === lastSite) || this.sites[0];
        } else {
          this.currentSite = this.sites[0];
        }
      } else {
        this.currentSite = {};
      }
      this.loaded = true;
    }
  }

  /**
   * @param {Array} sites
   */
  setComparison(sites) {
    this.comparison = sites;
  }

  /**
   * @param {Site} site
   * @return {String} site's name that will be displayed
   */
  nameToDisplay(site) {
    if (!site) return null;

    if (site.sharing_name && site.sharing_name.trim().length > 0) {
      for (let member of Store.state.general.memberships) {
        if (
          member.use_sharing_name &&
          member.tenant.cloud_id == site.tenant.cloud_id &&
          (member.sites === "all" || (site && member.sites.includes(site.id)))
        ) {
          return site.sharing_name;
        }
      }
    }
    return site.name;
  }
}
