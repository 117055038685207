<template lang="pug">
.cloud(
  :class="$user.getTheme()"
  v-if="$user.loaded || $router.currentRoute.name === 'login'"
)
  //- Top section containing the header
  .cloud-top
    header.topnav
      cloud-horizontal-nav
  //- Bottom section containing the menu and the main screen
  .cloud-bottom(@click.stop="$size.currentSize <= $size.TABLET && $store.state.menuOpened ? closeMenu() : ''")
    cloud-menu
    .dashboard(:class="{opacityFilm: $size.currentSize <= $size.TABLET && $store.state.menuOpened}")
      router-view
</template>

<script>
import CloudHorizontalNav from "@/components/navigation/horizontal-nav";
import CloudMenu from "@/components/navigation/menu";
import { apiGet } from "@/functions/api-requests";

export default {
  name: "integrator",
  components: {
    CloudHorizontalNav,
    CloudMenu
  },
  methods: {
    closeMenu() {
      this.$store.state.menuOpened = false;
    }
  },
  beforeUpdate() {
    apiGet(this.$store.state.general.token, "ping?", 1).catch(() => {
      this.$router.push("/login");
      return "You are unauthorized.";
    });
  },
  created() {
    this.$store.state.menuOpened = false;
  }
};
</script>
