//@ts-check
import defaultPrefs from "../assets/json/default-prefs.json";
import RequestHelper from "../functions/RequestHelper";
import { backwardCompatibility } from "../functions/objectives";
import { i18n } from "../i18n.js";
import { Settings } from "luxon";
import { defaultValidator } from "../functions/preferences";
import { isEqual } from "lodash-es";
import store from "../store";
import router from "../router";

/**
 * @typedef UserDefinition
 * @property {number} cloud_id
 * @property {string} email
 * @property {string} first_name
 * @property {string} last_name
 * @property {string} role
 * @property {string} prefs
 * @property {boolean} never_login
 */

export default class User {
  constructor() {
    this.id = null;
    this.email = null;
    this.first_name = null;
    this.last_name = null;
    this.role = null;
    this.preferences = null;
    this.never_login = true;
    this.loaded = false;
    this.locale = null;
  }

  clear() {
    this.id = null;
    this.email = null;
    this.first_name = null;
    this.last_name = null;
    this.role = null;
    this.preferences = null;
    this.never_login = true;
    this.locale = null;
  }

  userName() {
    return this.first_name
      ? this.first_name + " " + this.last_name
      : this.email.substring(
          0,
          this.email.indexOf("@") === -1
            ? this.email.length
            : this.email.indexOf("@")
        );
  }

  initials() {
    if (this.first_name)
      return (
        this.first_name.charAt(0) + this.last_name.charAt(0)
      ).toUpperCase();

    const username = this.email.substring(
      0,
      this.email.indexOf("@") === -1
        ? this.email.length
        : this.email.indexOf("@")
    );
    const arrNames = username
      .split(/[-_.+]+/)
      .filter(n => isNaN(parseFloat(n)));
    return (
      arrNames[0].charAt(0) +
      (arrNames.length > 1 ? arrNames[arrNames.length - 1].charAt(0) : "")
    ).toUpperCase();
  }

  getLocale() {
    return this.getPreference(
      "preferences.general.Language",
      navigator.language.split("-")[0]
    );
  }

  /**
   * @param {String} locale
   */
  setLocale(locale) {
    if (locale == this.locale) {
      return;
    }
    this.locale = locale;
    this.setPreference("preferences.general.Language", locale);
    Settings.defaultLocale = locale;
    i18n.locale = locale;
  }

  getTheme() {
    let theme = this.getPreference("preferences.general.theme");
    if (theme && typeof theme !== "string") {
      theme = theme.theme;
    }
    if (theme && theme.length > 0) {
      return theme.toLowerCase();
    }
    return window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light";
  }

  /**
   * @param {String} key
   * @param {any} [defaultValue]
   */
  getPreference(key, defaultValue) {
    if (!key) return null;
    let root = this.preferences;
    if (!root) return null;
    let def_prefs = defaultPrefs;
    let keys = key.split(".");
    for (let k of keys) {
      if (root && root[k]) {
        root = root[k];
        if (def_prefs) def_prefs = def_prefs[k];
      } else {
        if (defaultValue) return defaultValue;
        else if (def_prefs) root = def_prefs[k];
        else return null;
      }
    }

    if (!keys.includes("theme") && typeof root === "object")
      root = defaultValidator(keys, root);

    return root;
  }

  /**
   * @param {String} key
   * @param {any} value
   */
  setPreference(key, value) {
    let root = this.preferences;
    let keys = key.split(".");
    let lastKey = keys.splice(keys.length - 1, 1)[0];
    for (let k of keys) {
      if (!root[k]) {
        root[k] = {};
      }
      root = root[k];
    }
    if (isEqual(root[lastKey], value)) {
      return false;
    }
    root[lastKey] = value;
    return true;
  }

  /**
   * @param {String} key
   * @param {any} value
   * @param {Boolean} [force]
   */
  async savePreferences(key, value, force = false) {
    let saved = this.setPreference(key, value);
    if (saved || force) {
      let definition = await RequestHelper.patch("user", {
        prefs: JSON.stringify(this.preferences)
      });
      this.load(definition);
    }
    return this.getPreference(key);
  }

  async loadFromApi() {
    if (!RequestHelper.getToken()) {
      this.loaded = true;
      return;
    }
    const request = await RequestHelper.get("user");
    if (request.status) {
      this.loaded = true;
      store.state.general.token = null;
      await router.replace("/login");
    } else {
      await this.load(request);
    }
    return request;
  }

  /**
   * @param {UserDefinition} definition
   */
  async load(definition) {
    this.id = definition.cloud_id;
    this.email = definition.email;
    this.first_name = definition.first_name;
    this.last_name = definition.last_name;
    this.role = definition.role;
    this.never_login = definition.never_login;
    const parsePrefs = definition.prefs ? JSON.parse(definition.prefs) : null;
    if (
      parsePrefs &&
      (!parsePrefs.hasOwnProperty("objectives") ||
        parsePrefs.hasOwnProperty("litters") ||
        parsePrefs.hasOwnProperty("herd") ||
        (parsePrefs.hasOwnProperty("gestation") &&
          parsePrefs.gestation.hasOwnProperty("visits")))
    ) {
      this.preferences = await backwardCompatibility.call(this, parsePrefs);
    } else {
      this.preferences = parsePrefs || {};
    }
    this.setLocale(this.getLocale());
    this.loaded = true;
  }

  /**
   * @return {boolean} If user is staff
   */
  isStaff() {
    return this.role === "staff";
  }

  /**
   * @return {boolean} If user is owner/admin/manager
   */
  isManager() {
    if (this.role === "integrator") return false;
    if (this.isStaff()) return true;
    for (let i = 0; i < store.state.general.memberships.length; i++) {
      const current = store.state.general.memberships[i];
      if (current.role !== "member") return true;
    }
    return false;
  }

  /**
   * @return {boolean} If user is owner/admin
   */
  isAdmin() {
    if (this.role === "integrator") return false;
    if (this.isStaff()) return true;
    for (let i = 0; i < store.state.general.memberships.length; i++) {
      const currentRole = store.state.general.memberships[i].role;
      if (currentRole === "owner" || currentRole === "admin") return true;
    }
    return false;
  }

  /**
   * @return {boolean} If user has access to any tenant
   */
  hasAnyAccess() {
    return (
      this.isStaff() ||
      (this.role !== "integrator" &&
        store.state.general.memberships.some(
          m => m.expires_at == null || Date.parse(m.expires_at) >= Date.now()
        ))
    );
  }

  /**
   * @return {boolean} If user has access to at least one feeding report of the current site
   */
  hasFeeding() {
    return this.membershipAccess("feeding");
  }

  /**
   * @return {boolean} If user has access to all gestation feeding reports of the current site
   */
  hasGestationFeeding() {
    return this.membershipAccess("feeding", "gestation");
  }

  /**
   * @return {boolean} If user has access to all lactation feeding reports of the current site
   */
  hasLactationFeeding() {
    return this.membershipAccess("feeding", "lactation");
  }

  /**
   * @return {boolean} If user has access to all herd management reports of the current site
   */
  hasZootech() {
    return this.membershipAccess("zootech");
  }

  /**
   * @param {Site} [site] - The site we want to check if we have access to l-track reports
   * @return {boolean} If user has access to l-track reports
   */
  hasSilo(site = null) {
    return this.membershipAccess("silo", null, site);
  }

  /**
   * @param {String} type - The type to look for in the membership access_type
   * @param {String} [subtype] - The subtype of the type to look for in the membership access_type
   * @param {Site} [site] - The site we want to check if we have access to the type
   * @return {boolean} If user has access to reports of the param type of the current site
   */
  membershipAccess(type, subtype = null, site = null) {
    const currentSite = site ?? store.state.sites.currentSite;
    if (!currentSite || JSON.stringify(currentSite) === "{}") {
      // when there is no [currentSite]
      return (
        this.isStaff ||
        (this.role !== "integrator" &&
          store.state.sites.sites.some(s => {
            switch (type) {
              case "feeding":
                switch (subtype) {
                  case "gestation":
                    return s.hasGestationFeeding();
                  case "lactation":
                    return s.hasLactationFeeding();
                  default:
                    return s.hasFeeding();
                }
              case "zootech":
                return s.hasZootech();
              case "silo":
                return s.hasSilo();
              default:
                return false;
            }
          }))
      );
    }

    return (
      this.isStaff ||
      (this.role !== "integrator" &&
        currentSite &&
        store.state.general.memberships.some(m => {
          const access =
            m.access_type == null
              ? null
              : Object.fromEntries(
                  Object.entries(JSON.parse(m.access_type)).filter(
                    ([key, value]) => value.length > 0
                  )
                );
          return (
            m.tenant.cloud_id === currentSite.tenant.cloud_id &&
            (m.sites === "all" || m.sites.includes(currentSite.id)) &&
            (access == null ||
              (access[type] &&
                (subtype == null || access[type].includes(subtype))))
          );
        }))
    );
  }
}
