<template lang="pug">
div
    p.alert {{ $t('favorites.messageMaxTile')}}
    div.module__buttons
        button.modal-default-button(v-on:click="toggleAction('add')" :class="showAdd ? 'current-button' :''") {{ $t('add') }} ?
        button.modal-default-button(v-on:click="toggleAction('remove')" :class="showRemove ? 'current-button' :''") {{ $t('remove') }} ?
    div(v-if="showAdd || showRemove")
        p.message(
            v-if="typeof message !== 'object' && message.length > 0"
            :class="['error', 'noselect', 'maxreached'].includes(message.toLowerCase()) ? 'error' : (message.toLowerCase() === 'success' ? 'valide' : '')"
        ) {{ $t('favorites.' + message.toLowerCase()) }}
        form(
            v-on="{ submit: showAdd ? addToFavorites : removeFromFavorites }"
            v-if="Object.keys(favorites).length > 0 && ((showAdd && !excluded) || (showRemove && excluded))"
        )
            ul#fav-list
                li.deja-vu.deja-vu--all(
                    v-for="(value, key) in favorites"
                    v-if="(showAdd && value.indexOf(currentTile) === -1) || (showRemove && value.indexOf(currentTile) > -1)"
                )
                    input.hidden(type="checkbox" :id="key" :value="key" :name="key")
                    label(:for="key")
                        span
                            svg(width='12px', height='10px', viewbox='0 0 12 10')
                                polyline(points='1.5 6 4.5 9 10.5 1')
                        span {{ key.replace(/-/g, " ") }} ({{ value.length }} {{ $t('tile') }}{{ value.length > 1 ? 's' : '' }})
            input.submit-form(type="submit" id="submit" name="submit" :value="showAdd ? $t('add') : $t('remove')")
        p(v-else-if="Object.keys(favorites).length > 0 && (showAdd && excluded)")
            | {{ $t('favorites.tileInFav') }}
        p(v-else-if="Object.keys(favorites).length > 0 && (showRemove && !excluded)")
            | {{ $t('favorites.tileNotInFav') }}
        p(v-else) {{ $t('favorites.noFavPage') }}
</template>

<script>
import { postPrefs } from "@/functions/preferences";

export default {
  name: "favorites-menu",
  data() {
    return {
      showAdd: false,
      showRemove: false,
      message: "",
      messageTimeout: null
    };
  },
  props: {
    currentTile: String,
    favorites: {
      type: Object,
      required: true
    },
    resetter: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    resetter(bool) {
      if (bool) {
        this.showAdd = false;
        this.showRemove = false;
        this.message = "";
      } else {
        setTimeout(() => {
          this.showAdd = false;
          this.showRemove = false;
          this.message = "";
        }, 300);
      }
    }
  },
  computed: {
    excluded() {
      const currentLength = Object.values(this.favorites)
        .reduce((acc, val) => acc.concat(val), [])
        .filter(e => e === this.currentTile).length;

      if (this.showAdd) {
        return currentLength === Object.keys(this.favorites).length;
      }
      return (
        currentLength <= Object.keys(this.favorites).length && currentLength > 0
      );
    }
  },
  methods: {
    toggleAction(action) {
      if (action === "add") {
        this.showAdd = true;
        this.showRemove = false;
      } else {
        this.showAdd = false;
        this.showRemove = true;
      }
    },
    addToFavorites(event) {
      event.preventDefault();
      const form = event.target;
      const myFavorites = this.favorites;
      let nbChecked = 0;
      let maxReached = false;
      for (let i = 0; i < form.length - 1; i++) {
        const id = form[i].id;
        if (
          form[i].checked &&
          myFavorites[id] &&
          myFavorites[id].indexOf(this.currentTile) === -1
        ) {
          myFavorites[id].push(this.currentTile);
          nbChecked++;
          if (myFavorites[id].length + 1 > 5) {
            maxReached = true;
            break;
          }
        }
      }
      if (nbChecked > 0 && nbChecked <= 4 && !maxReached) {
        this.message = postPrefs("favorites", myFavorites);
      } else {
        if (nbChecked === 0) {
          this.message = "noselect";
        } else {
          this.message = "maxreached";
        }
      }
      this.addMessage();
    },
    removeFromFavorites(event) {
      event.preventDefault();
      const form = event.target;
      const myFavorites = this.favorites;
      let nbChecked = 0;
      for (let i = 0; i < form.length - 1; i++) {
        const id = form[i].id;
        if (
          form[i].checked &&
          myFavorites[id] &&
          myFavorites[id].indexOf(this.currentTile) > -1
        ) {
          myFavorites[id].splice(myFavorites[id].indexOf(this.currentTile), 1);
          nbChecked++;
        }
      }
      if (nbChecked > 0) {
        this.message = postPrefs("favorites", myFavorites);
      } else {
        this.message = "noselect";
      }
      this.addMessage();
    },
    addMessage() {
      clearTimeout(this.messageTimeout);
      if (this.message.includes("Same")) {
        this.$user.savePreferences(
          JSON.stringify(JSON.parse(this.message.replace("Same : ", "")))
        );
        this.message = "success";
        this.messageTimeout = setTimeout(() => {
          this.message = "";
        }, 5000);
      } else {
        this.messageTimeout = setTimeout(() => {
          this.message = "";
        }, 5000);
      }
    }
  }
};
</script>
