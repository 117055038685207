import Vue from "vue";
import Vuex from "vuex";
import VueLazyload from "vue-lazyload";
import { i18n } from "./i18n.js";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import VueAnalytics from "vue-analytics";
import Tile from "./components/Tile";
import CloudIcon from "@/components/icon";
import HttpErrors from "./components/HttpErrors";
import "vue-datetime/dist/vue-datetime.css";
import Chartkick from "vue-chartkick";
import Chart from "chart.js";

Vue.use(Vuex);
Vue.use(VueLazyload);
Vue.use(Chartkick.use(Chart));
Vue.component("Tile", Tile);
Vue.component("CloudIcon", CloudIcon);
Vue.component("HttpErrors", HttpErrors);
// if (process.env.NODE_ENV !== "development") {
//   Vue.use(VueAnalytics, {
//     id: process.env.VUE_APP_GA,
//     router
//   });
// }

// Chart global parameters
Chart.defaults.global.defaultFontSize = 14;
Chart.defaults.global.maintainAspectRatio = false;
Chart.defaults.global.legend.labels.boxWidth = 7;
Chart.defaults.global.legend.labels.usePointStyle = true;
Chart.defaults.global.legend.labels.generateLabels = chart => {
  const data = chart.data;
  return Chart.helpers.isArray(data.datasets)
    ? data.datasets.map(function (object, i) {
        return {
          text: object.label,
          fillStyle: ["transparent", "white"].includes(object.backgroundColor)
            ? object.borderColor
            : object.backgroundColor,
          pointStyle: "circle",
          strokeStyle: object.borderColor,
          hidden: !chart.isDatasetVisible(i),
          datasetIndex: i
        };
      }, this)
    : [];
};

window.store = store;
Vue.prototype.$user = store.state.user;
Vue.prototype.$sites = store.state.sites;
Vue.prototype.$size = store.state.size;
Vue.prototype.$objectives = store.state.objectives;

new Vue({
  el: "#app",
  router: router,
  store: store,
  i18n: i18n,
  render: h => h(App)
});
