import axios from "axios";
import store from "@/store";

export function apiGet(token, request, page, params, per_page = 1000, debug) {
  axios.interceptors.request.use(
    function (config) {
      config.metadata = { startTime: new Date() };
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  return axios
    .get(
      `${
        process.env.VUE_APP_API_URL + request
      }page=${page}&per_page=${per_page}`,
      {
        params: params,
        headers: {
          Authorization: "Bearer " + token
        }
      }
    )
    .then(response => {
      if (debug) {
        response.config.metadata.endTime = new Date();
        response.duration =
          response.config.metadata.endTime - response.config.metadata.startTime;
        return response;
      }
      return response.data;
    })
    .catch(() => {
      return "You are unauthorized.";
    });
}

export function allData(token, request, page, params, per_page = 1000) {
  return apiGet(token, request, page, params, per_page).then(data => {
    return data.data;
  });
}

export function apiPost(token, request, data) {
  return axios
    .post(`${process.env.VUE_APP_API_URL + request}`, data, {
      headers: {
        Authorization: "Bearer " + token
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(e => {
      return e.response.data;
    });
}

export function apiPatch(token, request, data) {
  return axios
    .patch(`${process.env.VUE_APP_API_URL + request}`, data, {
      headers: {
        Authorization: "Bearer " + token
      }
    })
    .then(response => {
      return response.data;
    });
}

export function apiGetBulk(token, bulk_id, site) {
  return axios
    .get(`${process.env.VUE_APP_API_URL}sites/${site}/bulk/${bulk_id}`, {
      headers: {
        Authorization: "Bearer " + token
      }
    })
    .then(response => {
      return response.data.pending;
    })
    .catch(() => {
      return "You are unauthorized.";
    });
}

export async function generalApiGet(request, page, params) {
  return await apiGet(store.state.general.token, request, page, params).then(
    async data => {
      if (data === "You are unauthorized.") {
        return data;
      } else {
        if (data.meta.pages <= data.meta.current_page) {
          return data.data;
        } else {
          let array = [];
          array.push(...data.data);
          for (let i = 2; i <= data.meta.pages; i++) {
            await allData(store.state.general.token, request, i, params).then(
              res => {
                array.push(...res);
              }
            );
          }
          return Promise.resolve(array);
        }
      }
    }
  );
}
