<template lang="pug">
.alert.error(v-if="error && error.status >= 500" v-html="$t('filters.error500') + $t('contactUs')")
.alert.warning(v-else-if="error") {{ $t('filters.error400') }}
</template>

<script>
export default {
  name: "HttpErrors",
  props: {
    error: Object
  }
};
</script>

<style lang="stylus" scoped>
.alert
  width 90%
  padding 8px 5px
  display block
  max-width 700px
  margin 0 auto 25px !important
  z-index 40
  @media screen and (--medium-up)
    width 70%
</style>
