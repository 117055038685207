<template lang="pug">
  .maintenance(v-if="inMaintenance")
    p.title {{ $t('maintenance.serviceDisruption') }}
    p.body {{ $t('maintenance.message', {dateStart: dateStart, duration: duration}) }}
</template>

<script>
import { DateTime } from "luxon";

export default {
  name: "maintenance",
  data() {
    return {
      inMaintenance: process.env.VUE_APP_MAINTENANCE === "true",
      start: DateTime.fromObject({
        month: 9,
        day: 21,
        hour: 8,
        zone: "America/Montreal"
      }),
      end: DateTime.fromObject({
        month: 9,
        day: 21,
        hour: 17,
        zone: "America/Montreal"
      })
    };
  },
  computed: {
    dateStart() {
      return (
        this.start.toLocal().toLocaleString(DateTime.DATETIME_MED) +
        " " +
        DateTime.local().toFormat("ZZZZ")
      );
    },
    duration() {
      const duration = this.start
        .until(this.end)
        .toDuration(["hours", "minutes"]);
      return (
        duration.hours +
        " " +
        this.$t(`date.${duration.hours > 1 ? "hours" : "hour"}`).toLowerCase() +
        (duration.minutes > 0
          ? " " +
            duration.minutes +
            " " +
            this.$t(
              `date.${duration.minutes > 1 ? "minutes" : "minute"}`
            ).toLowerCase()
          : "")
      );
    }
  }
};
</script>

<style lang="stylus" scoped>
.maintenance
  background-color var(--orange)
  padding 0 10px
  color var(--black)

  .title
    font-weight bold
    font-size 18px
    margin-bottom 0

  .body
    margin-top 0
</style>
