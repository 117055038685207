import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import general from "@/store/modules/general";
import User from "../models/User";
import Sites from "../models/Sites";
import Size from "../models/Size";
import Objectives from "../models/Objectives";

Vue.use(Vuex);

function persistedStateObject(keyName) {
  return {
    key: keyName,
    paths: [keyName],
    storage: {
      getItem: key => {
        let data = localStorage.getItem(key);
        return data;
      },
      setItem: (key, value) => {
        localStorage.setItem(key, value);
      },
      removeItem: key => {
        return localStorage.removeItem(key);
      }
    }
  };
}

const states = {
  menuOpened: window.innerWidth > 800,
  title: "",
  subtitles: [],
  userGoals: false,
  user: new User(),
  sites: new Sites(),
  size: new Size(),
  objectives: new Objectives(),
  toggleObjectives: true,
  toggleTotal: false,
  toggleAverage: false
};

let store = new Vuex.Store({
  state: states,
  modules: {
    general: general
  },
  plugins: [createPersistedState(persistedStateObject("general"))],
  mutations: {
    setUserGoals(state, value) {
      value = value === "on" ? true : value === "off" ? false : value;
      state.userGoals = value;
    },
    setToggleObjectives(state, value) {
      state.toggleObjectives = value;
    },
    setToggleTotal(state, value) {
      state.toggleTotal = value;
    },
    setToggleAverage(state, value) {
      state.toggleAverage = value;
    },
    setAllDefault(state) {
      state.user.clear();
      state.sites.clear();
      state.objectives.clear();
      state.general.token = null;
      state.general.memberships = [];
      state.general.totalMemberships = 0;
    }
  }
});

store.setTitle = function (...title) {
  store.state.title = title[0];
  store.state.subtitles = title.slice(1, title.length);
};

export default store;
