<template lang="pug">
.tile(:ref="title" :id="title" :class="{ opened: !hideContent, liveTile: live }")
  img.zIndex1000.loading(v-if="$size.currentSize > $size.MOBILE && loadingOpacity && loading < 1" v-lazy="'img/pig_loading.gif'" :class="{fadeInAnim: loading < 1}")
  .title(v-if="showTitle" :class="{ hideContent }")
    .favorite(v-if="!hideFavMenu" ref="favorites" @click="showPopperFav = !showPopperFav")
      .eva(:class="!inFav ? 'eva-star-outline' : 'eva-star'")
    .popper-container(:class="{show: showPopperFav}" @click="showPopperFav = false")
      .popper(ref="popperFav" @click.stop)
        p.alert {{ $t('favorites.messageMaxTile')}}
        button.action(@click="toggleAction('add')" :class="showAdd ? 'current-button' :''")
          .eva.eva-plus
          span {{ $t('add') }} ?
        button.action(@click="toggleAction('remove')" :class="showRemove ? 'current-button' :''")
          .eva.eva-minus
          span {{ $t('remove') }} ?
        .section(v-if="showAdd || showRemove")
          p.message(
              v-if="typeof message !== 'object' && message.length > 0"
              :class="['error', 'noselect', 'maxreached'].includes(message.toLowerCase()) ? 'error' : (message.toLowerCase().includes('success') ? 'valide' : '')"
          ) {{ $t('favorites.' + message.toLowerCase()) }}
          form(
              v-on="{ submit: showAdd ? addToFavorites : removeFromFavorites }"
              v-if="Object.keys(favorites).length > 0 && ((showAdd && !excluded) || (showRemove && excluded))"
          )
            ul#fav-list
              li.deja-vu.deja-vu--all(
                  v-for="(value, key) in favorites"
                  v-if="(showAdd && value.indexOf(tileName) === -1) || (showRemove && value.indexOf(tileName) > -1)"
              )
                input.hidden(type="checkbox" :id="key" :value="key" :name="key")
                label(:for="key")
                  span
                    svg(width='12px', height='10px', viewbox='0 0 12 10')
                      polyline(points='1.5 6 4.5 9 10.5 1')
                  span {{ key.replace(/-/g, " ") }} ({{ value.length }} {{ $t('tile') }}{{ value.length > 1 ? 's' : '' }})
            input.submit-form(type="submit" id="submit" name="submit" :value="showAdd ? $t('add') : $t('remove')")
          p(v-else-if="Object.keys(favorites).length > 0 && (showAdd && excluded)")
            | {{ $t('favorites.tileInFav') }}
          p(v-else-if="Object.keys(favorites).length > 0 && (showRemove && !excluded)")
            | {{ $t('favorites.tileNotInFav') }}
          p(v-else) {{ $t('favorites.noFavPage') }}
        .popperArrow(data-popper-arrow="true") ▲
    .text.text-ellipsis(:link="link" :collapsible="collapsible" @click="navigate" :class="{tileLink: (link || site || silo) && !unique, pointer: collapsible && !unique}")
      span.bin--level(v-if="silo && hideContent" :class="siloColor")
      | {{ title }}
    .grow(:class="{disabled: !collapsible}" @click="hideContent = !hideContent")
    .more(v-if="unknownSows > 0")
      .eva.eva-alert-triangle-outline
      Tooltip.alertBottom(:bottom="true")
        .text {{ $t('performances.tooltips.unknown_sow', { unknownSows }) }}
        .text {{ $t('performances.tooltips.unknown_name_with_status', { unknownNameWithStatus: calculatedName() }) }}
        .text {{ $t('performances.tooltips.unknown_status_with_name', { unknownStatusWithName: calculatedStatus() }) }}
        .text {{ $t('performances.tooltips.unknown_total_unknown', { unknownTotalUnknown: calculatedNameStatus() }) }}
    .live(v-if="live")
      .liveText {{ $t('liveToday') }}
    .tileRange(v-if="!live") {{ range }}
    .more(
      v-if="!hideFavMenu || unique || site || (building && isAdmin(building.site_id)) || silo"
      ref="more"
      @click="showPopper = !showPopper"
    )
      .eva.eva-more-horizontal
    .collapse(v-if="collapsible" @click="hideContent = !hideContent")
      .eva.eva-arrow-up(v-if="!hideContent")
      .eva.eva-arrow-down(v-if="hideContent")
    .popper-container(:class="{show: showPopper}" @click="showPopper = false")
      .popper(ref="popper" :style="{padding: 0}" @click.stop)
        .popper--content
          a.action(v-if="(!!link || site || (silo && !silo.isExpired && !silo.disabled_at)) && !unique" href="javascript:void(0)" @click="navigate")
            .eva.eva-info-outline
            span {{ $t('seeMore') }}
          button.action(v-if="periodChoice && (!unique || showPeriod)" @click="showPopperPeriod = !showPopperPeriod")
            .eva.eva-clock-outline
            span {{ $t('changeTimePeriod') }}
          form.section(v-on:submit.prevent="savePeriod" v-if="showPopperPeriod")
            .row
              input(type="number" size="1" v-model="periodAmount")
              .select
                .select-wrapper
                  select(v-model="periodType")
                    option(value="day") {{ $t(`date.${periodAmount > 1 ? "days" : "day"}`) }}
                    option(value="month") {{ $t(`date.month.title${periodAmount > 1 ? "Plural" : ""}`) }}
                    option(value="year") {{ $t(`date.${periodAmount > 1 ? "years" : "year"}`) }}
            input.submit-form(type="submit" :value="$t('save')")
          button.action(v-if="lactDay && !unique" @click="showPopperLactDay = !showPopperLactDay")
            .eva.eva-clipboard-outline
            span {{ $t('lactation.changeLactDay') }}
          form.section(v-on:submit.prevent="saveLactDay" v-if="showPopperLactDay")
            .row
              label(for="fromLactDay" style="margin-right: 5px")
                span {{ $t('date.from') }}:
              input(id="fromLactDay" name="fromLactDay" type="number" size="1" :max="toLactDay" v-model="fromLactDay" style="width: 0")
              label(for="fromLactDay" style="margin-right: 5px")
                span {{ $t('date.to') }}:
              input(id="toLactDay" name="toLactDay" type="number" size="1" :min="fromLactDay" v-model="toLactDay" style="width: 0")
            input.submit-form(type="submit" :value="$t('save')")
          button.action(v-if="comparison && $sites.sites.length > 1" @click="showPopperComparison = !showPopperComparison")
            .eva.eva-grid-outline
            span {{ $t("comparison.comparisonBySite") }}
          form.section(v-on:submit.prevent="callComparison" v-if="showPopperComparison")
            ul
              li.deja-vu.deja-vu--all
                input.hidden(type="checkbox" id="allSites" value="allSites" name="allSites" v-model="isCheckAll" @input="checkAll")
                label(for="allSites")
                  span
                    svg(width='12px', height='10px', viewbox='0 0 12 10')
                      polyline(points='1.5 6 4.5 9 10.5 1')
                  span {{ $t('select-all') }}
              li.deja-vu.deja-vu--all(v-for="value in $sites.sites")
                input.hidden(type="checkbox" :id="value.id" :value="value" :name="value.id" @change="updateCheckall" v-model="checkedSites")
                label(:for="value.id")
                  span
                    svg(width='12px', height='10px', viewbox='0 0 12 10')
                      polyline(points='1.5 6 4.5 9 10.5 1')
                  span {{ $sites.nameToDisplay(value) }}
            input.submit-form(type="submit" :value="$t('comparison.compare')" :disabled="checkedSites.length <= 1")
          button.action(v-if="silo && isAdmin(silo.site.cloud_id)" type="button" @click="emitDisable")
            .icon.eva(:class="[silo.disabled_at ? 'eva-refresh' : 'eva-slash']")
            span {{ $t(`silos.${silo.disabled_at ? 'enable' : 'disable'}`) }}
          button.action(v-if="(site && isAdmin(site.id)) || building" type="button" @click="emitShowSite")
            .icon.eva.eva-edit
            span {{ $t('edit') }}
          button.action(
            v-if="(site && !site.buildingsCount) || (building && (!building.silos || (building.silos && !building.silos.length)))"
            type="button"
            @click="emitDeleteSite"
          )
            .icon.eva.eva-trash-2-outline
            span {{ $t('delete') }}
          button.action(v-if="silo && !silo.isExpired && !silo.disabled_at && isAdmin(silo.site.cloud_id)" type="button" @click="emitShowSilo")
            .icon.eva.eva-edit
            span {{ $t('edit') }}
          button.action(v-if="silo && !silo.isExpired && !silo.disabled_at" type="button" @click="emitShowSiloGraph")
            .icon.eva.eva-bar-chart
            span {{ $t('silos.showCharts') }}
          button.action(v-if="toPrint" @click="print" :title="$t('printTile')" :aria-label="$t('printTile')")
            .eva.eva-printer-outline
            div {{ $t('printTile') }}
          button.action(v-if="exportCSV" @click="csvExport" :title="$t('exportCSV')" :aria-label="$t('exportCSV')")
            .eva.eva-cloud-download-outline
            div {{ $t('exportCSV') }}
          //a.action(v-if="exportPDF" href="javascript:void(0)" @click="pdfExport" :title="$t('exportPDF')" :aria-label="$t('exportPDF')")
          //  .eva.eva-cloud-download-outline
          //  div {{ $t('exportPDF') }}
          .popperArrow(data-popper-arrow="true") ▲
  div(:style="{ width: '100%', display: $size.currentSize <= $size.MOBILE ? 'inline-flex' : null, minHeight: $size.currentSize <= $size.MOBILE ? 'min-content' : null}")
    .title.title2(v-if="(actions && actions.length > 0) || showUnity")
      .actions(v-if="actions")
        template(v-for="action of actions")
          .action(v-if="iconName[action.eva]" @click="action.action" :class="{hidden: iconName[action.eva].toLowerCase() === 'debug' && displayDebug !== 'true'}")
            cloud-icon.icon(:name="action.icon" v-if="action.icon")
            .icon.eva(v-if="action.eva" :class="action.eva")
            span(v-if="!action.icon") {{ action.name }}
            Tooltip(v-if="iconName[action.eva]" :bottom="!showTitle") {{ $t(iconName[action.eva]) }}
      .grow
      .actions(v-if="palettePref || filterAction")
        .action(v-if="filterAction" @click="filterAction.action")
          .icon.eva.eva-funnel-outline
            .activeTag(v-if="$size.currentSize <= $size.TABLET && filterAction.hasFilters")
          Tooltip(:bottom="!showTitle") {{ $t('filter') }}
        .action(v-if="palettePref" ref="palette" @click="showPalette = !showPalette")
          .icon.eva.eva-color-palette-outline
          Tooltip(v-if="palettePref.tooltip" :bottom="!showTitle") {{ $t(palettePref.tooltip) }}
        .popper-container(v-if="palettePref && palettePref.objective" :class="{show: showPalette}" @click="showPalette = false")
          .popper(ref="popperPalette" style="padding: 10px" @click.stop)
            .deja-vu(@click.prevent="$user.savePreferences(palettePref.objective, 'all')")
              input.hidden(type="radio" id="paletteAll" name="palette" :checked="$user.getPreference(palettePref.objective) === 'all'")
              label.radio(for="paletteAll")
                span
                  svg(width='12px', height='10px', viewbox='0 0 12 10')
                    polyline(points='1.5 6 4.5 9 10.5 1')
                span {{ $t('colors.all') }}
            .deja-vu(@click.prevent="$user.savePreferences(palettePref.objective, 'red')")
              input.hidden(type="radio" id="paletteRed" name="palette" :checked="$user.getPreference(palettePref.objective) === 'red'")
              label.radio(for="paletteRed")
                span
                  svg(width='12px', height='10px', viewbox='0 0 12 10')
                    polyline(points='1.5 6 4.5 9 10.5 1')
                span {{ $t('colors.red') }}
            .deja-vu(@click.prevent="$user.savePreferences(palettePref.objective, 'none')")
              input.hidden(type="radio" id="paletteNone" name="palette" :checked="$user.getPreference(palettePref.objective) === 'none'")
              label.radio(for="paletteNone")
                span
                  svg(width='12px', height='10px', viewbox='0 0 12 10')
                    polyline(points='1.5 6 4.5 9 10.5 1')
                span {{ $t('colors.none') }}
            .popperArrow(data-popper-arrow="true") ▲
      .action.info-display.modal-default-button(v-show="infoText" ref="info" @click="showPopperInfo = !showPopperInfo")
        .eva.eva-info-outline
        Tooltip(:bottom="!showTitle") {{ $t('info') }}
      .popper-container(:class="{show: showPopperInfo}" @click="showPopperInfo = false")
        .popper.popper--info(ref="popperInfo" style="padding: 10px" @click.stop)
          div(v-html="infoText")
          .popperArrow(data-popper-arrow="true") {{ $size.currentSize <= $size.MOBILE ? "◄" : "►" }}
      .col-display.modal-default-button(v-show="(showUnity && !hideColumns) || showColumns" ref="col" @click="showPopperCol = !showPopperCol")
        .eva.eva-layout-outline
        Tooltip(:bottom="!showTitle") {{ $t('columns') }}
      .popper-container(:class="{show: showPopperCol}" @click="showPopperCol = false")
        .popper(ref="popperCol" style="padding: 10px" @click.stop)
          .deja-vu(v-if="!noTotal" @click.prevent="$store.commit('setToggleTotal', !$store.state.toggleTotal)")
            input.hidden(type="checkbox" id="toggleTotal" name="toggleTotal" :checked="$store.state.toggleTotal")
            label(for="toggleTotal")
              span
                svg(width='12px', height='10px', viewbox='0 0 12 10')
                  polyline(points='1.5 6 4.5 9 10.5 1')
              span {{ $t('total') }}
          .deja-vu(v-if="!noAvg" @click.prevent="$store.commit('setToggleAverage', !$store.state.toggleAverage)")
            input.hidden(type="checkbox" id="toggleAverage" name="toggleAverage" :checked="$store.state.toggleAverage")
            label(for="toggleAverage")
              span
                svg(width='12px', height='10px', viewbox='0 0 12 10')
                  polyline(points='1.5 6 4.5 9 10.5 1')
              span {{ $t('lactation.table.average') }}
          .deja-vu(@click.prevent="$store.commit('setToggleObjectives', !$store.state.toggleObjectives)")
            input.hidden(type="checkbox" id="toggleObjectives" name="toggleObjectives" :checked="$store.state.toggleObjectives")
            label(for="toggleObjectives")
              span
                svg(width='12px', height='10px', viewbox='0 0 12 10')
                  polyline(points='1.5 6 4.5 9 10.5 1')
              span {{ $t('objectives.title') }}
          .popperArrow(data-popper-arrow="true") ▲
      cloud-buttons-units(v-if="showUnity" :bottom="!showTitle" :style="{ 'align-items':'center', 'transform': 'scale(.8, .8)' }" :hideUnity="hideUnity || []")
      buttons-monitoring(v-if="$parent.$options.name == 'PerformancesMonitoringUniqueTile'" :bottom="!showTitle" :style="{ 'align-items':'center', 'transform': 'scale(.8, .8)' }")
  .content(ref="content" :class="{ hideContent }")
    .loadingBar(:class="{show: loading < 1}")
      .loadingInner(:style="{width: (loading * 95 + 5) + '%'}")
    slot
</template>

<script>
import { DateTime } from "luxon";
import Clamp from "@/components/Clamp";
import CloudButtonsUnits from "@/components/buttons-units";
import ButtonsMonitoring from "@/components/buttons-monitoring";
import FavoritesMenu from "@/components/navigation/menu/favorites";
import { createPopper } from "@popperjs/core";
import { renameTiles } from "@/functions/preferences";
import { parsePeriod } from "../functions/small-function";
import { switchCurrentTile } from "@/functions/small-function";
import Tooltip from "@/components/Tooltip.vue";
import { formatDate1000 } from "../functions/dates";
import { gererKg } from "@/functions/conversion";

export default {
  name: "tile",
  components: {
    Clamp,
    CloudButtonsUnits,
    ButtonsMonitoring,
    FavoritesMenu,
    Tooltip
  },
  props: {
    title: String,
    live: Boolean,
    range: String,
    objectivePage: String,
    link: String,
    breadcrumbs: String,
    priority: { value: String },
    collapsible: {
      type: Boolean,
      default: true
    },
    actions: Array,
    site: Object,
    building: Object,
    silo: Object,
    siloColor: String,
    filter: Function,
    showUnity: Boolean,
    unique: Boolean,
    periodChoice: Boolean,
    showPeriod: Boolean,
    hideFavMenu: Boolean,
    showMenu: Boolean,
    exportCSV: Boolean,
    exportCSVFunction: Boolean,
    exportPDF: Boolean,
    loading: {
      type: Number,
      default: 1
    },
    hideUnity: Array,
    hideColumns: Boolean,
    showColumns: Boolean,
    comparison: Function,
    lactDay: Boolean,
    stat: String,
    infoText: String,
    palettePref: Object,
    noAvg: Boolean,
    noTotal: Boolean,
    defaultCollapse: {
      type: Boolean,
      default: false
    },
    toPrint: {
      type: Boolean,
      default: true
    },
    unknownSows: Number,
    unknownName: Number,
    unknownStatus: Number
  },
  data() {
    return {
      displayDebug: process.env.VUE_APP_DISPLAY_DEBUG,
      hideContent: this.defaultCollapse,
      loadingOpacity: 0,
      showPopper: false,
      showPopperFav: false,
      showPopperPeriod: false,
      showPopperLactDay: false,
      showPopperCol: false,
      showPopperComparison: false,
      showPopperInfo: false,
      showPalette: false,
      periodAmount: 1,
      periodType: "month",
      preference: switchCurrentTile(this.link),
      popper: null,
      popperFav: null,
      popperInfo: null,
      showAdd: false,
      showRemove: false,
      message: "",
      messageTimeout: null,
      popperOptions: {
        placement: "bottom",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 10]
            }
          },
          {
            name: "preventOverflow",
            options: {
              padding: 10
            }
          }
        ]
      },
      iconName: {
        "eva-arrow-back": "back",
        "eva-refresh": "reset",
        "eva-sync": "refresh",
        "eva-save-outline": "save",
        "eva-shield-outline": "Debug",
        "eva-plus": "create",
        "eva-search-outline": "search",
        "eva-alert-triangle-outline": "UnknownSow"
      },
      fromLactDay: (
        this.$user.getPreference("lactation.lactation_day_range") || ""
      ).split("/")[0],
      toLactDay: (
        this.$user.getPreference("lactation.lactation_day_range") || ""
      ).split("/")[1],
      isCheckAll: false,
      checkedSites: this.$sites.comparison
    };
  },
  watch: {
    "$user.preferences"() {
      this.changeFavorites();
    },
    showPopperFav() {
      this.showAdd = false;
      this.showRemove = false;
      this.message = "";
    },
    showPopperPeriod(newVal) {
      if (newVal) {
        this.showPopperLactDay = false;
        this.showPopperComparison = false;
        this.countMaxHeightPopper();
      }
      if (!this.hideFavMenu && this.$refs.more && this.$refs.popper) {
        this.popper = createPopper(
          this.$refs.more,
          this.$refs.popper,
          this.popperOptions
        );
      } else {
        this.popper.update();
      }
    },
    showPopperLactDay(newVal) {
      if (newVal) {
        this.showPopperPeriod = false;
        this.showPopperComparison = false;
        this.countMaxHeightPopper();
      }
      if (!this.hideFavMenu && this.$refs.more && this.$refs.popper) {
        this.popper = createPopper(
          this.$refs.more,
          this.$refs.popper,
          this.popperOptions
        );
      } else {
        this.popper.update();
      }
    },
    showPopperComparison(newVal) {
      if (newVal) {
        this.showPopperPeriod = false;
        this.showPopperLactDay = false;
        this.countMaxHeightPopper();
      }
      if (!this.hideFavMenu && this.$refs.more && this.$refs.popper) {
        this.popper = createPopper(
          this.$refs.more,
          this.$refs.popper,
          this.popperOptions
        );
      } else {
        this.popper.update();
      }
    },
    showPopper(bool) {
      if (!bool) {
        this.showPopperPeriod = false;
        this.showPopperLactDay = false;
        this.showPopperComparison = false;
      } else {
        this.popper.update();
        this.countMaxHeightPopper();
      }
    },
    range() {
      this.parsePeriod();
    },
    "$sites.comparison"(val) {
      this.checkedSites = val;
    },
    hideContent() {
      // setTimeout to wait for the opened class to added/removed
      setTimeout(() => {
        // call tileOpened function in ModuleGridContainer
        if (this.$parent.$parent.$options.name === "ModuleGridContainer") {
          this.$parent.$parent.tileOpened();
        } else {
          if (
            this.$parent.$parent.$parent.$options.name === "ModuleGridContainer"
          ) {
            this.$parent.$parent.$parent.tileOpened();
          }
        }
      }, 0);
    },
    loading(newVal) {
      if (newVal === 0) {
        setTimeout(() => {
          this.loadingOpacity = 1;
        }, 1000);
      }

      if (newVal >= 1) this.loadingOpacity = 0;
    }
  },
  mounted() {
    if (
      !this.hideContent &&
      this.$parent &&
      this.$parent.$parent &&
      this.$parent.$parent.$parent &&
      this.$parent.$parent.$parent.startDrag
    ) {
      this.hideContent = true;
    }
    this.isCheckAll = this.checkedSites.length === this.$sites.sites.length;
    this.changeFavorites();
    setTimeout(() => {
      if (this.$refs.more && this.$refs.popper) {
        this.popper = createPopper(
          this.$refs.more,
          this.$refs.popper,
          this.popperOptions
        );
      }
      if (!this.hideFavMenu) {
        this.popperFav = createPopper(
          this.$refs.favorites,
          this.$refs.popperFav
        );
      }
      if (this.showUnity || this.showColumns) {
        createPopper(this.$refs.col, this.$refs.popperCol, this.popperOptions);
      }
      if (this.palettePref) {
        createPopper(
          this.$refs.palette,
          this.$refs.popperPalette,
          this.popperOptions
        );
      }
      if (this.infoText) {
        const popperOptions = this.popperOptions;
        popperOptions.placement = "left";
        this.popperInfo = createPopper(
          this.$refs.info,
          this.$refs.popperInfo,
          popperOptions
        );
      }
    }, 0);
    this.parsePeriod();
  },
  updated() {
    if (this.infoText && this.showPopperInfo) {
      const popperOptions = this.popperOptions;
      popperOptions.placement =
        this.$size.currentSize <= this.$size.MOBILE ? "right" : "left";
      if (this.popperInfo) {
        this.popperInfo.setOptions(popperOptions);
        this.popperInfo.update();
      } else {
        this.popperInfo = createPopper(
          this.$refs.info,
          this.$refs.popperInfo,
          popperOptions
        );
      }
    }
  },
  computed: {
    removeBr() {
      return string => string.replaceAll(/<br>/g, "");
    },
    tileName() {
      return this.$parent.$options.name.replace("Unique", "Main");
    },
    showTitle() {
      return this.title || this.collapsible || !this.hideFavMenu || this.unique;
    },
    excluded() {
      const currentLength = Object.values(this.favorites)
        .reduce((acc, val) => acc.concat(renameTiles(val)), [])
        .filter(e => e === this.tileName).length;

      if (this.showAdd) {
        return currentLength === Object.keys(this.favorites).length;
      }
      return (
        currentLength <= Object.keys(this.favorites).length && currentLength > 0
      );
    },
    inFav() {
      let favorites = this.$user.getPreference("favorites", []);

      for (let key in favorites) {
        if (renameTiles(favorites[key]).indexOf(this.tileName) > -1)
          return true;
      }
      return false;
    },
    getWeightPreferences() {
      return this.$user
        .getPreference("preferences.general.weightUnit")
        .toLowerCase();
    },
    isAdmin() {
      return siteId => {
        if (this.$user.role === "staff") return true;
        const site = this.$sites.sites.find(s => s.id === siteId);
        if (!site) return false;
        return this.$store.state.general.memberships.some(
          m =>
            m.tenant.cloud_id === site.tenant.cloud_id &&
            (m.role === "owner" || m.role === "admin") &&
            (m.sites === "all" || m.sites.includes(site.id))
        );
      };
    },
    filterAction() {
      return this.actions.find(f => f.eva == "eva-funnel-outline");
    }
  },
  methods: {
    changeFormatDate(date) {
      if (
        this.$user.getPreference("preferences.general.thousandDayCalendar") ===
        "on"
      ) {
        return formatDate1000(
          date.toFormat("LL/dd/yyyy"),
          this.$user.getPreference(
            "preferences.general.thousandDayCalendarFormat"
          )
        );
      }
      return date.toLocaleString(DateTime.DATE_FULL);
    },
    formatData(range, value, key) {
      if (value == null) return "";

      const weanedStats = [
        "total_weaned",
        "ps_total_weaned",
        "pre_weaned",
        "ps_wean_age"
      ];
      if (
        key != "net_transfer" &&
        key != "weaned_weight_loss" &&
        (value == -1 ||
          (value == 0
            ? weanedStats.includes(key.toString())
              ? range.data.sow_weaned <= 0
              : !key.toString().includes("parity")
            : false))
      ) {
        return "-";
      }
      if (value && key.includes("weight") && !key.startsWith("pc_")) {
        const weightPref = this.$user
          .getPreference("preferences.general.weightUnit")
          .toLowerCase();
        return parseFloat(gererKg(value / 100 / 1000, weightPref)).toFixed(2);
      }
      if (value && key.includes("time")) {
        const hours = Math.floor(value / 3600);
        const minutes = Math.floor((value % 3600) / 60);
        const seconds = Math.floor(value % 60);
        let formatted = "";
        if (hours) {
          formatted += hours + "h";
        }
        return (formatted += minutes + "m" + seconds + "s");
      }
      return Number(value) === value && value % 1 !== 0
        ? value.toFixed(2)
        : value;
    },
    calculatedName() {
      return this.unknownSows - this.unknownStatus;
    },
    calculatedStatus() {
      return this.unknownSows - this.unknownName;
    },
    calculatedNameStatus() {
      return this.unknownName + this.unknownStatus - this.unknownSows;
    },
    csvExport() {
      const route = this.$route.name;
      if (this.exportCSVFunction) {
        this.$emit("exportCSV", true);
        return;
      }
      const grids = this.$el.getElementsByClassName("gridContainer");
      const grid = grids[0].__vue__;
      const comparisonEnabled = grids.length > 1;
      let date = this.live ? new Date().toString() : this.title;
      if (this.live)
        date = date.split("GMT")[0].replace(":", "h").replace(":", "s");
      const monitoringDisplays = {
        simple: this.$t("performances.monitoring.simple"),
        detailed: this.$t("performances.monitoring.detailed"),
        gilts: this.$t("performances.monitoring.sections.gilts"),
        weaning: this.$t("performances.weaning"),
        inventory: this.$t("performances.inventory"),
        reproduction: this.$t("performances.reproduction"),
        farrowing: this.$t("performances.farrowing"),
        custom: this.$t("performances.monitoring.custom")
      };
      const csvName = (
        (route == "performances-monitoring"
          ? monitoringDisplays[grid.display] + "-"
          : "") +
        document.title.replaceAll(/ \| /g, "_") +
        "_" +
        date
      ).replaceAll(/ /g, "-");
      let arrData = grid.ranges;
      const translate = grid.translate;
      const csvHeader = "data:text/csv;charset=utf-8,%EF%BB%BF";
      let csvContent = "";
      let start = this.title;
      let end = "";

      const notRoutes =
        route != "performances-monitoring" && route != "locations";
      const isRoutes =
        route == "performances-monitoring" || route == "locations";
      const isLiveInventory = route == "active-sows";
      for (let i = 0; i < arrData.length; i++) {
        if (!arrData.length - 1) {
          csvContent += ";";
        }
        if (arrData[i].title) {
          start = arrData[i].title;
        } else if (this.live) {
          start = this.changeFormatDate(DateTime.local());
        } else if (arrData[i].start) {
          start = this.changeFormatDate(arrData[i].start);
          end = this.changeFormatDate(arrData[i].end);
        }
        if (comparisonEnabled) {
          for (let j = 0; j < grids.length; j++) {
            const g = grids[j].__vue__;
            const siteName = this.$sites.nameToDisplay(
              g.$props.site ? g.$props.site : g.$parent.$props.site
            );
            csvContent += (
              start +
              (end ? " / " + end : "") +
              (notRoutes
                ? " - " +
                  this.$t("total") +
                  " | " +
                  siteName +
                  ";" +
                  start +
                  (end ? " / " + end : "") +
                  " - " +
                  this.$t("percent") +
                  " | " +
                  siteName +
                  (isLiveInventory
                    ? ""
                    : ";" +
                      start +
                      (end ? " / " + end : "") +
                      " - " +
                      this.$t("performances.perSow") +
                      " | " +
                      siteName)
                : " | " + siteName)
            ).replace(/(^\[)|(\]$)/gm, "");
            if (j < grids.length - 1) csvContent += ";";
          }
        } else {
          csvContent += (
            start +
            (end ? " / " + end : "") +
            (notRoutes
              ? " - " +
                this.$t("total") +
                ";" +
                start +
                (end ? " / " + end : "") +
                " - " +
                this.$t("percent") +
                (isLiveInventory
                  ? ""
                  : ";" +
                    start +
                    (end ? " / " + end : "") +
                    " - " +
                    this.$t("performances.perSow"))
              : "")
          ).replace(/(^\[)|(\]$)/gm, "");
        }
        if (i === arrData.length - 1) {
          csvContent += "\r\n";
        }
      }

      for (let i = 0; i < arrData.length; i++) {
        let obj = arrData[i].data;
        for (let key in obj) {
          if (
            ["start", "end", "period_length", "ges_days"].includes(key) ||
            (route === "performances-monitoring" &&
              !grid.inCurrentDisplay(key)) ||
            (route === "sows-piglets" && key === "mortality")
          ) {
            continue;
          }

          if (
            obj[key] !== "undefined" &&
            (route == "herd-reproduction" ||
              isRoutes ||
              key == "ps_wean_age" ||
              (!key.includes("_pc") &&
                !key.includes("_ps") &&
                !key.includes("pc_") &&
                !key.includes("ps_")))
          ) {
            if (notRoutes) key = key.replace("pc_", "").replace("ps_", "");

            let percent, per_sow;
            const stat = (
              isRoutes
                ? this.$t(translate + "." + key) !== translate + "." + key
                  ? this.$t(translate + "." + key, {
                      length: (this.$user.getPreference(
                        "preferences.general.length"
                      ) == "ft"
                        ? this.$t("settings.prefs.unit.foot")
                        : this.$t("settings.prefs.unit.meter")
                      ).toLowerCase()
                    }).replace(/(<([^>]+)>)/gi, "")
                  : this.$t(`reports.piglets_mortality.${key}`) !==
                    `reports.piglets_mortality.${key}`
                  ? this.$t(`reports.piglets_mortality.${key}`)
                  : this.$t(
                      `performances.${key
                        .replace("pc_", "")
                        .replace("ps_", "")}`
                    )
                : this.$t(translate + "." + key)
            ).replace(/(^\[)|(\]$)/gm, "");

            if (notRoutes) {
              percent = key.toString();
              // .replace("_total", "")
              // .replace("total_", "");
              percent = percent + "_pc";
              per_sow = key.toString();
              // .replace("_total", "")
              // .replace("total_", "");
              per_sow = per_sow + "_ps";
              if (!arrData[i].data[percent]) {
                percent = percent.replace("_pc", "");
                percent = "pc_" + percent;
              }
              if (!arrData[i].data[per_sow]) {
                per_sow = per_sow.replace("_ps", "");
                per_sow = "ps_" + per_sow;
              }
            }
            if (i === 0) {
              for (let j = 0; j < grids.length; j++) {
                const data = grids[j].__vue__.ranges;
                csvContent +=
                  (j == 0 ? stat : "") +
                  ";" +
                  this.formatData(data[i], data[i].data[key], key);
                if (notRoutes) {
                  if (data[i].data[percent]) {
                    csvContent +=
                      ";" +
                      this.formatData(data[i], data[i].data[percent], percent);
                  } else {
                    csvContent += ";";
                  }
                  if (!isLiveInventory) {
                    if (data[i].data[per_sow]) {
                      csvContent +=
                        ";" +
                        this.formatData(
                          data[i],
                          data[i].data[per_sow],
                          per_sow
                        ) +
                        " ";
                    } else {
                      csvContent += ";";
                    }
                  }
                }
              }
              csvContent += "\r\n";
            } else {
              let splitCsvContent = csvContent.split("\r\n");
              for (let k = 0; k < splitCsvContent.length; k++) {
                for (let j = 0; j < grids.length; j++) {
                  const data = grids[j].__vue__.ranges;
                  if (splitCsvContent[k].split(";").includes(stat)) {
                    splitCsvContent[k] +=
                      ";" + this.formatData(data[i], data[i].data[key], key);
                    if (notRoutes) {
                      splitCsvContent[k] +=
                        ";" +
                        this.formatData(
                          data[i],
                          data[i].data[percent],
                          percent
                        );
                      if (!isLiveInventory) {
                        splitCsvContent[k] +=
                          ";" +
                          this.formatData(
                            data[i],
                            data[i].data[per_sow],
                            per_sow
                          );
                      }
                    }
                  }
                }
              }
              csvContent = "";
              for (let k = 0; k < splitCsvContent.length; k++) {
                csvContent += splitCsvContent[k] + "\r\n";
              }
            }
          }
        }
      }
      const data = csvHeader + encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", csvName + ".csv");
      link.click();
    },
    beforePrint() {
      const children = this.$parent.$parent.$children.filter(
        f => f.$el != this.$el
      );
      children.forEach(child =>
        child.$el.parentNode.classList.add("printHidden")
      );
    },
    print() {
      const tempTitle = document.title;
      if (!this.unique) {
        // when in simple view
        window.onbeforeprint = () => {
          this.beforePrint();
        };
        document.title = this.title.replace(/ /g, "-") + "_Vision";
        window.print();
        document.title = tempTitle;
        window.onafterprint = () => {
          this.afterPrint();
        };
      } else {
        document.title = tempTitle.replace(/ \| /g, "-").replace(/ /g, "-");
        window.print();
        document.title = tempTitle;
      }
    },
    afterPrint() {
      const children = this.$parent.$parent.$children.filter(
        f => f.$el != this.$el
      );
      children.forEach(child =>
        child.$el.parentNode.classList.remove("printHidden")
      );
    },
    navigate() {
      if (this.site) {
        this.showPopper = false;
        this.$emit("showMore", this.site);
      } else if (this.silo) {
        this.$emit("showUniqueGraph", this.silo);
      } else if (this.link && !this.unique) {
        this.$router.push({
          name: this.link,
          params: { breadcrumbs: this.breadcrumbs }
        });
      } else if (this.collapsible) {
        this.hideContent = !this.hideContent;
      }
    },
    parsePeriod() {
      if (this.range || this.periodChoice) {
        let parsed = parsePeriod(this.$user.getPreference(this.preference));
        this.periodAmount = parsed.amount;
        this.periodType = parsed.unit;
      }
    },
    async savePeriod() {
      let value = parseInt(this.periodAmount);
      switch (this.periodType) {
        case "day":
          value /= 1000;
          break;
        case "year":
          value *= 12;
          break;
      }
      this.showPopper = false;
      this.$emit("changePeriodInTile", value);
      await this.$user.savePreferences(this.preference, value);
    },
    async saveLactDay() {
      const fromDay = parseInt(this.fromLactDay);
      const toDay = parseInt(this.toLactDay);
      this.showPopper = false;
      await this.$user.savePreferences(
        "lactation.lactation_day_range",
        `${fromDay}/${toDay}`
      );
    },
    changeFavorites() {
      let prefFav = this.$user.getPreference("favorites", {});
      for (const key in prefFav) prefFav[key] = renameTiles(prefFav[key]);
      this.favorites = prefFav;
    },
    toggleAction(action) {
      if (action === "add") {
        this.showAdd = true;
        this.showRemove = false;
      } else {
        this.showAdd = false;
        this.showRemove = true;
      }
    },
    addToFavorites(event) {
      event.preventDefault();
      const form = event.target;
      let nbChecked = 0;
      let maxReached = false;
      for (let i = 0; i < form.length - 1; i++) {
        const id = form[i].id;
        const renamed = renameTiles(this.favorites[id] || []);
        if (
          form[i].checked &&
          this.favorites[id] &&
          renamed.indexOf(this.tileName) === -1
        ) {
          nbChecked++;
          if (renamed.length + 1 > 4) {
            maxReached = true;
            break;
          }
          this.$set(this.favorites, id, [...renamed, ...[this.tileName]]);
        }
      }
      if (nbChecked > 0 && nbChecked <= 4 && !maxReached) {
        this.$user
          .savePreferences("favorites", this.favorites, true)
          .then(() => {
            this.message = "addSuccess";
            this.messageTimeout = setTimeout(() => {
              this.message = "";
            }, 3000);
          })
          .catch(() => {
            this.messageTimeout = setTimeout(() => {
              this.message = "";
            }, 3000);
          });
      } else {
        if (nbChecked === 0) {
          this.message = "noselect";
        } else {
          this.message = "maxreached";
        }
      }
    },
    removeFromFavorites(event) {
      event.preventDefault();
      const form = event.target;
      let nbChecked = 0;
      for (let i = 0; i < form.length - 1; i++) {
        const id = form[i].id;
        const renamed = renameTiles(this.favorites[id] || []);
        if (
          form[i].checked &&
          this.favorites[id] &&
          renamed.indexOf(this.tileName) > -1
        ) {
          renamed.splice(renamed.indexOf(this.tileName), 1);
          this.$set(this.favorites, id, renamed);
          nbChecked++;
        }
      }
      if (nbChecked > 0) {
        this.$user
          .savePreferences("favorites", this.favorites, true)
          .then(() => {
            this.message = "removeSuccess";
            this.messageTimeout = setTimeout(() => {
              this.message = "";
            }, 3000);
          })
          .catch(() => {
            this.messageTimeout = setTimeout(() => {
              this.message = "";
            }, 3000);
          });
      } else {
        this.message = "noselect";
      }
    },
    callComparison() {
      this.$sites.setComparison(this.checkedSites);
      this.comparison();
      this.showPopper = false;
    },
    checkAll() {
      this.isCheckAll = !this.isCheckAll;
      this.checkedSites = [];
      if (this.isCheckAll) {
        // Check all
        this.$sites.sites.forEach((e, id) => {
          if (this.checkedSites.findIndex(i => i.id === e.id) === -1)
            this.$set(this.checkedSites, id, e);
        });
      }
    },
    updateCheckall() {
      this.isCheckAll = this.checkedSites.length === this.$sites.sites.length;
    },
    countMaxHeightPopper() {
      const parentHeight = window.innerHeight;
      this.$refs.popper.style.maxHeight = `${
        parentHeight - this.$refs.more.getBoundingClientRect().top - 60
      }px`;
    },
    emitShowSite() {
      this.showPopper = false;
      this.$emit("showEdit", this.site || this.building);
    },
    emitDeleteSite() {
      this.showPopper = false;
      this.$emit("deleteSite", this.site || this.building);
    },
    emitDisable() {
      this.showPopper = false;
      this.$emit("disableSilo", this.silo);
    },
    emitShowSilo() {
      this.showPopper = false;
      this.$emit("showSilo", this.silo);
    },
    emitShowSiloGraph() {
      this.showPopper = false;
      this.$emit("showGraph", this.silo);
    }
  }
};
</script>

<style lang="stylus" scoped>
.loading
  z-index 1000
  display block
  user-select none
  pointer-events none
  position absolute
  width 115px
  margin 0 auto
  left 0
  right 0

.eva.eva-more-horizontal
  background-color var(--blue)
  color white
  border-radius 50%
  padding 5px

.eva.eva-alert-triangle-outline
  color var(--red)
  font-size 24px
.tooltip.alertBottom
  bottom -135px
  padding 10px 15px
  .text
    padding 0 0 10px 15px
    font-size 14px
    &:first-child
      padding 10px 0
      font-size 16px

.tile
  background-color white
  border-radius 3px
  box-shadow 0 2px 4px 1px rgba(0, 0, 0, 0.2)
  display flex
  flex-direction column
  max-height 100%
  &.opened
    height 100%
.title
  font-size 20px
  color var(--black)
  border-bottom 1px solid var(--athens-gray)
  padding 10px 5px 10px 20px
  display flex
  position relative
  min-height 57px
  max-height 57px
  &.title2
    padding-right 20px
    @media print
      display none
  &.hideContent
    border-color white
  .favorite
    position absolute
    top -8px
    left -8px
    padding 10px
    display flex
    align-items center
    cursor pointer
    font-size 20px
    .eva
      color rgb(255, 190, 29)
  .text
    display flex
    align-items center
    line-height 1
    & + .grow
      cursor pointer
      &.disabled
        pointer-events none
    &.pointer
      cursor pointer
    &.tileLink
      cursor pointer
      position relative
      color #0747a6
      &:hover
        text-decoration underline
    .bin--level
      &:before
        content ""
        display inline-block
        width 8px
        height 8px
        background-color var(--green)
        margin-left -10px
        margin-right 10px
        border-radius 100%
        position relative
        vertical-align middle
      &__warn:before
        background-color var(--orange)
      &__warn-alert:before
        background-color var(--red-orange)
      &__alert:before
        background-color var(--red)
    &-ellipsis
      display inline-block
      margin auto 0
      white-space nowrap !important
      overflow hidden
      text-overflow ellipsis
  .tileObjective
    position relative
    display flex
    align-items center
    .name
      font-size 12px
      padding 0 6px 0 10px
      line-height 1.2
      text-align right
    .value
      position relative
      z-index 1
      font-size 18px
      min-width 50px
      text-align center
      font-weight 900
      flex-shrink 0
    .tileFlag
      position absolute
      top 50%
      right 0px
      height 36px
      transform translateY(-50%);
  .live
    font-size 16px
    border 2px solid var(--lightGreen)
    color var(--lightGreen)
    padding 5px 8px 7px
    border-radius var(--global-radius)
    display flex
    align-items center
    line-height 1
    margin-left 10px
    white-space nowrap
    &:before
      content: ""
      display inline-block
      width 8px
      height 8px
      background-color var(--lightGreen)
      margin-right 8px
      border-radius 100%
      animation pulse 2s infinite
      top 2px
      position relative
      @keyframes pulse
        0%
          box-shadow 0 0 0 0 rgba(0, 170, 0, 0.8)
        70%
          box-shadow 0 0 0 10px rgba(0, 170, 0, 0)
        100%
          box-shadow 0 0 0 0 rgba(0, 170, 0, 0)
  .tileRange
    display flex
    align-items center
    font-size 16px
    opacity 0.5
    margin-left 10px
    white-space nowrap
  .grow
    flex-grow 1
  .more
    display flex
    align-items center
    padding 0 10px
    cursor pointer
    @media print
      display none
  .collapse
    display flex
    align-items center
    padding-right 10px
    color #aaa
    cursor pointer
    @media print
      display none
  .actions
    display flex
    align-items center
    .action
      font-size 20px
      padding 3px 10px
      background-color var(--blue)
      border-radius 3px
      color var(--athens-gray)
      cursor pointer
      display flex
      align-items center
      justify-content center
      .icon
        display flex
        align-items center
        margin 3px 0
        fill var(--athens-gray)
        position relative
        &.eva
          font-size 24px
        .activeTag
          position absolute
          top -4px
          right -4px
          background-color var(--red)
          border-radius 10px
          width 12px
          height 12px
      &:hover
        box-shadow 0 2px 4px 1px rgba(0, 0, 0, 0.2)
      &:not(:last-child)
        margin-right 10px
  .col-display, .info-display
    font-size 16px
    display flex
    justify-content center
    align-items center
    cursor pointer
    margin-right 10px
    .eva
      font-size 20px
  .col-display
    @media screen and (--max-tablette)
      display none
  .deja-vu span:first-child
    margin-right 10px
  .popper--content
    max-height inherit
    padding 10px 0
    overflow auto
    &::-webkit-scrollbar-thumb
      background-color var(--blue) !important
  .popper--info
    max-width 60vw
    div
      max-height 80vh
      overflow auto
      @media screen and (--min-tablette)
        max-height 70vh
      &::-webkit-scrollbar-thumb
        background-color var(--blue) !important
    .popperArrow
      left -14px
      bottom auto
      text-shadow -2px 0 2px rgba(0, 0, 0, 0.2)
      overflow hidden
      @media screen and (--mobile)
        left auto
        right -14px
        text-shadow 2px 0 2px rgba(0, 0, 0, 0.2)
.content
  flex-grow 1
  overflow auto
  position relative
  display flex
  flex-direction column
  padding 20px
  &.hideContent
    height 0
    padding 0
    overflow hidden
  .loadingBar
    height 5px
    background-color lighten(#0747a6, 90%)
    position absolute
    top 0
    left 0
    right 0
    pointer-events none
    opacity 0
    transition opacity 0.5s ease
    animation loadingPulse 3s ease infinite
    z-index 10
    @keyframes loadingPulse
      0%
        background-color lighten(#0747a6, 90%)
      30%
        background-color lighten(#0747a6, 90%)
      50%
        background-color lighten(#0747a6, 80%)
      70%
        background-color lighten(#0747a6, 90%)
      100%
        background-color lighten(#0747a6, 90%)
    &.show
      opacity 1
      transition-delay 0.5s
      .loadingInner
        transition width 0.3s ease
    &:not(.show)
      background-color #0747a6
      .loadingInner
        width 0 !important
    .loadingInner
      height 100%
      background-color #0747a6
.section ul
  display flex
  flex-flow column wrap
  justify-content space-between

.cloud.dark
  .live
    border-color var(--lightGreen)
    color var(--lightGreen)
    &::before
      animation pulse-dark 2s infinite
      background-color var(--lightGreen)
      @keyframes pulse-dark
        0%
          box-shadow 0 0 0 0 rgba(0, 170, 0, 0.8)
        70%
          box-shadow 0 0 0 10px rgba(0, 170, 0, 0)
        100%
          box-shadow 0 0 0 0 rgba(0, 170, 0, 0)
  .eva.eva-alert-triangle-outline
    color var(--dark-mode-red)
  .text.tileLink
    color lighten(#313f52, 60%)
    @media print
      color #0747a6
  .loadingBar
    animation loadingPulseDark 3s ease infinite
    @keyframes loadingPulseDark
      0%
        background-color lighten(#313f52, 10%)
      30%
        background-color lighten(#313f52, 10%)
      50%
        background-color lighten(#313f52, 20%)
      70%
        background-color lighten(#313f52, 10%)
      100%
        background-color lighten(#313f52, 10%)
    .loadingInner
      background-color lighten(#313f52, 50%)
@media screen and (--max-tablette)
  .tile
    box-shadow none
    border-top 1px solid #c3cbd5
    border-bottom 1px solid #c3cbd5
    border-radius 0
    .title
      .text
        font-size 16px
        white-space normal
        line-height 1
      .live
        border none
        padding-right 0
        margin-right -6px
        .liveText
          display none
      .tileRange
        display none
      .tileObjective .name
        display none
  .cloud.dark
    .tile
      border-color #3f4b5a

@media print and (min-width 1px)
  .content.hideContent
    height auto
    padding 20px
    overflow auto
</style>

<style lang="stylus">
.tileFlag
  opacity 0.5
  &.yellow
    animation midflash 1.5s ease-in-out infinite
  &.red
    animation flash 1.5s ease-in-out infinite
  @keyframes midflash {
    0% {
      opacity 0.5
    }
    50% {
      opacity 0.75
    }
    100% {
      opacity 0.5
    }
  }
  @keyframes flash {
    0% {
      opacity 0.5
    }
    50% {
      opacity 1
    }
    100% {
      opacity 0.5
    }
  }
  svg
    width 50px
    height 44px
.cloud.dark .tileFlag
  opacity 0.6
  &.yellow
    animation midflashdark 1.5s ease-in-out infinite
  &.red
    animation flashdark 1.5s ease-in-out infinite
  @keyframes midflashdark {
    0% {
      opacity 0.6
    }
    50% {
      opacity 0.8
    }
    100% {
      opacity 0.6
    }
  }
  @keyframes flashdark {
    0% {
      opacity 0.6
    }
    50% {
      opacity 1
    }
    100% {
      opacity 0.6
    }
  }
.cloud.dark .tile
  background-color var(--dark-SB)
  .title
    color var(--athens-gray)
    border-color #313f52
    &.hideContent
      border-color var(--dark-SB)
.perspectiveBlob
    transform perspective(600px) scale(1.5) rotateY(60deg) rotateX(20deg)
.tile .displayFlex
  padding-bottom 0
  display flex
  height 37px
  transform none !important
  .modal-default-button
    padding 3px 10px
    height 37px
    display flex
    align-items center
    .cloud-icon
      display flex
      align-items center
@media screen and (--max-tablette)
  .tile .title2
    padding 0 !important
    min-height auto
    font-size 14px
    overflow auto
    &::-webkit-scrollbar
      height 10px
    .grow + .actions .action:first-child
        border-left solid 1px white
    .actions .action, .modal-default-button
      border-radius 0
      font-size 14px
      height 40px
      width auto
      min-width 44px
      padding 3px
      margin-right 0 !important
      margin-left 0 !important
      &:not(:last-child)
        border-right 1px solid white
    .modal-default-button:first-child
      border-left solid 1px white
    .displayFlex
      height auto
    .grow
      min-width 0
</style>
