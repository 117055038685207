import { i18n } from "@/i18n";

/**
 * To format number based on language
 * @param {*|string} value
 * @param {boolean} [showDecimals=true]
 * @return {*|string}
 */
export function formatNumber(value, showDecimals = true) {
  if (value === null || value === undefined || value === "") {
    return "";
  }

  if (typeof value !== "undefined" && !isNaN(value) && value !== true) {
    const numberValue = parseFloat(value);

    const formattedValue = numberValue.toLocaleString(i18n.locale, {
      useGrouping: true,
      minimumFractionDigits: showDecimals ? 2 : 0,
      maximumFractionDigits: showDecimals ? 2 : 0
    });
    return formattedValue;
  }
  return value;
}
