import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "@/lang/en.json";
import fr from "@/lang/fr.json";
import es from "@/lang/es.json";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: navigator.language,
  fallbackLocale: "en",
  silentTranslationWarn: true,
  messages: { en, fr, es }
});
