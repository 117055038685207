<template lang="pug">
.tooltip(ref="tooltip" :class="{show: show, bottom: bottom}")
  slot
  .tooltipArrow(ref="tooltipArrow")
</template>

<script>
export default {
  name: "tooltip",
  props: {
    bottom: Boolean
  },
  data() {
    return {
      parent: null,
      show: false
    };
  },
  mounted() {
    this.parent = this.$el.parentElement;
    this.parent.style.position = "relative";
    this.parent.addEventListener("mouseover", () => {
      this.show = true;
    });
    this.parent.addEventListener("mouseout", () => {
      this.show = false;
    });
    // reposition when tooltip is going out of screen from right
    const parentRight = this.parent.getBoundingClientRect().right;
    const tooltipWidth = this.$refs.tooltip.clientWidth;
    if (parentRight + tooltipWidth / 2 > window.innerWidth) {
      this.$refs.tooltip.style.transform = `translateX(-${
        tooltipWidth - (window.innerWidth - parentRight)
      }px)`;
      this.$refs.tooltipArrow.style.left = `${
        ((this.parent.getBoundingClientRect().left +
          this.parent.clientWidth / 2 -
          this.$refs.tooltip.getBoundingClientRect().left) *
          100) /
        tooltipWidth
      }%`;
    }
  }
};
</script>

<style lang="stylus" scoped>
.tooltip
  font-family 'Lato', -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Helvetica, Arial, sans-serif
  line-height 1.5
  background-color white
  color #333
  position absolute
  padding 2px 8px
  border-radius 3px
  box-shadow 0 2px 4px 1px rgba(0, 0, 0, 0.2)
  bottom calc(100% + 12px)
  display none
  pointer-events none
  transition opacity 0.1s ease
  z-index 200
  font-size 20px
  left 50%
  transform translateX(-50%)
  white-space nowrap
  &.show
    display block
  .tooltipArrow
    position absolute
    top calc(100% - 6px)
    left 50%
    background-color white
    width 12px
    height 12px
    transform translateX(-50%) rotateZ(45deg)
    border-top-left-radius 100px
    box-shadow 2px 2px 2px 0 rgba(0, 0, 0, 0.2)
  &.bottom
    bottom -46px
    .tooltipArrow
      top auto
      bottom calc(100% - 6px)
      border-top-left-radius 0
      border-bottom-right-radius 100px
      box-shadow none
</style>
