import store from "@/store";
import { DateTime } from "luxon";
import { momentToLuxon } from "@/functions/small-function";

export function orderDate(date) {
  let year = date.substr(date.indexOf("-") - 4, 4);
  let month = date.substr(date.indexOf("-") + 1, 2);
  let day = date.substr(date.indexOf("-") + 4, 2);
  return month + "-" + day + "-" + year;
}

export function calcul1000Days(date) {
  const date2 = new Date("1971-09-27");
  let tmp = new Date(date) - date2;
  let diff = {};
  tmp = Math.floor(tmp / 1000); // Nombre de secondes entre les 2 dates
  diff.sec = tmp % 60; // Extraction du nombre de secondes

  tmp = Math.floor((tmp - diff.sec) / 60); // Nombre de minutes (partie entière)
  diff.min = tmp % 60; // Extraction du nombre de minutes

  tmp = Math.floor((tmp - diff.min) / 60); // Nombre d'heures (entières)
  diff.hour = tmp % 24; // Extraction du nombre d'heures

  return parseFloat(Math.floor((tmp - diff.hour) / 24) / 1000).toFixed(3); // Nombre de jours restants
}

export function formatDate(date) {
  const pref = store.state.user.getPreference("preferences.general");
  const formatedDate = date.substr(0, date.indexOf("T"));
  const luxonDate = DateTime.fromISO(formatedDate);
  if (date) {
    if (pref.DateFormat === "humanFormat") {
      return luxonDate.toLocaleString(DateTime.DATE_FULL);
    }
    return luxonDate.toFormat(momentToLuxon(pref.DateFormat));
  }
}

export function formatDate1000(date, calendarFormat) {
  const day = calcul1000Days(date).toString().split(".");
  const dayThousand = day[1];
  const cycle = parseFloat(day[0]);
  let value = "";
  switch (calendarFormat) {
    case "TTT":
      return (value = dayThousand);
    case "TTT-CC":
      return (value = dayThousand + "-" + cycle);
    case "CC-TTT":
      return (value = cycle + "-" + dayThousand);
  }
  return value;
}

export function formatHour(date) {
  const time = new Date(date);
  const timeZone = new Date(time + time.getTimezoneOffset() * 60 * 1000);
  const hour = timeZone.getHours();
  const min = timeZone.getMinutes();
  return hour + "h " + min + "m";
}

export function splitDate(date) {
  if (date && date.length <= 10) {
    return date;
  }
  if (date) {
    return date.toISOString().split("T")[0];
  }
  return false;
}

export function dateDiff(date) {
  const start = date.substring(0, date.indexOf("/")).replace(/-/g, "/");
  const end = date.substring(date.indexOf("/") + 1).replace(/-/g, "/");

  if (date.indexOf("/") === -1) {
    return date;
  }
  return (
    Math.round((new Date(end) - new Date(start)) / (24 * 60 * 60 * 1000)) + 1
  );
}

export function appendLeadingZeroes(n) {
  if (n <= 9) {
    return "0" + n;
  }
  return n;
}

export function dateRange(date, self) {
  if (!parseInt(date) < 1) {
    return (
      date + " " + self.$t(`date.month.${date > 1 ? "titlePlural" : "title"}`)
    );
  } else {
    return date * 1000 + " " + self.$t("date.days").toLowerCase();
  }
}

export function previousMonths(date, months) {
  let formattedDate = date;
  if (typeof date != "object") {
    formattedDate = formattedDate.replace(/-/g, "/");
  }
  let timestamp = "";
  if (months > 0 && months < 1) {
    // back in number of days
    timestamp = new Date(
      new Date(formattedDate).setDate(
        new Date(formattedDate).getDate() - Math.round(months * 1000)
      )
    );
  } else {
    timestamp = new Date(
      new Date(formattedDate).setMonth(
        new Date(formattedDate).getMonth() - months
      )
    );
  }
  let month = timestamp.getMonth() + 1;
  if (month === -1) {
    month = 12;
  }

  return (
    timestamp.getFullYear() +
    "-" +
    appendLeadingZeroes(month) +
    "-" +
    appendLeadingZeroes(timestamp.getDate())
  );
}
