//@ts-check
import Axios from "axios";
import Store from "../store";
import { isEqual } from "lodash-es";

class RequestHelperClass {
  constructor() {
    this.getAllPagesCache = [];
    this.enabled = false;
    this.baseUrl = process.env.VUE_APP_API_URL;
  }

  getToken() {
    return Store.state.general.token;
  }

  /**
   * @param {string} url
   * @param {object} [params]
   * @param {string|string[]|null} [siteTimezone = null]
   */
  async get(url, params, siteTimezone = null) {
    let cached = this.getCache(url, params);
    if (cached) {
      if (cached.data) {
        return cached.data;
      } else {
        let promise = new Promise(r => {
          cached.callbacks.push(r);
        });
        let data = await promise;
        return data;
      }
    }
    siteTimezone = this._getTimeZone(siteTimezone);
    const manyTimezones = Array.isArray(siteTimezone);
    let error = null;
    let request = await Axios.get(this.baseUrl + url, {
      params: {
        ...params,
        timezone: this._getTimeZone(),
        site_timezone: manyTimezones ? null : siteTimezone,
        site_timezones: manyTimezones ? siteTimezone.join(",") : null
      },
      headers: { Authorization: "Bearer " + this.getToken() }
    }).catch(e => (error = e.response));
    let cache = this.addCache(url, params);
    if (request == undefined) {
      return {
        status: 500
      };
    }

    if (cache && !error) {
      cache.data = request.data;
      for (let c of cache.callbacks) {
        c(request.data);
      }
    }
    return error || request.data || request;
  }

  /**
   * @param {string} url
   * @param {any} data
   * @param {object} [params]
   */
  async post(url, data, params) {
    let request = await Axios.post(this.baseUrl + url, data, {
      params,
      headers: { Authorization: "Bearer " + this.getToken() }
    });
    return request;
  }

  /**
   * @param {string} url
   * @param {any} data
   * @param {object} [params]
   */
  async patch(url, data, params) {
    let error = null;
    let request = await Axios.patch(this.baseUrl + url, data, {
      params,
      headers: { Authorization: "Bearer " + this.getToken() }
    }).catch(e => (error = e.response));
    return error || request.data || request;
  }

  /**
   * @param {string} url
   */
  async delete(url) {
    let request = await Axios.delete(this.baseUrl + url, {
      headers: { Authorization: "Bearer " + this.getToken() }
    });
    return request;
  }

  /**
   * @param {string} url
   * @param {object} [params]
   * @param {Function} [pageCallback]
   */
  async getAllPages(url, params, pageCallback) {
    let cached = this.getCache(url, params);
    if (cached) {
      if (cached.data) {
        if (pageCallback) {
          pageCallback(1, 1);
        }
        return cached.data;
      } else {
        let promise = new Promise(r => {
          cached.callbacks.push(r);
        });
        let data = await promise;
        if (pageCallback) {
          pageCallback(1, 1);
        }
        return data;
      }
    }
    let cache = this.addCache(url, params);
    let start = Date.now();
    let error = null;
    let request = await Axios.get(this.baseUrl + url, {
      params: {
        ...params,
        page: 1,
        per_page: 1000,
        timezone: this._getTimeZone()
      },
      headers: { Authorization: "Bearer " + this.getToken() }
    }).catch(e => (error = e.response));

    if (error) return error;

    let end = Date.now();
    let data = request.data.data;
    let pagesLoaded = 1;
    if (pageCallback) {
      pageCallback(pagesLoaded, request.data.meta.pages);
    }
    if (request.data.meta.pages > 1) {
      let requests = [];
      for (let i = 2; i <= request.data.meta.pages; i++) {
        requests.push(
          Axios.get(this.baseUrl + url, {
            params: {
              ...params,
              page: i,
              per_page: 1000,
              timezone: this._getTimeZone()
            },
            headers: { Authorization: "Bearer " + this.getToken() }
          }).then(response => {
            if (pageCallback) {
              pageCallback(++pagesLoaded, request.data.meta.pages);
            }
            return response;
          })
        );
      }
      let results = await Promise.all(requests);
      end = Date.now();
      for (let r of results) {
        data = [...data, ...r.data.data];
      }
    }
    data.meta = request.data.meta;
    data.config = request.config;
    data.duration = end - start;
    if (cache) {
      cache.data = data;
      for (let c of cache.callbacks) {
        c(data);
      }
    }
    return data;
  }

  /**
   * @param {string} url
   * @param {object} params
   */
  getCache(url, params) {
    if (!this.enabled) {
      return null;
    }
    // Clear old locks
    this.getAllPagesCache = this.getAllPagesCache.filter(l => {
      return l.date >= Date.now() - 1000 * 10;
    });
    for (let cache of this.getAllPagesCache) {
      if (cache.url == url && isEqual(params, cache.params)) {
        return cache;
      }
    }
    return null;
  }

  /**
   * @param {string} url
   * @param {object} params
   * @param {any} [data]
   */
  addCache(url, params, data) {
    if (!this.enabled) {
      return null;
    }
    let cache = new RequestHelperCache(url, params, data);
    this.getAllPagesCache.push(cache);
    return cache;
  }

  /**
   * @param {string|string[]|null} [timezone = null]
   * @return {string|string[]} Timezone as Standard Time
   * @private
   */
  _getTimeZone(timezone = null) {
    if (
      timezone !== null &&
      timezone.trim() !== "" &&
      Array.isArray(timezone)
    ) {
      let timezones = [];
      for (const current of timezone) {
        timezones.push(this._getTimeZone(current));
      }
      return timezones;
    }
    if (timezone === null || timezone.trim() === "") {
      timezone = new Date()
        .toLocaleDateString("en-US", {
          day: "2-digit",
          timeZoneName: "long"
        })
        .slice(4);
    }
    return timezone.replaceAll("Daylight", "Standard");
  }
}

class RequestHelperCache {
  /**
   * @param {string} url
   * @param {object} params
   * @param {any} [data]
   */
  constructor(url, params, data) {
    this.url = url;
    this.params = params;
    this.data = data || null;
    this.callbacks = [];
    this.date = Date.now();
  }
}

let RequestHelper = new RequestHelperClass();
export default RequestHelper;
