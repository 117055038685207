import store from "@/store";
const Integrations = () => import("@/views/Integrations");
const AccessKeys = () => import("@/views/AccessKeys");

export default [
  {
    name: "integrations",
    path: "integrations",
    component: Integrations,
    meta: {
      title: "Integrations | Cloud",
      description: "Displays the integrator's integrations"
    },
    beforeEnter(to, from, next) {
      // if there is no token settled
      if (store.state.user.role !== "integrator") {
        return next({
          name: "dashboard",
          params: {
            lang: to.params.lang
          }
        });
      }
      return next();
    }
  },
  {
    name: "access-keys",
    path: "access-keys/:site_name/:site_id/:inst_id",
    component: AccessKeys,
    meta: {
      title: "Access Keys | Cloud",
      description: "Displays the integrator's access-keys"
    },
    beforeEnter(to, from, next) {
      // if there is no token settled
      if (store.state.user.role !== "integrator") {
        return next({
          name: "dashboard",
          params: {
            lang: to.params.lang
          }
        });
      }
      return next();
    }
  }
];
