<template lang="pug">
div.displayFlex.buttonGroup
    button.modal-default-button(
      v-if="!hideUnity.includes('standard')"
      @click="currentButton('standard')"
      :class="modeShow === 'standard' ? 'current-button buttonDateCurrent' : ''"
      type="button"
      role="button"
    )
        .eva.eva-menu.rotated
        Tooltip(:bottom="bottom") {{ $t("horizontalLayout") }}
    button.modal-default-button.doubleLine(
      v-if="!hideUnity.includes('compact')"
      @click="currentButton('compact')"
      :class="modeShow === 'compact' ? 'current-button buttonDateCurrent' : ''"
      type="button"
      role="button"
    )
        .eva.eva-menu
        Tooltip(:bottom="bottom") {{ $t("verticalLayout") }}
    button.modal-default-button(
      v-if="!hideUnity.includes('total')"
      @click="currentButton('total')"
      :class="modeShow === 'total' ? 'current-button buttonDateCurrent' : ''"
      type="button"
      role="button"
    )
        .eva.eva-plus-circle-outline
        Tooltip(:bottom="bottom") {{ $t("total") }} / {{ $t("gestation.average") }}
    button.modal-default-button(
      v-if="!hideUnity.includes('percent')"
      @click="currentButton('percent')"
      :class="modeShow === 'percent' ? 'current-button buttonDateCurrent' : ''"
      type="button"
      role="button"
    )
        .eva.eva-percent
        Tooltip(:bottom="bottom") {{ $t("percent") }}

    button.modal-default-button.menu-link(
      v-if="!hideUnity.includes('per_sow')"
      @click="currentButton('per_sow')"
      :class="modeShow === 'per_sow' ? 'current-button buttonDateCurrent' : ''"
      type="button"
      role="button"
    )
        cloud-icon.icon(name="pig3")
        Tooltip(:bottom="bottom") {{ $t("performances.perSow") }}
</template>

<script>
import Tooltip from "@/components/Tooltip.vue";

export default {
  name: "cloud-buttons-units",
  components: { Tooltip },
  props: {
    hideUnity: Array,
    bottom: Boolean
  },
  data() {
    return {
      modeShow: this.$user.getPreference("preferences.general.gridDisplay")
    };
  },
  methods: {
    currentButton(button) {
      this.modeShow = button;
      this.$user.savePreferences("preferences.general.gridDisplay", button);
    }
  }
};
</script>

<style lang="stylus" scoped>
.displayFlex
    justify-content center
    padding-bottom 1rem
button
    display flex
    height 50px
    width 70px
    margin-left 2px
    text-align center
    justify-content center
    align-items center
    flex-wrap wrap
.rotated
    transform rotateZ(90deg)
.buttonGroup
  button
    margin 0
    padding 3px 10px
    width auto
    font-size 20px
    min-width 44px
    border-radius 0
    box-shadow none !important
    &:first-child
      border-top-left-radius 3px
      border-bottom-left-radius 3px
    &:last-child
      border-top-right-radius 3px
      border-bottom-right-radius 3px
</style>
