<template lang="pug">
  div
    h1 TERMS OF USE
    p These terms of use (“Terms”) govern your use of this LES LOGICIELS GESTAL INC. (“GESTAL”)’s application named “Vision”
      | &nbsp;where these Terms are posted (“Application”) located and hosted on Amazon Web Services.
      | &nbsp;By accessing, using or posting anything to the Application, you agree to use the Application in accordance with
      | &nbsp;all applicable laws and regulations and in accordance with the terms and conditions outlined herein.
      | &nbsp;These Terms may be updated from time to time and the most recent version of the Terms will be posted on the Application.
      | &nbsp;Your use of the Application constitutes your acceptance of these Terms, and any changes thereto.
      | &nbsp;IF YOU DO NOT AGREE TO THESE TERMS, DO NOT USE THE APPLICATION.

    section
      h2 Data analysis
      p This Application, including application contents, such as text, graphics, images, and information obtained from
        | &nbsp;the Application and its licensors, is intended to be used or viewed to analyze data on farms.

    section
      h2 Ownership of Intellectual Property
      p All content on the Application that we provide, including but not limited to, text, graphics, logos, icons, images,
        | &nbsp;headers, illustrations, audio clips, video clips and software is owned or licensed by GESTAL, its affiliates or
        | &nbsp;the content providers, and is protected by law, including Canadian, United States and international copyright laws.
        | &nbsp;In addition, any trade names, trademarks (registered or unregistered) or other intellectual property are also
        | &nbsp;owned by GESTAL, its affiliates or the content providers and any use, misuse or copying of any trademarks or
        | &nbsp;intellectual property is prohibited without our express authorization. Except as expressly authorized in writing,
        | &nbsp;you agree not to reproduce, modify, rent, lease, loan, sell, distribute, mirror, frame, republish, download,
        | &nbsp;transmit, or create derivative works of the content of others, in whole or in part, by any means.

    section
      h2 License
      p GESTAL grants you a limited, non-exclusive and revocable license to use the Application in accordance with these Terms.
        | &nbsp;The content on the Application that is posted by GESTAL, including but not limited to any text and images contained
        | &nbsp;herein and their selection and arrangement, is owned by GESTAL, its affiliates or our content providers. You may
        | &nbsp;download material from the Application that is posted by GESTAL for your personal use; however, you may not transmit,
        | &nbsp;broadcast, upload to any computer, mobile or other device, create derivative works of, or make commercial use of
        | &nbsp;the Application or any material provided or obtained through the Application, including, but not limited to, the
        | &nbsp;names, images and other information used in any User Content (as defined below). You acknowledge that we or our
        | &nbsp;third party content providers retain ownership of the posted materials and that you are not acquiring any ownership
        | &nbsp;rights by downloading copyrighted materials.

    section
      h2 Color Correctness Disclaimer
      p Product photography and illustrations have been reproduced as accurately as print and web technologies permit.
        | &nbsp;However, due to variations in individual user’s monitor settings, calibrations, color printing settings, and
        | &nbsp;lighting sources, we cannot guarantee that your products will be an exact match to the colors represented on this
        | &nbsp;Application or on a home printer output. To ensure highest satisfaction, we suggest you view an actual sample from us.

    section
      h2 Your Representations and Warranties
      p You represent and warrant that you possess the legal right and ability to agree to these Terms, that you are over
        | &nbsp;the age of 13, and that all information or material that you submit to the Site, including any images, photographs,
        | &nbsp;graphics or pictures (“User Content”) is owned by you, is true, accurate and current. By submitting any User Content
        | &nbsp;to or through the Site, you represent and warrant that: (a) you are the copyright owner of such User Content; and
        | &nbsp;(b) you have the rights necessary to grant the licenses and sublicenses described in these Terms.
      p You further agree that you will not:
      ol(type="i")
        li Submit any material that is copyrighted, protected by trade secret or otherwise subject to third party proprietary
          | &nbsp;rights, including privacy and publicity rights, unless you are the owner of such rights or have written permission
          | &nbsp;from their rightful owner to post the material and to grant the right to use such materials as provided in these
          | &nbsp;Terms (this includes, but is not limited to, a prohibition on submitting a photograph of or including another
          | &nbsp;person without that person’s permission);
        li Post on the Application any false information or misrepresentation that could result in liability or damage to
          | &nbsp;GESTAL or any third party;
        li Submit any material that is unlawful, obscene, defamatory, libelous, threatening, pornographic, graphic or
          | &nbsp;gratuitously violent, harassing, hateful, racially or ethnically offensive, encourages conduct that would give
          | &nbsp;rise to criminal or civil liability, violate any law, or is otherwise inappropriate;
        li Impersonate another person, including submitting their photographs as your own;
        li Collect users’ information, or otherwise access website, using automated means (such as harvesting bots, robots,
          | &nbsp;spiders, or scrapers) without our permission; or
        li Transmit worms, viruses or other malicious codes.
      p The Application may allow you to access and interoperate with third party websites, software applications, and data
        | &nbsp;services (“Third Party Applications”). Your access to and use of any Third Party Applications is governed by the
        | &nbsp;terms and conditions respecting such offerings provided to you at the time you access such Third Party Applications,
        | &nbsp;as well as any applicable third party rules, requirements or terms, and you hereby agree to adhere to such terms.
        | &nbsp;You understand that in submitting any User Content, you are giving GESTAL and its designees an exclusive (unless
        | &nbsp;specifically stated otherwise in this Application), worldwide, perpetual, irrevocable, royalty-free, sub-licensable
        | &nbsp;right and license to exploit the User Content and any person’s property (physical, personal, and indicia) contained
        | &nbsp;therein, in any media now or hereafter known, without any payment or other consideration of any kind, or permission
        | &nbsp;or notification, to you or any third party. GESTAL’s rights shall include, without limitation, the right to reproduce,
        | &nbsp;record, alter, amend, edit, publish, publicly perform, use, merchandise, license, sublicense (through multiple
        | &nbsp;tiers), adapt, and stream the User Content in any and all media now or hereafter known, including but not limited
        | &nbsp;to, the Internet and outdoor billboards, throughout the world, related to GESTAL, its agents and designees. You
        | &nbsp;acknowledge and agree that GESTAL has now, nor shall have in the future, any duty or liability, direct or indirect,
        | &nbsp;vicarious, contributory, or otherwise, with respect to the infringement or protection of any copyright or any
        | &nbsp;other intellectual property or confidentiality, privacy and/or publicity interest in and to any User Content
        | &nbsp;submitted by you.
      p You also acknowledge and agree that GESTAL may obtain User Content on this Application, or otherwise develop on its
        | &nbsp;own, or through third party vendors it engages, ideas and materials that may be similar or identical in theme,
        | &nbsp;idea, format or other respects to ideas and other User Content you submit to the Application, and you waive any
        | &nbsp;and all claims you may have had, may have, and/or may have in the future, that any ideas, composition, video and/or
        | &nbsp;other works used by GESTAL based on any similarity with any User Content submitted by you on this Application.

    section
      h2 Data You Provide
      p If you decide, or if at any time you are asked, to register for or complete an application available through any
        | &nbsp;part of the Application, you agree to: (i) provide true, accurate and complete information about yourself as
        | &nbsp;prompted by the registration or application form and (ii) maintain and promptly update the data to keep it true,
        | &nbsp;accurate, current and complete. Any data you provide about yourself or your interests shall be referred to in
        | &nbsp;these Terms as “Data You Provide”.

    section
      h2 Indemnification
      p You agree to indemnify and hold GESTAL, its subsidiaries, directors, officers, employees and website contractors and
        | &nbsp;each of their directors, officers, employees and agents harmless from any claims, damages and expenses, including
        | &nbsp;reasonable attorneys’ fees and costs, related to your violation of these Terms or any violations thereof by your
        | &nbsp;dependents or which arises from the use of User Content you submitted, posted, or otherwise provided to GESTAL
        | &nbsp;or that you submit through the Application. Upon our request, you will furnish any documentation, substantiation
        | &nbsp;or releases necessary to verify your compliance with these Terms.

    section
      h2 Notices of Intellectual Property Infringement
      p If you are a trademark or copyright owner and you believe that your intellectual property rights have been violated,
        | &nbsp;please advise GESTAL by email at&nbsp;
        a(href="mailto:communication@jygatech.com") communication@jygatech.com
        | .

    section
      h2 Sweepstakes and Contests
      p From time to time, GESTAL may make sweepstakes and contests available to users of the Application and others. All
        | &nbsp;sweepstakes and contests are governed by this Agreement and the applicable Official Rules of the respective
        | &nbsp;sweepstakes or contest. By participating in a contest through the Application, you signify your agreement and
        | &nbsp;acceptance of this Agreement and the applicable Official Rules.

    section
      h2 Limitation and Liability and Warranty
      p YOU AGREE THAT USE OF THE APPLICATION IS ENTIRELY AT YOUR OWN RISK AND THAT THE APPLICATION IS PROVIDED ON AN
        | &nbsp;“AS IS” AND “AS AVAILABLE” BASIS. WE MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS
        | &nbsp;TO THE OPERATION OF THE APPLICATION OR THE INFORMATION, CONTENT, MATERIALS, LINKS TO OTHER WEBSITES OR ANY OTHER
        | &nbsp;MATERIAL INCLUDED IN THE APPLICATION OR THE ACCURACY OR CONTINUED AVAILABILITY OF ANY OF THE FOREGOING.
      p WHILE WE PROHIBIT CERTAIN CONDUCT AND CONTENT DESCRIBED HEREIN, YOU UNDERSTAND THAT WE CANNOT BE RESPONSIBLE FOR
        | &nbsp;THE CONTENT DISEMMINATED VIA THE APPLICATION AND YOU NONETHELESS MAY BE EXPOSED TO CERTAIN CONTENT. THEREFORE,
        | &nbsp;YOU USE THE APPLICATION AT YOUR OWN RISK. WE EXPRESSLY DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING,
        | &nbsp;BUT NOT LIMITED TO, 1) ANY IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE: 2) ANY
        | &nbsp;WARRANTIES CONCERNING THE AVAILABILITY, ACCURACY, OR CONTENT OF THE APPLICATION; AND 3) ANY WARRANTIES OF TITLE.
      p WE DO NOT WARRANT THAT THE APPLICATION IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE APPLICATION WILL
        | &nbsp;BE AVAILABLE OR ERROR FREE, AND WE WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THE
        | &nbsp;APPLICATION, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE AND CONSEQUENTIAL DAMAGES.
      p You acknowledge and agree that GESTAL will not have any obligation to review, monitor, display, accept or exploit
        | &nbsp;any User Content and GESTAL may, in its sole discretion, delete, move, re-format, edit, alter, distort, remove
        | &nbsp;or refuse to exploit User Content without notice or liability. Except as prohibited by law, you waive any moral
        | &nbsp;rights you may have in any User Content you submit, even if such User Content is altered or changed in a manner
        | &nbsp;not agreeable to you. GESTAL DOES NOT ENDORSE THE USER CONTENT, IS NOT RESPONSIBLE FOR THE USER CONTENT AND
        | &nbsp;SPECIFICALLY DISCLAIMS ANY RESPONSIBILITY OR LIABILITY TO ANY PERSON OR ENTITY (INCLUDING, WITHOUT LIMITATION,
        | &nbsp;PERSONS WHO MAY USE OR RELY ON SUCH USER CONTENT) FOR ANY LOSS, DAMAGE (WHETHER ACTUAL, CONSEQUENTIAL, PUNITIVE
        | &nbsp;OR OTHERWISE), INJURY, CLAIM, LIABILITY OR OTHER CAUSE OF ANY KIND OR CHARACTER BASED UPON OR RESULTING FROM ANY
        | &nbsp;USER CONTENT PROVIDED THROUGH THE APPLICATION.

    section
      h2 Links to Other Websites
      p The Application may contain links to other websites that are not under our control. Accordingly, we make no
        | &nbsp;representations whatsoever concerning the content of those websites. The fact that we have provided a link to a
        | &nbsp;third-party website is NOT an endorsement, authorization, sponsorship, or affiliation by GESTAL with respect to
        | &nbsp;such website, its owners, or its providers. We provide these links only as a convenience to you. We have not
        | &nbsp;tested any information, software, or products found on these websites and therefore cannot make any representations
        | &nbsp;whatsoever with respect thereto. There are risks in using any information, software, or products found on the
        | &nbsp;Internet; and we caution you to make sure that you completely understand these risks before retrieving, using,
        | &nbsp;relying upon, or purchasing anything via the Internet.

    section
      h2 Information Provided by You is Not Confidential or Proprietary
      p Any information uploaded by you to the Application or provided to GESTAL, including but not limited to photos,
        | &nbsp;names, links, data, questions, comments, suggestions, or the like, shall be deemed to be non-confidential; we
        | &nbsp;shall have no obligation of any kind with respect to such information and shall be free to reproduce, use,
        | &nbsp;disclose, and distribute the information to others without limitation. We shall be free to use any ideas, concepts,
        | &nbsp;know-how, or techniques contained in such information for any purpose whatsoever, including but not limited to
        | &nbsp;developing, manufacturing, and marketing products incorporating such information.
      p We reserve the right at all times to disclose any information as we deem necessary to satisfy any applicable law,
        | &nbsp;regulation, legal process or governmental request, or to edit, refuse to post or to remove any information or
        | &nbsp;materials, in whole or in part, in our sole discretion.

    section
      h2 Applicable Law
      p This Application is hosted in Canada, with servers in the Canada, and is intended for all countries. If you are
        | &nbsp;visiting the Site or using the Application from another jurisdiction, you understand that your use is at your
        | &nbsp;own risk, and that data you submit is subject to the terms of our Privacy Policy, and that the laws of this
        | &nbsp;jurisdiction may vary from those in your own, and may provide fewer protections than your jurisdiction allows.
        | &nbsp;All users understand that the laws applicable within the province of Quebec CANADA will govern these Terms,
        | &nbsp;without regard to Conflict of Laws Rules or Principles, and any dispute that might arise between you and GESTAL.
        | &nbsp;You agree to the personal and exclusive jurisdiction of the courts located in the judicial district of Quebec CANADA.

    section
      h2 Severability
      p These Terms constitute the entire agreement between you and GESTAL with respect to the Application and supersede
        | &nbsp;all prior or contemporaneous communications, whether oral, written or electronic, between you and GESTAL. Should
        | &nbsp;any of these Terms be held invalid or unenforceable, the remaining Terms shall remain in full force and effect.

    section
      h2 Termination
      p Notwithstanding any other provision of this Agreement, GESTAL may in its discretion, without any notice or liability
        | &nbsp;to you or any other person, change, discontinue, modify, restrict, suspend or terminate the Application, or any
        | &nbsp;part thereof, including, but not limited to: (i) restricting the time the Application is available, (ii) restricting
        | &nbsp;the amount of use permitted, and (iii) restricting any user’s right to use or access the Application. GESTAL may
        | &nbsp;in its discretion and for its convenience at any time immediately terminate, temporarily or permanently, this
        | &nbsp;Agreement or your permission to access and use the Application without any notice or liability to you or any other person.

    section
      h2 Miscellaneous
      p You acknowledge and agree that no partnership is formed between you and GESTAL and neither of you nor GESTAL has
        | &nbsp;the power or the authority to obligate or bind the other. The failure of GESTAL to comply with these Terms because
        | &nbsp;of an act of God, war, fire, riot, terrorism, earthquake, actions of federal, state or local governmental authorities
        | &nbsp;or for any other reason beyond the reasonable control of GESTAL, including force majeure, shall not be deemed a
        | &nbsp;breach of these Terms. If GESTAL fails to act with respect to your breach or anyone else’s breach on any occasion,
        | &nbsp;GESTAL is not waiving its right to act with respect to future or similar breaches. If any provision of these
        | &nbsp;Terms shall be unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from
        | &nbsp;these terms of use and shall not affect the validity and enforceability of any remaining provisions.

    section
      h2 Language
      p You agree that these Terms be drafted in the English language. Vous convenez que ces Termes soient rédigés dans la langue anglaise.
</template>

<script>
export default {
  name: "Terms",
  mounted() {
    const body = document.getElementsByTagName("body")[0];
    body.style.overflow = "auto";
    body.style.backgroundColor = "white";
  },
  destroyed() {
    const body = document.getElementsByTagName("body")[0];
    body.style.overflow = null;
    body.style.backgroundColor = null;
  }
};
</script>

<style lang="stylus" scoped>
div
  max-width 1024px
  padding 0 12px
  margin 0 auto
h1
  position sticky
  top 0
  background-color white
  margin 0 0 -16px
  padding 20px 0
p, li
  text-align justify
  a
    color var(--black)
    text-decoration underline

@media not print
  .dark
    h1
      background-color var(--dark-SB)
    a
      color var(--athens-gray)
</style>
