<template lang="pug">
  div
    h1 CONDITIONS GÉNÉRALES D’UTILISATION
    p Les présentes conditions générales d’utilisation (ci-après les « Conditions ») régissent votre utilisation de la
      | &nbsp;présente application appelée « Vision » (ci-après « Application »), propriété de LES LOGICIELS GESTAL INC.
      | &nbsp;(« GESTAL »), où les présentes conditions sont publiées, stockées et hébergées par Amazon Web Services. En
      | &nbsp;accédant, en utilisant ou en publiant tout contenu sur l’Application, vous acceptez d’utiliser l’Application
      | &nbsp;conformément à toutes les lois et tous les règlements applicables décrits dans le présent document, ainsi
      | &nbsp;qu’à toutes les conditions générales décrites dans le présent document. Les présentes conditions peuvent
      | &nbsp;être mises à jour de temps à autre. De plus, la dernière version des conditions générales s’affichera sur
      | &nbsp;l’Application. Votre utilisation de l’Application constitue votre acceptation des présentes conditions, et
      | &nbsp;de toutes modifications de celles-ci. SI VOUS N’ACCEPTEZ PAS CES CONDITIONS, N’UTILISEZ PAS L’APPLICATION.

    section
      h2 Analyse des données
      p La présente Application, y compris son contenu tel que le texte, les graphiques, les images et les renseignements
      | &nbsp;obtenus de l’Application et de ses concédants de licence est destinée à être utilisée ou consultée aux fins
      | &nbsp;d’analyse des données en ferme.

    section
      h2 Droit de propriété intellectuelle
      p Tout le contenu de l’Application que nous fournissons, y compris, mais sans s’y limiter, le texte, les graphiques,
      | &nbsp;les logos, les icônes, les images, les en-têtes, les illustrations, les extraits audio, les vidéos et le
      | &nbsp;logiciel est détenu ou concédé sous licence par GESTAL, ses sociétés affiliées ou des fournisseurs de contenu
      | &nbsp;et est protégé par la loi, notamment les lois canadiennes, américaines et internationales en matière de droits
      | &nbsp;d’auteur. De plus, toutes les appellations commerciales, marques de commerce (déposées ou non déposées) ou
      | &nbsp;autre propriété intellectuelle sont également la propriété de GESTAL, de ses sociétés affiliées ou des
      | &nbsp;fournisseurs de contenu, et toute utilisation ou copie de toutes marques de commerce ou de toute propriété
      | &nbsp;intellectuelle est interdite sans notre autorisation expresse. Sauf autorisation expresse par écrit, vous
      | &nbsp;acceptez de ne pas reproduire, modifier, louer, prêter, vendre, distribuer, refléter, encadrer, republier,
      | &nbsp;télécharger, transmettre ou créer des œuvres dérivées du contenu d’autrui, en tout ou en partie, par quelque
      | &nbsp;moyen de ce soit.

    section
      h2 Licence
      p GESTAL vous accorde une licence limitée, non exclusive et révocable afin d’utiliser l’Application conformément aux
      | &nbsp;présentes conditions. Le contenu de l’Application publié par GESTAL, y compris, mais sans s’y limiter, le
      | &nbsp;texte et les images figurant dans le présent document, ainsi que leur sélection et leur agencement, est détenu
      | &nbsp;par GESTAL, ses sociétés affiliées ou nos fournisseurs de contenu. Vous pouvez télécharger du matériel de
      | &nbsp;l’Application publié par GESTAL à des fins personnelles. Toutefois, vous ne pouvez pas transmettre, diffuser,
      | &nbsp;téléverser du contenu vers un ordinateur, un appareil mobile ou toute autre appareil. Vous ne pouvez pas non
      | &nbsp;plus créer des œuvres dérivées ou faire un usage commercial de l’Application ou de tout autre matériel fourni
      | &nbsp;ou obtenu par l’Application, y compris, mais sans s’y limiter, les noms, les images ou autres renseignements
      | &nbsp;utilisés dans un contenu d’utilisateur (tel que défini ci-dessous). Vous reconnaissez que nous ou nos
      | &nbsp;fournisseurs de contenu tiers demeurent propriétaires du matériel publié et que vous n’acquérez aucun droit
      | &nbsp;de propriété en téléchargeant du matériel protégé par le droit d’auteur.

    section
      h2 Dénégation de responsabilité en matière de correction des couleurs
      p Les photographies et les illustrations de produits ont été reproduites aussi fidèlement que le permettent les
      | &nbsp;technologies d’impressions et du Web. Toutefois, en raison des différences des paramètres du moniteur, des
      | &nbsp;calibrations, des paramètres d’impression des couleurs et des sources d’éclairage de chaque utilisateur,
      | &nbsp;nous ne pouvons pas garantir que vos produits correspondront parfaitement aux couleurs affichées dans la
      | &nbsp;présente Application ou au résultat d’une imprimante domestique. Pour assurer la plus grande satisfaction,
      | &nbsp;nous vous suggérons de visionner un échantillon réel de notre part.

    section
      h2 Vos déclarations et garanties
      p Vous déclarez et garantissez que vous possédez le droit légal et la capacité d’accepter les présentes conditions,
      | &nbsp;que vous être âgé(e) de plus de 13 ans, et que vous détenez les droits de tous les renseignements et le
      | &nbsp;matériel que vous soumettez sur le site, notamment les images, les photographies, les graphiques (« Contenu
      | &nbsp;d’utilisateur ») sont vrais, exacts et à jour. En soumettant tout contenu d’utilisateur sur le site ou par
      | &nbsp;le biais du site, vous déclarez et garantissez ce qui suit : (a) vous êtes titulaire du droit d’auteur d’un
      | &nbsp;tel contenu d’utilisateur, et (b) vous possédez les droits requis pour accorder les licences et les
      | &nbsp;sous-licences décrites dans les présentes conditions.
      p Vous acceptez également de ne pas :
      ol(type="i")
        li soumettre tout matériel protégé par des droits d’auteur, par le secret commercial ou autrement soumis aux droits
          | &nbsp;de propriété d’un tiers, y compris les droits de protection des renseignements personnels et le droit
          | &nbsp;à la protection de la personnalité, à moins que vous ne déteniez ces droits ou que vous ayez la permission
          | &nbsp;écrite de leur détenteur légitime de publier le matériel et d’accorder le droit d’utiliser un tel matériel
          | &nbsp;comme prévu dans les présentes conditions (y compris, sans s’y limiter, l’interdiction de soumettre une
          | &nbsp;photographie d’une autre personne ou incluant une autre personne sans son consentement);
        li publier dans l’Application tout faux renseignement ou toute fausse déclaration qui pourrait entraîner une
          | &nbsp;responsabilité ou des dommages pour GESTAL ou pour un tiers;
        li soumettre tout matériel de nature illicite, obscène, diffamatoire, calomnieuse, menaçante, pornographique,
          | &nbsp;graphique ou gratuitement violente, harcelante, haineuse et ethniquement offensante, incite à une conduite
          | &nbsp;qui pourrait donner lieu à une responsabilité pénale ou civile, à violer une loi ou être autrement inappropriée;
        li usurper l’identité d’une autre personne, notamment en présentant ses photographies comme étant les vôtres;
        li collecter les renseignements des utilisateurs, ou accéder d’une autre manière au site Web, en utilisant des
          | &nbsp;moyens automatisés (tels que des ordinateurs zombie, des robots, des robots d’indexation ou des logiciels de collimage); ou
        li transmettre des vers informatiques, des virus et autres programmes malveillants.
      p L’Application peut vous permettre d’accéder et d’interagir avec des sites Web, des logiciels d’application et des
        | &nbsp;services de données de tiers (« Applications tierces »). Votre accès à des Applications tierces, ainsi que
        | &nbsp;votre utilisation de celles-ci sont régis par les conditions générales relatives à ces offres qui vous
        | &nbsp;sont fournies au moment où vous accédez à ces applications tierces, ainsi que par les règles, exigences
        | &nbsp;ou conditions applicables des tiers et vous acceptez par la présente d’adhérer à ces conditions. Vous
        | &nbsp;convenez qu’en soumettant du contenu d’utilisateur, vous accordez à Gestal et à ses représentants un droit
        | &nbsp;et une licence exclusifs (à moins d’indication contraire dans la présente Application), mondiaux, perpétuels,
        | &nbsp;irrévocables, libres de droits, pouvant donner lieu à une sous-licence et une licence d’exploitation du
        | &nbsp;contenu d’utilisateur et les biens d’une personne (physiques, personnels et indices) contenues dans le
        | &nbsp;présent document, dans tout média maintenant ou à l’avenir, sans aucun paiement ou contrepartie de quelque
        | &nbsp;nature que ce soit, ou autorisation ou préavis à vous ou à un tiers. Les droits de GESTAL doivent comprendre,
        | &nbsp;sans s’y limiter, le droit de reproduire, d’enregistrer, de modifier, de publier, d’exécuter publiquement,
        | &nbsp;d’utiliser, de commercialiser, de concéder une licence, d’accorder une sous-licence (par le biais de
        | &nbsp;plusieurs tiers), d’adapter et de diffuser en continu le contenu d’utilisateur dans tous les médias connus
        | &nbsp;maintenant et à l’avenir, y compris sans s’y limiter, Internet, les panneaux d’affichage extérieurs, à
        | &nbsp;l’échelle mondiale, liés à GESTAL, à ses mandataires et à ses représentants. Vous reconnaissez et convenez
        | &nbsp;que GESTAL n’a pas, et n’aura pas à l’avenir, d’obligation ou de responsabilité, directe ou indirecte,
        | &nbsp;dérivée, contributive, ou autre, en ce qui concerne la violation ou la protection de tout droit d’auteur
        | &nbsp;ou de toute autre propriété intellectuelle ou de tout intérêt en matière de confidentialité, de vie privée
        | &nbsp;et/ou d’intérêt public et pour tout contenu d’utilisateur que vous soumettez.
      p Vous reconnaissez et convenez également que GESTAL peut obtenir le contenu d’utilisateur sur cette application,
        | &nbsp;ou développer autrement, par elle-même ou par l’intermédiaire de fournisseurs tiers qu’elle embauche, des
        | &nbsp;idées et du matériel qui peuvent se révéler similaires ou identiques dans le thème, l’idée, le format ou
        | &nbsp;d’autres aspects des idées et d’autre contenu d’utilisateur que vous avez soumis sur l’Application, et
        | &nbsp;vous renoncez à toute réclamation que vous avez pu avoir, que vous pouvez avoir et que vous pourrez avoir
        | &nbsp;à l’avenir, concernant les idées, compositions, vidéos et/ou autres œuvres utilisées par GESTAL sur la
        | &nbsp;base d’une quelconque similitude avec le contenu d’utilisateur que vous avez soumis sur cette Application.

    section
      h2 Les renseignements que vous fournissez
      p Si vous décidez, ou lorsque le système le demande, de vous inscrire ou de remplir une demande accessible par une
        | &nbsp;partie de l’Application, vous acceptez d’effectuer ce qui suit : (i) fournir des renseignements personnels
        | &nbsp;exacts et complets tels que demandés dans le formulaire d’inscription ou de demande et (ii) tenir et mettre
        | &nbsp;à jour rapidement les renseignements afin qu’ils demeurent exacts, à jour et complets. Tous les renseignements
        | &nbsp;que vous fournissez vous concernant ou vos intérêts doivent être mentionnés dans les présentes conditions
        | &nbsp;comme des « Renseignements que vous fournissez ».

    section
      h2 Indemnisation
      p Vous acceptez d’indemniser et de tenir GESTAL, ainsi que ses filiales, ses directeurs, ses dirigeants, ses employées
        | &nbsp;et ses développeurs de sites Web et tous leurs directeurs, dirigeants, employés à l’abri de toute réclamation,
        | &nbsp;de tout dommage et frais, y compris des frais juridiques raisonnables en lien avec votre violation des
        | &nbsp;présentes conditions ou avec les violations de celles-ci par vos dépendants ou qui découlent de l’utilisation
        | &nbsp;du contenu d’utilisateur que vous avez soumis, publié ou autrement fourni à GESTAL ou que vous soumettez
        | &nbsp;par le biais de l’Application. À notre demande, vous fournirez toute documentation, justification ou
        | &nbsp;décharge nécessaire visant à vérifier votre respect des présentes conditions.

    section
      h2 Avis de violation de la propriété intellectuelle
      p Si vous détenez une marque de commerce ou un droit d’auteur et que vous croyez que vos droits de propriété
        | &nbsp;intellectuelle ont été violés, veuillez en informer GESTAL par courriel à l’adresse suivante&nbsp;
        a(href="mailto:communication@jygatech.com") communication@jygatech.com
        | .

    section
      h2 Loterie et concours
      p De temps à autre, GESTAL peut proposer des loteries et des concours aux utilisateurs de l’Application et à d’autres
        | &nbsp;personnes. Toutes les loteries et tous les concours sont régis par le présente entente et les Règlements
        | &nbsp;officiels applicables à la loterie ou au concours en question. En participant à un concours par le biais
        | &nbsp;de l’Application, vous signifiez votre accord et votre acceptation de la présente entente et les
        | &nbsp;Règlements officiels applicables.

    section
      h2 Limites de garantie et des responsabilités
      p VOUS ACCEPTEZ QUE L’UTILISATION DE L’APPLICATION SOIT ENTIÈREMENT À VOS RISQUES ET PÉRILS ET QUE L’APPLICATION
        | &nbsp;EST FOURNIE « TELLE QUELLE » ET « TELLE QUE DISPONIBLE ». NOUS NE FAISONS AUCUNE DÉCLARATION NI NE DONNONS
        | &nbsp;AUCUNE GARANTIE DE QUELQUE NATURE QUE CE SOIT, EXPRESSE OU IMPLICITE, QUANT AU FONCTIONNEMENT DE L’APPLICATION
        | &nbsp;OU AUX RENSEIGNEMENTS, AU CONTENU, AU MATÉRIEL, AUX LIENS VERS D’AUTRES SITES WEB OU À TOUT AUTRE MATÉRIEL
        | &nbsp;INCLUS DANS L’APPLICATION OU À L’EXACTITUDE OU À LA DISPONIBILITÉ CONTINUE DE TOUT CE QUI PRÉCÈDE.
      p MÊME SI NOUS INTERDISONS CERTAINS COMPORTEMENTS ET CONTENUS DÉCRITS DANS LES PRÉSENTES, VOUS COMPRENEZ QUE NOUS
        | &nbsp;NE POUVONS PAS ÊTRE TENU RESPONSABLE DU CONTENU DIFFUSÉ PAR L’APPLICATION ET QUE VOUS POUVEZ NÉAMONS ÊTRE
        | &nbsp;EXPOSÉ(E) À CERTAINS CONTENUS. PAR CONSÉQUENT, VOUS UTILISEZ L’APPLICATION À VOS RISQUES ET PÉRILS. NOUS
        | &nbsp;REJETONS TOUTE RESPONSABILITÉ RELATIVE À TOUTE GARANTIE, EXPRESSE OU IMPLICITE, Y COMPRIS, SANS S’Y LIMITER,
        | &nbsp;1) TOUTE GARANTIE IMPLICITE DE QUALITÉ MARCHANGE ET D’ADÉQUATION À UN USAGE PARTICULIER : 2) TOUTE GARANTIE
        | &nbsp;CONCERNANT LA DISPONIBILITÉ, L’EXACTITIUDE OU LE CONTENU DE L’APPLICATION; ET 3) TOUTE GARANTIE DE PROPRIÉTÉ.
      p NOUS NE GARANTISSONS PAS QUE L’APPLICATION EST EXEMPTE DE VIRUS OU D’AUTRES COMPOSANTS NUISIBLES, OU QUE L’APPLICATIONS
        | &nbsp;SERA DISPONIBLE ET SANS ERREUR, ET NOUS NE POURRONS PAS ÊTRE TENU RESPONSABLE DE TOUT DOMMAGE DE QUELQUE
        | &nbsp;NATURE QUE CE SOIT RÉSULTANT DE L’UTILISATION DE L’APPLICATION, Y COMPRIS, SANS S’Y LIMITER, LES DOMMAGES
        | &nbsp;DIRECTS, INDIRECTS, ACCESSOIRES, PUNITIFS ET CONSÉCUTIFS
      p Vous reconnaissez et acceptez que GESTAL n’aura pas l’obligation d’examiner, de surveiller, d’afficher d’accepter
        | &nbsp;ou d’exploiter tout contenu d’utilisateur. De plus, GESTAL peut, à sa discrétion, supprimer, déplacer,
        | &nbsp;reformater, modifier, déformer, retirer ou refuser d’exploiter le contenu d’utilisateur sans préavis ni
        | &nbsp;responsabilité. Sauf si la loi l’interdit, vous renoncez à tout droit moral que vous pourriez avoir sur
        | &nbsp;tout contenu d’utilisateur que vous soumettez, et ce, même si ce contenu d’utilisateur est modifié ou
        | &nbsp;changé de manière que ne vous convient pas. GESTAL N’ENDOSSE PAS LE CONTENU D’UTILISATEUR, N'EST PAS
        | &nbsp;RESPONSABLE DU CONTENU D’UTILISATEUR ET REJETTE EXPRESSÉMENT TOUTE RESPONSABILITÉ À L’ÉGARD DE TOUTE
        | &nbsp;PERSONNE OU ENTITÉ (Y COMPRIS, MAIS SANS S’Y LIMITER, LES PERSONNES QUI PEUVENT UTILISER OU SE RÉFÉRER À
        | &nbsp;CE CONTENU D’UTILISATEUR) EN CAS DE PERTE, DE DOMMAGE (QU’IL SOIT RÉEL, ACCESSOIRE, PUNITIF OU AUTRE),
        | &nbsp;DE BLESSURE, DE RÉCLAMATION, DE RESPONSABILITÉ, OU DE TOUTE AUTRE CAUSE DE TOUTE NATURE OU CARACTÈRE SUR
        | &nbsp;LA BASE OU RÉSULTANT DU CONTENU D’UTILISATEUR FOURNI PAR L’APPLICATION.

    section
      h2 Liens vers d’autres sites Web
      p L’Application peut contenir des liens vers d’autres sites Web qui ne sont pas sous notre contrôle. Par conséquent,
        | &nbsp;nous ne faisons aucune déclaration concernant le contenu de ces sites Web. Le fait que nous ayons fourni
        | &nbsp;un lien vers le site Web d’un tiers ne constitue PAS un endossement, une autorisation, un parrainage ou
        | &nbsp;une affiliation de GESTAL à l’égard d’un tel site Web, de ses propriétaires ou de ses fournisseurs. Nous
        | &nbsp;fournissons ces liens seulement par courtoisie pour vous. Nous n’avons effectué aucun test relatif aux
        | &nbsp;renseignements, aux logiciels ou aux produits trouvés sur ces sites Web, et nous ne pouvons donc pas faire
        | &nbsp;de déclaration à leur sujet. L’utilisation de renseignements, de logiciels ou de produits trouvés sur
        | &nbsp;Internet comporte des risques; et nous vous recommandons de vous assurer que vous comprenez parfaitement
        | &nbsp;ces risques avant de récupérer, d’utiliser, de faire confiance ou d’acheter quoi que ce soit par Internet.

    section
      h2 Les renseignements que vous fournissez ne sont ni confidentiels ni exclusifs
      p Les renseignements que vous téléchargez dans l’Application ou transmettez à GESTAL, y compris, mais sans s’y limiter,
        | &nbsp;les photographies, les noms, les liens, les données, les questions, les commentaires, les suggestions ou
        | &nbsp;autres seront considérés comme non confidentiels; nous n’aurons aucune obligation de quelque nature que
        | &nbsp;ce soit en ce qui concerne ces renseignements et nous serons libres de les reproduire, de les utiliser de
        | &nbsp;les divulguer et de les distribuer à d’autres, sans restriction. Nous sommes libres d’utiliser les idées,
        | &nbsp;les concepts, le savoir-faire et les techniques contenus dans ces renseignements à quelque fin que ce soit,
        | &nbsp;y compris, mais sans s’y limiter, le développement, la fabrication et la commercialisation de produits
        | &nbsp;intégrant ces renseignements.
      p Nous nous réservons le droit de divulguer, en tout temps, tout renseignement que nous jugeons nécessaire pour
        | &nbsp;respecter une loi, un règlement, un processus judiciaire ou une demande du gouvernement, ou pour modifier,
        | &nbsp;refuser d’afficher ou de retirer des renseignements ou du matériel, en tout ou en partie, à notre entière discrétion.

    section
      h2 Droit applicable
      p La présente Application est hébergée au Canada, sur des serveurs situés au Canada, et est accessible à tous les
        | &nbsp;pays. Si vous visitez le site ou si vous utilisez l’Application à partir d’un autre ressort, vous comprenez
        | &nbsp;que vous l’utilisez à vous risques et périls, et que les données que vous transmettez sont soumises aux
        | &nbsp;conditions de notre Politique de confidentialité, et que les lois de ce ressort peuvent varier de celles
        | &nbsp;de votre propre ressort, et peuvent prévoir moins de protections que ce que votre ressort autorise. Tous
        | &nbsp;les utilisateurs comprennent que le droit applicable dans la province de Québec, au CANADA, régira les
        | &nbsp;présentes conditions, sans égard aux règles ou principes de conflit des lois, et à tout différend qui peut
        | &nbsp;survenir entre vous et GESTAL. Vous acceptez la compétence personnelle et exclusive des tribunaux situés
        | &nbsp;dans le district judiciaire de Québec, au CANADA.

    section
      h2 Dissociabilité
      p Les présentes conditions constituent l’intégralité de l’entente entre vous et GESTAL en ce qui a trait à l’Application
        | &nbsp;et remplacent toutes les communications antérieures ou contemporaines qu’elles soient orales, écrites ou
        | &nbsp;électroniques, entre vous et GESTAL. Si l’une des présentes conditions est jugée invalide ou inapplicable,
        | &nbsp;les autres conditions demeureront pleinement en vigueur.

    section
      h2 Résiliation
      p Nonobstant toute autre disposition de la présente entente, GESTAL peut, à sa discrétion, sans préavis ou responsabilité
        | &nbsp;envers vous ou toute autre personne, remplacer, abandonner, modifier, restreindre, suspendre ou résilier
        | &nbsp;l’Application, ou toute partie de celle-ci, y compris, mais sans s’y limiter : (i) limiter la durée de
        | &nbsp;disponibilité de l’Application, (ii) limiter l’utilisation permise, et (iii) limiter le droit de tout
        | &nbsp;utilisateur à recourir ou à accéder à l’Application. GESTAL, peut, à sa discrétion et sa convenance, et
        | &nbsp;en tout temps, immédiatement résilier la présente entente ou votre autorisation à accéder et à utiliser
        | &nbsp;l’Application sans préavis ou responsabilité envers vous ou toute autre personne.

    section
      h2 Dispositions diverses
      p Vous reconnaissez et vous acceptez qu’aucun partenariat n’est formé entre vous et GESTAL et que ni vous ni GESTAL
        | &nbsp;n’a le pouvoir ni l’autorité de lier l’autre. L’incapacité de GESTAL de se conformer aux présentes conditions
        | &nbsp;en raison d’un cas de force majeure, d’une guerre, d’un incendie, d’une émeute, d’un acte de terrorisme,
        | &nbsp;d’un tremblement de terre, d’actions des autorités gouvernementales fédérales et locales ou pour toute
        | &nbsp;autre raison échappant au contrôle raisonnable de GESTAL, ne sera pas considérée comme une violation des
        | &nbsp;présentes conditions. Si GESTAL n’agit pas à l’égard de votre violation ou de celle d’une autre personne
        | &nbsp;à une occasion quelconque, GESTAL ne renonce pas à son droit d’agir à l’égard de violations futures ou
        | &nbsp;similaires. Si une disposition des présentes conditions est illicite, invalide ou inexécutable pour quelque
        | &nbsp;raison que ce soit, cette disposition sera considérée dissociable des présentes conditions d’utilisation
        | &nbsp;et ne compromettra pas la validité et la force exécutoire des autres dispositions.

    section
      h2 Langue
      p Vous convenez que ces Termes soient rédigés dans la langue anglaise.
</template>

<script>
export default {
  name: "Terms",
  mounted() {
    const body = document.getElementsByTagName("body")[0];
    body.style.overflow = "auto";
    body.style.backgroundColor = "white";
  },
  destroyed() {
    const body = document.getElementsByTagName("body")[0];
    body.style.overflow = null;
    body.style.backgroundColor = null;
  }
};
</script>

<style lang="stylus" scoped>
div
  max-width 1024px
  padding 0 12px
  margin 0 auto
h1
  position sticky
  top 0
  background-color white
  margin 0 0 -16px
  padding 20px 0
p, li
  text-align justify
  a
    color var(--black)
    text-decoration underline

@media not print
  .dark
    h1
      background-color var(--dark-SB)
    a
      color var(--athens-gray)
</style>
