<template lang="pug">
span.cloud-icon(:class="classes")
  .icon.svgicon(ref="svg" v-html="inner" @click="$emit('click', $event)" :style="{width: width ? width + 'px' : null, height: height ? height + 'px' : null}")
</template>

<script>
import { isEmpty } from "lodash-es";
import Axios from "axios";

const sizes = ["small", "medium"];
let cache = new Map();

export default {
  name: "cloud-icon",
  data() {
    return {
      inner: null,
      parser: new DOMParser()
    };
  },
  watch: {
    async name() {
      await this.loadSvg();
    },
    inner() {
      this.setFillColor();
    }
  },
  props: {
    name: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: "medium",
      validate(value) {
        return sizes.contains(value);
      }
    },
    modifiers: {
      type: String,
      default: null
    },
    width: Number,
    height: Number,
    fillColor: String,
    fillLevel: Number,
    isFlat: Boolean,
    maskId: String
  },
  computed: {
    classes() {
      const modifiers = [this.size];
      if (!isEmpty(this.modifiers)) {
        modifiers.push(...this.modifiers.split("|"));
      }
      return modifiers.map(modifier => `${this.$options.name}_${modifier}`);
    }
  },
  methods: {
    async loadSvg() {
      const src = "svg/" + this.name + ".svg";
      if (cache.has(src)) {
        this.inner = cache.get(src);
        if (this.fillColor) {
          this.inner = this.inner
            .replaceAll("#mask", "#mask" + this.maskId)
            .replaceAll('id="mask', 'id="mask' + this.maskId);
        }
        return;
      }
      this.inner = (await Axios.get(src)).data;
      cache.set(src, this.inner);
      if (this.fillColor) {
        this.inner = this.inner
          .replaceAll("#mask", "#mask" + this.maskId)
          .replaceAll('id="mask', 'id="mask' + this.maskId);
      }
    },
    setFillColor() {
      if (this.fillColor && this.inner) {
        const svg = this.parser.parseFromString(this.inner, "text/html").body;
        const levels = svg.getElementsByClassName("level");
        const mask = svg.getElementsByTagName("mask")[0];
        for (const level of levels) {
          level.style.fill = this.fillColor;
        }
        const rect = mask.getElementsByTagName("rect")[0];
        const lidHeight = this.isFlat ? 16 : 12;
        // 15 is for the hopper height
        const height =
          rect.getAttribute("height") - lidHeight - (this.isFlat ? 0 : 15);
        const adjustedLevel = (height * this.fillLevel) / 100 + lidHeight;
        rect.setAttribute("y", adjustedLevel.toString());
        this.inner = svg.innerHTML;
      }
    }
  },
  mounted() {
    this.loadSvg();
  }
};
</script>

<style lang="stylus" scoped>
.svgicon
  display flex
  align-items center
  justify-content center
  max-height 100%
  max-width 100%
</style>

<style lang="stylus">
.svgicon svg
  width 100%
  height 100%
</style>
