import Vue from "vue";
import { get, has } from "lodash-es";

import Router from "vue-router";
import DashboardRoutes from "@/dashboard-routes";
import IntegratorRoutes from "@/integrator-routes";
import App from "@/App.vue";
import Cloud from "@/views/Cloud.vue";
import Integrator from "@/views/Integrator";
import Login from "@/views/Login.vue";
const NotFound = () => import("@/views/NotFound.vue");
const Terms = () => import("@/views/political/Terms.vue");
const Termes = () => import("@/views/political/Termes.vue");
const Policy = () => import("@/views/political/Policy.vue");
const Politique = () => import("@/views/political/Politique.vue");
import { i18n } from "@/i18n.js";
import store from "@/store";
import { Settings } from "luxon";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/",
      component: App,
      children: [
        {
          path: "login",
          name: "login",
          meta: {
            title: `${i18n.t("login.title")} | Vision`,
            public: true,
            description: `Login to ${process.env.VUE_APP_API_URL}`
          },
          component: Login,
          beforeEnter(to, from, next) {
            setupTimer(next);
            // if there is no token settled or no sites
            if (
              !store.state.general.token ||
              !store.state.sites.sites.length ||
              !store.state.sites.comparison.length
            ) {
              if (!to.params.lang) {
                Settings.defaultLocale = navigator.language;
                i18n.locale = navigator.language.split("-")[0];
              }
              return next();
            }
            return next({
              name: "root",
              params: {
                message: i18n.t("login.messages.logged")
              }
            });
          }
        },
        {
          path: "",
          name: "root",
          redirect: {
            name: "dashboard"
          },
          //redirect: {
          //name: "sites-comparison"
          //},
          component: Cloud,
          children: DashboardRoutes,
          beforeEnter(to, from, next) {
            next();
          }
        },
        {
          name: "integrator",
          path: "",
          component: Integrator,
          children: IntegratorRoutes,
          redirect: {
            name: "integrations"
          },
          beforeEnter(to, from, next) {
            // if there is no token settled
            if (store.state.user.role === "integrator") {
              next();
            } else {
              next({
                name: "dashboard"
              });
            }
          }
        },
        {
          path: "logout",
          name: "logout",
          meta: {
            public: true
          },
          beforeEnter(to, from, next) {
            localStorage.clear();
            store.commit("setAllDefault");
            for (let i = 0; i < store.state.general.allIntervals.length; i++)
              window.clearInterval(store.state.general.allIntervals[i]);
            return next({
              name: "login",
              params: {
                message: i18n.t("login.messages.disconnected"),
                messageClass: "valide",
                lang: to.params.lang
              }
            });
          }
        },
        {
          path: "terms",
          name: "terms",
          meta: {
            title: `${i18n.t("terms")} | Vision`,
            public: true,
            description: "Terms of use"
          },
          component: Terms
        },
        {
          path: "termes",
          name: "termes",
          meta: {
            title: `${i18n.t("terms")} | Vision`,
            public: true,
            description: "Conditions d'utilisation"
          },
          component: Termes
        },
        {
          path: "policy",
          name: "policy",
          meta: {
            title: `${i18n.t("policy")} | Vision`,
            public: true,
            description: "Privacy policy"
          },
          component: Policy
        },
        {
          path: "politique",
          name: "politique",
          meta: {
            title: `${i18n.t("policy")} | Vision`,
            public: true,
            description: "Politique de confidentialité"
          },
          component: Politique
        }
      ]
    },
    {
      path: "/",
      name: "default",
      meta: {
        public: true
      },
      beforeEnter(to, from, next) {
        return next("/:lang");
      }
    },
    {
      path: "*",
      name: "notFound",
      component: NotFound
    }
  ]
});

router.onReady(() => {
  let path = window.location.pathname;
  if (path != "/") {
    window.history.replaceState(null, null, "/");
  }
});

router.beforeEach(async (to, from, next) => {
  // Change the page title
  if (has(to, "meta.title")) {
    document.title = to.meta.title;
    store.setTitle(to.meta.title);
  }
  // Change the page description
  if (has(to, "meta.description")) {
    document.head.querySelector("[name~=description][content]").content =
      to.meta.description;
  }

  if (localStorage.getItem("scroll")) {
    localStorage.removeItem("scroll");
  }
  if (get(to, "meta.public", false)) {
    return next();
  }

  if (store.state.general.token) {
    setupTimer(next);
    if (
      to.path.startsWith("/en") ||
      to.path.startsWith("/fr") ||
      to.path.startsWith("/es")
    ) {
      return next(to.path.substring(3, to.path.length));
    }
    return next();
  }

  return next({
    name: "login",
    params: {
      message: i18n.t("login.messages.notConnected"),
      messageClass: "error"
    }
  });
});

export default router;

function setupTimer(next) {
  // localStorage timeOut
  // clear the localStorage
  const now = new Date().getTime();
  const setupTime = localStorage.getItem("setupTime");
  const acceptedPolicyTerms =
    store.state.general.acceptedPolicy && store.state.general.acceptedTerms;
  if (!store.state.general.token) {
    localStorage.setItem("setupTime", now);
  } else {
    if (now - setupTime > 8 * 60 * 60 * 1000 || !acceptedPolicyTerms) {
      // 8 heures
      localStorage.clear();
      store.commit("setAllDefault");
      localStorage.setItem("setupTime", now);
      return next({
        name: "login",
        params: {
          message: i18n.t(
            `login.messages.${!acceptedPolicyTerms ? "policy" : "expired"}`
          ),
          messageClass: "error"
        }
      });
    }
  }
}
