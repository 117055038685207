<template lang="pug">
.cloud(
  :class="$user.getTheme()"
  v-if="$user.loaded || $router.currentRoute.name === 'login'"
)
  //- Top section containing the header
  Maintenance
  .cloud-top
    header.topnav
      cloud-horizontal-nav(:class="hideSidebar ? 'horizontal-nav-larger' : ''")
  //- Bottom section containing the menu and the main screen
  .cloud-bottom(@click.stop="$size.currentSize <= $size.TABLET && $store.state.menuOpened ? closeMenu() : ''")
    cloud-menu
    .dashboard(:class="{opacityFilm: $size.currentSize <= $size.TABLET && $store.state.menuOpened}")
      div.printOnly.printHeader
        .left
          div {{ DateTime.local().toLocaleString(DateTime.DATETIME_MED) }}
          div(v-if="$sites.currentSite") {{ $sites.nameToDisplay($sites.currentSite) }}
        .right(v-if="$sites.currentSite")
          div © Copyright {{ new Date().getFullYear() }} | Jyga Technologies inc.
          div {{ $t('allRights')}}
      router-view
</template>

<script>
import CloudHorizontalNav from "@/components/navigation/horizontal-nav";
import CloudMenu from "@/components/navigation/menu";
import CloudFooter from "@/components/footer";
import CloudIcon from "@/components/icon";
import Maintenance from "@/components/maintenance.vue";
import { apiGet } from "@/functions/api-requests";
import { DateTime, Settings } from "luxon";

export default {
  name: "cloud",
  components: {
    CloudHorizontalNav,
    CloudMenu,
    CloudIcon,
    Maintenance,
    CloudFooter
  },
  data() {
    return {
      DateTime,
      Settings,
      hideSidebar: false
    };
  },
  beforeMount() {
    Settings.defaultLocale = navigator.language;
  },
  methods: {
    closeMenu() {
      this.$store.state.menuOpened = false;
    },
    ping() {
      apiGet(this.$store.state.general.token, `ping?`, 1).catch(() => {
        this.$router.push("/login");
        return "You are unauthorized.";
      });
    }
  },
  computed: {
    currentSiteId() {
      return this.$sites.currentSite.id;
    },
    currentSiteName() {
      return this.$sites.nameToDisplay(this.$sites.currentSite);
    }
  },
  beforeUpdate() {
    this.ping();
  }
};
</script>

<style lang="stylus" scoped>
.dashboard
  transition opacity .3s ease-in-out

.opacityFilm
  pointer-events none
  opacity .1

.printHeader
  display flex
  line-height 1.2
  .left
    flex-grow 1
  .left, .right
    display flex
    flex-direction column
  .right
    text-align right
</style>

<style lang="stylus">
@import "../assets/css/styles.styl";
.cloud
  display flex
  flex-direction column
  overflow hidden
  height 100%

  .cloud-top
    flex-shrink 0
    height 60px
    z-index 200

  .cloud-bottom
    flex-grow 1
    overflow hidden
    display flex
</style>
