//@ts-check
import defaultPrefs from "../assets/json/default-prefs-sites.json";
import RequestHelper from "../functions/RequestHelper";
import { defaultValidator } from "../functions/preferences";
import { backwardCompatibility } from "../functions/objectives";
import store from "@/store";
import Store from "@/store";

/**
 * @typedef SiteDefinition
 * @property {string} cloud_id
 * @property {string} created_at
 * @property {string} updated_at
 * @property {string} name
 * @property {string} sharing_name
 * @property {string} address
 * @property {string} apartment
 * @property {string} city
 * @property {string} region
 * @property {string} country
 * @property {string} zip
 * @property {string} timezone_name
 * @property {string} prefs
 * @property {{cloud_id: string, name: string, ltrack_access: boolean}} tenant
 * @property {string} access_type
 * @property {string} access_code
 * @property {string} expires_at
 * @property {Array} buildings
 * @property {Array} contents
 */

export default class Site {
  /**
   * @param {SiteDefinition} description
   */
  constructor(description) {
    this.id = description.cloud_id;
    this.cloud_id = description.cloud_id;
    this.name = description.name;
    this.sharing_name = description.sharing_name;
    this.address = description.address || null;
    this.apartment = description.apartment || null;
    this.city = description.city || null;
    this.region = description.region || null;
    this.country = description.country || null;
    this.zip = description.zip || null;
    this.timezone = description.timezone_name;
    this.createdAt = description.created_at;
    this.updatedAt = description.updated_at;
    this.tenant = description.tenant;
    this.access = this.formatAccess(description.access_type);
    this.access_code = description.access_code;
    this.expiresAt = description.expires_at;
    this.buildings = description.buildings ?? [];
    this.buildingsCount = this.buildings.length;
    this.contents = description.contents ?? [];
    this.contentsCount = this.contents.length;
  }

  /**
   * @param {String} prefs
   */
  async initialize(prefs) {
    if (prefs && !JSON.parse(prefs).hasOwnProperty("objectives"))
      await this.refactorObjectives(JSON.parse(prefs));
    else this.preferences = JSON.parse(prefs) || {};
  }

  /**
   * @return {boolean} If access_key is not expired and report can be viewed
   */
  hasAccess() {
    return (
      store.state.user.role === "staff" ||
      (this.access &&
        Object.keys(this.access).length > 0 &&
        (!this.expiresAt || Date.parse(this.expiresAt) >= Date.now())) ||
      this.hasSilo()
    );
  }

  /**
   * @param {Object} building
   */
  addBuilding(building) {
    this.buildingsCount++;
    this.buildings.push(building);
    this.buildings = this.buildings.sort();
  }

  /**
   * @param {String} id
   * @param {String} new_site_id
   * @param {String} name
   * @param {String} type
   */
  updateBuilding(id, new_site_id, name, type) {
    const current = this.buildings.find(b => b.cloud_id == id);
    if (current) {
      current.name = name;
      current.type = type;
      if (new_site_id !== this.id) {
        // Add the building to the new site
        const newSite = Store.state.sites.sites.find(
          el => el.id === new_site_id
        );
        newSite.buildings.push(current);
        newSite.buildingsCount++;
        newSite.buildings = newSite.buildings.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
        // Remove building from current site
        this.buildings.splice(this.buildings.indexOf(current), 1);
        this.buildingsCount--;
      } else {
        this.buildings = this.buildings.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
      }
    }
  }

  /**
   * @param {Object} content
   */
  addContent(content) {
    this.contentsCount++;
    content.id = this.id;
    content.tenant = this.tenant;
    this.contents.push(content);
    this.contents = this.contents.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
  }

  /**
   * @param {String} id
   * @param {String} name
   * @param {String} recipe
   * @param {float} density
   * @param {String} utility
   */
  updateContent(id, name, recipe, density, utility) {
    const current = this.contents.find(b => b.cloud_id == id);
    if (current) {
      current.name = name;
      current.recipe = recipe;
      current.density = density;
      current.utility = utility;
      this.contents = this.contents.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
    }
  }

  /**
   * @param {String} key
   * @param {any} [defaultValue]
   */
  getPreference(key, defaultValue) {
    if (!key) return null;
    let root = this.preferences;
    if (!root) return null;
    let def_prefs = defaultPrefs;
    let keys = key.split(".");
    for (let k of keys) {
      if (root && root[k]) {
        root = root[k];
        if (def_prefs) def_prefs = def_prefs[k];
      } else {
        if (defaultValue) return defaultValue;
        else if (def_prefs) root = def_prefs[k];
        else return null;
      }
    }

    if (!keys.includes("theme") && typeof root === "object")
      root = defaultValidator(keys, root, true);

    return root;
  }

  /**
   * @param {String} key
   * @param {any} value
   */
  setPreference(key, value) {
    let root = this.preferences;
    let keys = key.split(".");
    let lastKey = keys.splice(keys.length - 1, 1)[0];
    for (let k of keys) {
      if (!root[k]) {
        root[k] = {};
      }
      root = root[k];
    }
    if (root[lastKey] === value) {
      return false;
    }
    root[lastKey] = value;
    return true;
  }

  /**
   * @param {String} key
   * @param {any} value
   */
  async savePreferences(key, value) {
    this.setPreference(key, value);
    let saved = await RequestHelper.patch("sites/" + this.id, {
      prefs: JSON.stringify(this.preferences)
    });
    return saved;
  }

  async refactorObjectives(prefs) {
    this.preferences = await backwardCompatibility.call(this, prefs, true);
  }

  /**
   * @param {String} access
   * @return {Object} access param to JSON
   */
  formatAccess(access) {
    if (access == "all") {
      return {
        feeding: ["all"],
        zootech: ["all"]
      };
    }

    const data = JSON.parse(access);
    return data
      ? Object.fromEntries(
          Object.entries(data).filter(([key, value]) => value.length > 0)
        )
      : data;
  }

  /**
   * @return {boolean} If has access to at least one feeding report
   */
  hasFeeding() {
    return this.access && this.access.feeding;
  }

  /**
   * @return {boolean} If has access to all gestation feeding reports
   */
  hasGestationFeeding() {
    return (
      this.hasFeeding() &&
      (this.access.feeding.includes("all") ||
        this.access.feeding.includes("gestation"))
    );
  }

  /**
   * @return {boolean} If has access to all lactation feeding reports
   */
  hasLactationFeeding() {
    return (
      this.hasFeeding() &&
      (this.access.feeding.includes("all") ||
        this.access.feeding.includes("lactation"))
    );
  }

  /**
   * @return {boolean} If has access to all herd management reports
   */
  hasZootech() {
    return this.access && this.access.zootech;
  }

  /**
   * @return {boolean} If has access to l-track reports
   */
  hasSilo() {
    const authorizedTenants = [
      "aldo",
      "tests concordance de données",
      "jyga technologies inc."
    ];
    return (
      this.tenant &&
      (this.tenant.ltrack_access ||
        authorizedTenants.includes(this.tenant.name.toLowerCase()))
    );
  }

  /**
   * @return {boolean} If user is owner/admin/manager of this current site
   */
  isManager() {
    if (Store.state.user.role === "integrator") return false;
    if (Store.state.user.role === "staff") return true;
    for (let i = 0; i < Store.state.general.memberships.length; i++) {
      const current = Store.state.general.memberships[i];
      if (
        current.role !== "member" &&
        current.tenant.cloud_id === this.tenant.cloud_id &&
        (current.sites === "all" || current.sites.includes(this.id))
      ) {
        return true;
      }
    }
    return false;
  }

  /**
   * @return {boolean} If user is owner/admin of this current site
   */
  isAdmin() {
    if (Store.state.user.role === "integrator") return false;
    if (Store.state.user.role === "staff") return true;
    for (let i = 0; i < Store.state.general.memberships.length; i++) {
      const current = Store.state.general.memberships[i];
      if (
        (current.role === "owner" || current.role === "admin") &&
        current.tenant.cloud_id === this.tenant.cloud_id &&
        (current.sites === "all" || current.sites.includes(this.id))
      ) {
        return true;
      }
    }
    return false;
  }
}
