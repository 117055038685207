export default class Size {
  constructor() {
    this.currentSize = 0;
    this.MOBILE = 1;
    this.LARGE_MOBILE = 1.1;
    this.TABLET = 2;
    this.DESKTOP = 3;
    window.addEventListener("resize", this.calculate.bind(this));
    this.calculate();
  }
  calculate() {
    let width = window.innerWidth;
    if (width <= 500) {
      this.currentSize = this.MOBILE;
    } else if (width <= 550) {
      this.currentSize = this.LARGE_MOBILE;
    } else if (width <= 1024) {
      this.currentSize = this.TABLET;
    } else {
      this.currentSize = this.DESKTOP;
    }
  }
}
