//@ts-check
import Vue from "vue";
import Store from "../store/index";
import { gererKg } from "../functions/conversion";

export default class Objectives {
  constructor() {
    this.objectives = {};
  }

  clear() {
    this.objectives = {};
  }

  /**
   * @param {String} key
   * @param type
   */
  get(key, type = "number") {
    if (!key) {
      return null;
    }
    type = this.parseType(type);
    let root = this.objectives;
    let keys = key.split(".");
    for (let k of keys) {
      if (root && root[k] !== undefined) {
        root = root[k];
      } else {
        return null;
      }
    }

    if (!root[type] && type == "number") {
      return root["weight"];
    }
    return Math.round(root[type] * 100) / 100;
  }

  /**
   * @param {Number} value
   * @param {String} type
   */
  format(value, type) {
    if (!value) {
      return null;
    }
    if (typeof value == "string") {
      return value;
    }
    switch (this.parseType(type)) {
      case "weight": {
        const weightPref = Store.state.user
          .getPreference("preferences.general.weightUnit")
          .toLowerCase();
        return `${parseFloat(gererKg(value, weightPref))} ${weightPref}`;
      }
      case "number":
      case "perSow":
        return Math.round(value * 100) / 100;
      case "percent":
        return Math.round(value * 10000) / 100 + "%";
    }
    return value;
  }

  // getColor(type, values, value) {
  //   let colors;
  //   switch (type) {
  //     case "higher":
  //       colors = ["#bf0711", "#edcc0d", "#008000", "#008000", "#1c9ce6"];
  //       break;
  //     case "lower":
  //       colors = ["#1c9ce6", "#008000", "#008000", "#edcc0d", "#bf0711"];
  //       break;
  //     case "target":
  //       colors = ["#bf0711", "#bf0711", "#edcc0d", "#008000", "#edcc0d", "#bf0711"];
  //       break;
  //   }
  // }

  /**
   * @param {String} key
   * @param {Number} number
   * @param {Number} percent
   * @param {Number} perSow
   * @param {Number} weight
   */
  set(key, number, percent, perSow, weight, time) {
    let root = this.objectives;
    let keys = key.split(".");
    let lastKey = keys.splice(keys.length - 1, 1)[0];
    for (let k of keys) {
      if (!root[k]) {
        Vue.set(root, k, {});
      }
      root = root[k];
    }
    Vue.set(root, lastKey, {
      number,
      weight,
      percent,
      perSow,
      time
    });
  }

  /**
   * @param {String} key
   */
  parseType(key) {
    if (key == "pourcent") {
      return "percent";
    }
    if (key == "per_sow") {
      return "perSow";
    }
    return key;
  }
}
