import Store from "../store";
import { recupererHour, recupererMinutes } from "@/functions/conversion";
import { defaultValidator } from "@/functions/preferences";
import ObjectivesType from "@/assets/json/objectives-type.json";

export function objectivesTable(prefs = {}, type) {
  let obj_objectives = {};
  const objectives =
    type === "user"
      ? Store.state.user.getPreference("objectives")
      : Store.state.sites.getPreference("objectives");
  if (prefs) {
    for (const key of prefs) {
      const currentObj = objectives[key];
      if (currentObj && currentObj.type !== "None") {
        obj_objectives[key] = {
          value: currentObj.values[currentObj.type === "lower" ? 1 : 2]
        };
      }
    }
  }
  return obj_objectives;
}

export function objectiveColorToRGB(
  colorObjective = ["black", "black"],
  fade = 1
) {
  const colors = {
    yellow: [237, 204, 13],
    red: [191, 7, 17],
    green: [0, 128, 0],
    blue: [28, 156, 230],
    black: [0, 0, 0]
  };
  let color1 = colors[colorObjective[0]];
  let color2 = colors[colorObjective[1]];
  let diffRed = color2[0] - color1[0];
  let diffGreen = color2[1] - color1[1];
  let diffBlue = color2[2] - color1[2];
  let gradient = {
    red: parseInt(Math.floor(color1[0] + diffRed * fade), 10),
    green: parseInt(Math.floor(color1[1] + diffGreen * fade), 10),
    blue: parseInt(Math.floor(color1[2] + diffBlue * fade), 10)
  };
  return `rgb(${gradient.red}, ${gradient.green}, ${gradient.blue})`;
}

export function setObjectiveColor(colorObjective = "black-black") {
  if (colorObjective === "black-black") return "transparent";
  // if (gradient) {
  //   let arrColor = colorObjective;
  //   if (typeof arrColor === "string") {
  //     arrColor = arrColor.split("-");
  //   }
  //   return objectiveColorToRGB(arrColor, fade);
  // } else {
  let color;
  switch (colorObjective) {
    case "green-green":
    case "green-yellow":
    case "yellow-green":
    case "blue-green":
    case "green-blue":
      color = new String("#008000");
      color.type = "green";
      return color;
    case "yellow-yellow":
    case "red-yellow":
    case "yellow-red":
      color = new String("#edcc0d");
      color.type = "yellow";
      return color;
    case "red-red":
      color = new String("#bf0711");
      color.type = "red";
      return color;
    case "blue-blue":
      color = new String("#1C9CE6");
      color.type = "blue";
      return color;
  }
  // }
}

export function numberObjectiveType(objective) {
  switch (objective) {
    case "lowConsumptionSows":
    case "unknownSows":
    case "population":
    case "increased":
    case "decreased":
      return "sow";
    case "todayQuantity":
    case "avgQuantity":
      return "kg";
    default:
      return "number";
  }
}

export function setObjectiveNumber(unit, number, percent, per_sow) {
  if (["time", "number", "weight"].includes(unit) && number !== undefined)
    return typeof number === "string"
      ? number.includes("m") && !number.includes("h")
        ? recupererMinutes(number)
        : number.includes("h")
        ? recupererHour(number)
        : parseFloat(number)
      : parseFloat(number);

  if (unit === "percent" && percent !== undefined) {
    if (typeof percent === "string")
      percent = parseFloat(percent.replace("%", ""));
    return percent;
  }

  if (unit === "per_sow" && per_sow !== undefined) return per_sow;
}

export function countObjective(type, values, number) {
  let color = "black-black";
  let fade = 1;
  if (
    ["lower", "target", "higher"].includes(type) &&
    (number || number === 0)
  ) {
    // set variables
    let lowest = values[0];
    const target = values[type === "lower" ? 1 : 2];
    let average = values[type === "lower" ? 2 : 1];
    if (!average)
      average =
        (values[type === "lower" ? 1 : 0] + values[type === "lower" ? 3 : 2]) /
        2;
    let highest = values[type === "target" ? 4 : 3];
    let mid_right = values[3];
    if (type === "target" && !mid_right) {
      mid_right =
        ((values[4] ? values[4] : target - lowest + target) + target) / 2;
    }
    if (type === "higher" && !highest) highest = values[2];
    if (type === "lower" && typeof lowest !== "number") lowest = values[1];
    const percent = (number - target) * 100;

    // on emergency
    if (
      (type === "lower" && number >= highest) ||
      (type === "target" && (number >= highest || number <= lowest)) ||
      (type === "higher" && number <= lowest)
    )
      color = "red-red";

    // on emergency-warning
    if (
      number < highest &&
      number > target &&
      ((type === "lower" && number > average) ||
        (type === "target" && number >= mid_right))
    ) {
      color = "yellow-red";
      fade =
        type === "lower"
          ? parseFade(percent, average, highest)
          : 1 - parseFade(percent, highest, mid_right);
    }
    if (
      (type === "target" && number >= lowest && number < mid_right) ||
      (type === "higher" && number > lowest && number <= average)
    ) {
      color = "red-yellow";
      fade =
        1 -
        (type === "higher"
          ? parseFade(percent, target, lowest)
          : parseFade(percent, average, lowest));
    }

    // on warning
    if (number === average) {
      color = "yellow-yellow";
      fade = 1;
    }

    // on warning-target
    if (
      ["target", "higher"].includes(type) &&
      number < highest &&
      number >= average &&
      number < target
    ) {
      color = "yellow-green";
      fade = 1 - parseFade(percent, target, average);
    }
    if (
      number < highest &&
      ((type === "lower" &&
        color !== "yellow-red" &&
        (number > target || (number < average && number > target))) ||
        (type === "target" && number > target && number <= mid_right))
    ) {
      color = "green-yellow";
      fade =
        type === "lower"
          ? parseFade(percent, lowest, target)
          : 1 - parseFade(percent, target, mid_right);
    }

    // on target
    if (
      target === number ||
      (type === "lower" && number < target && typeof lowest !== "number") ||
      (type === "higher" && number > target && !highest)
    ) {
      color = "green-green";
      fade = 1;
    }

    // on target-higher
    if (type === "higher" && number < highest && number >= target) {
      color = "green-blue";
      fade = parseFade(percent, highest, target);
    }
    if (
      type === "lower" &&
      number < highest &&
      number <= average &&
      number < target
    ) {
      color = "blue-green";
      fade = 1 - parseFade(percent, lowest, target);
    }

    // on higher
    if (
      (type === "lower" && number <= lowest) ||
      (type === "higher" && number >= highest)
    ) {
      color = "blue-blue";
      fade = 1;
    }
  }
  return { color: color, fade: fade };
}

export async function backwardCompatibility(data, site = false) {
  const prefs = JSON.parse(JSON.stringify(data));
  let objectives = await defaultValidator(["objectives"], {}, site);
  const gesFeed = prefs.gestation ? prefs.gestation.feeding : null;
  const gesVisits = prefs.gestation ? prefs.gestation.visits : null;
  const gesLosses = prefs.gestation ? prefs.gestation.losses : null;
  const lactHistory = prefs.lactation ? prefs.lactation.feedingHistory : null;
  const lactToday = prefs.lactation ? prefs.lactation.feedingToday : null;
  const farrWean = prefs.performances ? prefs.performances.farr_wean : null;
  const herdReprod = prefs.performances ? prefs.performances.herd_reprod : null;
  const userReport = prefs.performances ? prefs.performances.users : null;
  const litters = prefs.litters ? prefs.litters.sows_pgts : null;
  const herd = prefs.herd ? prefs.herd.active_sows : null;
  if (gesFeed) {
    const obj = await editObjective(gesFeed, objectives);
    objectives = obj;
    delete prefs.gestation.feeding;
  }
  if (gesVisits) {
    const obj = await editObjective(gesVisits, objectives);
    objectives = obj;
    delete prefs.gestation.visits;
  }
  if (gesLosses) {
    const obj = await editObjective(gesLosses, objectives);
    objectives = obj;
    delete prefs.gestation.losses;
  }
  if (lactHistory) {
    const obj = await editObjective(lactHistory, objectives);
    objectives = obj;
    delete prefs.lactation.feedingHistory;
  }
  if (lactToday) {
    const obj = await editObjective(lactToday, objectives);
    objectives = obj;
    delete prefs.lactation.feedingToday;
  }
  if (farrWean) {
    const obj = await editObjective(farrWean, objectives);
    objectives = obj;
    delete prefs.performances.farr_wean;
  }
  if (herdReprod) {
    const obj = await editObjective(herdReprod, objectives);
    objectives = obj;
    delete prefs.performances.herd_reprod;
  }
  if (userReport) {
    const obj = await editObjective(userReport, objectives);
    objectives = obj;
    delete prefs.performances.users;
  }
  if (prefs.performances && prefs.performances.monitoring) {
    delete prefs.performances.monitoring;
  }
  if (litters) {
    const obj = await editObjective(litters, objectives);
    objectives = obj;
    delete prefs.litters;
  }
  if (herd) {
    const obj = await editObjective(herd, objectives);
    objectives = obj;
    delete prefs.herd;
  }
  if (prefs.reports) {
    if (prefs.reports.piglets_mortality) delete prefs.reports.piglets_mortality;
    if (prefs.reports.sow_withdrawals) delete prefs.reports.sow_withdrawals;
    if (prefs.reports.locations) delete prefs.reports.locations;
    if (prefs.reports.farm_comparison) delete prefs.reports.farm_comparison;
  }
  prefs.objectives = objectives;
  return await JSON.parse(JSON.stringify(prefs));
}

function parseFade(percent, value1, value2) {
  return parseFloat("." + Math.round(Math.abs(percent / (value1 - value2))));
}

function editObjective(data, obj) {
  const prefs = JSON.parse(JSON.stringify(data));
  const objectives = JSON.parse(JSON.stringify(obj));
  for (const [key, value] of Object.entries(prefs)) {
    let objKey =
      key === "farr_rate"
        ? "far_rate"
        : key === "average"
        ? "averageFeeding"
        : key;
    if (!objectives[objKey]) {
      objectives[objKey] = {};
    }
    if (value.unit) {
      objectives[objKey].unit =
        value.unit === "pourcent"
          ? "percent"
          : key === "wean_age"
          ? "per_sow"
          : ["avgQuantity", "todayQuantity"].includes(key)
          ? "weight"
          : value.unit;
    }
    if (value.Objectives) {
      if (value.Objectives.type)
        objectives[objKey].type = value.Objectives.type;
      if (value.Objectives.values && value.Objectives.values.length > 0)
        objectives[objKey].values = value.Objectives.values;
      prefs[key].priority = value.Objectives.priority;
      delete prefs[key].Objectives;
    }
    if (ObjectivesType.manyObjectives.includes(objKey)) {
      switch (objectives[objKey].unit) {
        case "percent":
          objectives["pc_" + objKey] = objectives[objKey];
          delete objectives[objKey];
          objKey = "pc_" + objKey;
          break;
        case "per_sow":
          objectives["ps_" + objKey] = objectives[objKey];
          delete objectives[objKey];
          objKey = "ps_" + objKey;
          break;
      }
    }
    delete prefs[key].unit;
    const newKey =
      key === "farr_rate"
        ? "far_rate"
        : key === "average"
        ? "averageFeeding"
        : key;
    if (key === "farr_rate" || key === "average")
      delete Object.assign(prefs, { [newKey]: prefs[key] })[key];
    if (objectives[objKey]) {
      delete objectives[objKey].unit;
      objectives[objKey] = JSON.parse(JSON.stringify(objectives[objKey]));
      prefs[newKey] = JSON.parse(JSON.stringify(prefs[newKey]));
    }
  }
  return JSON.parse(JSON.stringify(objectives));
}
