<template lang="pug">
.header
  .menutoggle.menuDesktop(tabindex="0" @click="$store.state.menuOpened = !$store.state.menuOpened" @keyup.enter="$store.state.menuOpened = !$store.state.menuOpened")
    .icon.eva.eva-menu-arrow-outline(:class="!$store.state.menuOpened ? 'menu-closed' : ''")
  .menutoggle.menuMobile(tabindex="0" @click="$store.state.menuOpened = !$store.state.menuOpened" @keyup.enter="$store.state.menuOpened = !$store.state.menuOpened")
    .icon.eva.eva-menu-outline(v-if="!$store.state.menuOpened")
    .icon.eva.eva-close-outline(v-if="$store.state.menuOpened")
  .logo(tabindex="0" @click="goToDashboard" @keyup.enter="goToDashboard")
    img(v-lazy="'img/LogoVision.png'" alt="Vision")
  .title {{ $store.state.title }}
  .subtitle(v-for="subtitle of $store.state.subtitles") {{ subtitle }}
  .grow
  .lastUpdate(
    v-if="!userIntegrator && (JSON.stringify($sites.currentSite) !== '{}' && ($sites.currentSite.hasFeeding() || $sites.currentSite.hasZootech()))"
    :class="{'lastUpdate--late': diffMinuteCond >= 60}"
    :style="{paddingRight: '14px'}"
    v-html="lastActiveAt"
  )
  .notifications(v-if="false && !userIntegrator" tabindex="0" @click="$router.push('/dashboard/notifications')" @keyup.enter="$router.push('/dashboard/notifications')")
    .notifContainer
      .eva.eva-bell-outline
      .notificationAmount(v-if="notifications.length > 0") {{ notifications.length }}
  .site(v-if="!userIntegrator && $route.name != 'farm-comparison' && $sites.sites.length")
    .selectContainer(:class="{disabled: $sites.sites.length <= 1}")
      .icon.eva.eva-home-outline
      .multiselect(@click="$refs.input.focus()" :class="{showChoices: showSites}")
        .selected
          span {{ $sites.nameToDisplay(siteId || $sites.sites.length > 1 ? $sites.sites.find(x => x.id === siteId) : $sites.sites[0]) }}
        form
          input(ref="input" @focus="showSites = true" @blur="hideSites" v-model="filteredSites" autocomplete="off"
            :placeholder="showSites ? $sites.nameToDisplay($sites.sites.find(x => x.id === siteId)) : null")
        .choices(:class="{show: showSites}" @click.stop.prevent @mousedown="focus")
          .scroll
            template(v-if="getTenants.length + getSharingTenants.length > 1")
              .optgroup(v-for="tenant of getTenants")
                span {{ tenant.name }}
                .choice(v-for="site of getSites.filter(s => s.tenant.cloud_id === tenant.cloud_id)" @click.stop.prevent="changeSite(site.id)" :class="{active: siteId == site.id}") {{ $sites.nameToDisplay(site) }}
            template(v-if="getSharingTenants.length")
              template(v-if="getTenants.length")
                .optgroup
                  span {{ $t('sharedSites') }}
                  template(v-for="tenant of getSharingTenants")
                    .choice(v-for="site of getSharingTenantsSites.filter(s => s.tenant.cloud_id === tenant.cloud_id)" @click.stop.prevent="changeSite(site.id)" :class="{active: siteId == site.id}") {{ $sites.nameToDisplay(site) }}
              template(v-else)
                template(v-for="tenant of getSharingTenants")
                  .choice(v-for="site of getSharingTenantsSites.filter(s => s.tenant.cloud_id === tenant.cloud_id)" @click.stop.prevent="changeSite(site.id)" :class="{active: siteId == site.id}") {{ $sites.nameToDisplay(site) }}
            .choice(v-if="getTenants.length + getSharingTenants.length == 1" v-for="site of getSites" @click.stop.prevent="changeSite(site.id)" :class="{active: siteId == site.id}") {{ $sites.nameToDisplay(site) }}
            .choice.none(v-if="getSites.length == 0 && getSharingTenantsSites.length ==  0") {{ $t("noResult") }}
  .user(v-if="$user.email" tabindex="0" @click="showUserPopper = !showUserPopper" @keyup.enter="showUserPopper = !showUserPopper")
    .name {{ $user.initials() }}
    .arrow.eva(ref="user_arrow" :class="[showUserPopper ? 'eva-chevron-up-outline' : 'eva-chevron-down-outline']")
    .popper-container(:class="{show: showUserPopper}" @click.stop="showUserPopper = false")
      .popper(ref="popper")
        router-link.action(
          v-if="$user.isAdmin() && $sites.tenants.some(t => t.ltrack_access) && $user.hasSilo()"
          :to="{ name: 'l-track-subscriptions', params: {breadcrumbs: $route.name} }"
          :tabindex="showUserPopper ? 0 : 1"
        )
          span.eva.eva-award
          span {{ $t('settings.manager.lTrackSubscriptions') }}
        router-link.action(v-if="$user.isAdmin()" :to="{ name: 'sites-manager', params: {breadcrumbs: $route.name} }" :tabindex="showUserPopper ? 0 : 1")
          span.eva.eva-home
          span {{ $t('settings.manager.sites') }}
        router-link.action(v-if="$user.isAdmin()" :to="{ name: 'users-manager', params: {breadcrumbs: $route.name} }" :tabindex="showUserPopper ? 0 : 1")
          span.eva.eva-people
          span {{ $t('settings.manager.users') }}
        router-link.action(v-if="$user.isAdmin()" :to="{ name: 'dictionary-manager', params: {breadcrumbs: $route.name} }" :tabindex="showUserPopper ? 0 : 1")
          span.eva.eva-book-open
          span {{ $t('settings.manager.dictionary') }}
        router-link.action(v-if="$user.role !== 'integrator'" :to="{ name: 'objectives', params: {breadcrumbs: $route.name} }" :tabindex="showUserPopper ? 0 : 1")
          .eva.eva-flag
          span {{ $t('objectives.title') }}
        router-link.action(:to="{ name: 'settings', params: {breadcrumbs: $route.name} }" :tabindex="showUserPopper ? 0 : 1")
          span.eva.eva-settings
          span {{ $t('settings.title') }}
        router-link.action(v-if="$user.role !== 'integrator'" :to="{ name: 'help', params: {breadcrumbs: $route.name} }" :tabindex="showUserPopper ? 0 : 1")
          .eva.eva-book
          span {{ $t('help.title') }}
        a.action(href="javascript:void(0)" :tabindex="showUserPopper ? 0 : 1" @click="logout")
          span.eva.eva-log-out
          span {{ $t('logout') }}
        .popperArrow(data-popper-arrow="true") ▲
</template>

<script>
import { prefVisible } from "@/functions/preferences";
import { createPopper } from "@popperjs/core";
import RequestHelper from "@/functions/RequestHelper";
import { DateTime } from "luxon";

export default {
  name: "horizontal-nav",
  data() {
    return {
      prefsType: "",
      lastActiveAt: "",
      diffMinuteCond: 0,
      showUserPopper: false,
      notifications: [],
      syncInterval: null,
      notifInterval: null,
      siteId: this.$sites.currentSite ? this.$sites.currentSite.id : null,
      showSites: false,
      filteredSites: "",
      timeout: null
    };
  },
  computed: {
    currentSiteName() {
      return this.$sites.nameToDisplay(this.$sites.currentSite);
    },
    currentSiteId() {
      return this.$sites.currentSite ? this.$sites.currentSite.id : null;
    },
    userIntegrator() {
      return this.$user.role === "integrator";
    },
    straightString() {
      return string =>
        string
          .toString()
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
    },
    getSharingTenants() {
      return this.$sites.tenants
        .filter(
          c =>
            this.$store.state.general.memberships.some(
              m => m.tenant.cloud_id == c.cloud_id && m.use_sharing_name
            ) &&
            String(c.cloud_id).length > 0 &&
            (this.filteredSites.length == 0 ||
              this.straightString(String(c.name)).indexOf(
                this.straightString(this.filteredSites)
              ) > -1 ||
              this.getSites.find(x => x.tenant.cloud_id == c.cloud_id))
        )
        .sort((a, b) => {
          return String(a).toLowerCase().localeCompare(String(b).toLowerCase());
        });
    },
    getTenants() {
      return this.$sites.tenants
        .filter(
          c =>
            !this.$store.state.general.memberships.some(
              m => m.tenant.cloud_id == c.cloud_id && m.use_sharing_name
            ) &&
            String(c.cloud_id).length > 0 &&
            (this.filteredSites.length == 0 ||
              this.straightString(String(c.name)).indexOf(
                this.straightString(this.filteredSites)
              ) > -1 ||
              this.getSites.find(x => x.tenant.cloud_id == c.cloud_id))
        )
        .sort((a, b) => {
          return String(a).toLowerCase().localeCompare(String(b).toLowerCase());
        });
    },
    getSites() {
      return this.$sites.sites
        .filter(
          c =>
            !this.getSharingTenants.some(
              t => t.cloud_id == c.tenant.cloud_id
            ) &&
            String(c.id).length > 0 &&
            (this.filteredSites.length == 0 ||
              this.straightString(String(c.name)).indexOf(
                this.straightString(this.filteredSites)
              ) > -1 ||
              this.straightString(String(c.tenant.name)).indexOf(
                this.straightString(this.filteredSites)
              ) > -1)
        )
        .sort((a, b) => {
          return String(a).toLowerCase().localeCompare(String(b).toLowerCase());
        });
    },
    getSharingTenantsSites() {
      return this.$sites.sites
        .filter(
          c =>
            this.getSharingTenants.some(t => t.cloud_id == c.tenant.cloud_id) &&
            String(c.id).length > 0 &&
            (this.filteredSites.length == 0 ||
              this.straightString(String(c.name)).indexOf(
                this.straightString(this.filteredSites)
              ) > -1 ||
              this.straightString(String(c.tenant.name)).indexOf(
                this.straightString(this.filteredSites)
              ) > -1)
        )
        .sort((a, b) => {
          return String(a).toLowerCase().localeCompare(String(b).toLowerCase());
        });
    }
  },
  watch: {
    "$store.state.userGoals"(newValue, oldValue) {
      if (newValue !== oldValue) this.changePersonalGoals();
    },
    currentSiteId(newSite) {
      this.lastUpdate();
      // this.getNotifications();
      this.siteId = newSite;
      this.syncInterval = setInterval(this.lastUpdate, 10 * 60 * 1000); // each 10 minutes
    }
  },
  methods: {
    themeSplit() {
      let body = document.body;
      body.classList.add("dark-body");
      let cloudDiv = document.getElementsByClassName("cloud");
      cloudDiv = cloudDiv[0];
      let prefsTheme = this.$user.getPreference("preferences.general.theme");
      if ([null, undefined, "undefined", "", "Dark"].includes(prefsTheme)) {
        if (
          (window.matchMedia &&
            window.matchMedia("(prefers-color-scheme: dark)").matches) ||
          prefsTheme === "Dark"
        ) {
          body.classList.add("dark-body");
          if (cloudDiv) {
            cloudDiv.classList.add("dark");
          }
        } else {
          if (cloudDiv) {
            cloudDiv.classList.remove("dark");
            body.classList.remove("dark-body");
          }
        }
      } else {
        if (
          cloudDiv &&
          (body.classList.contains("dark-body") ||
            cloudDiv.classList.contains("dark"))
        ) {
          body.classList.remove("dark-body");
          cloudDiv.classList.remove("dark");
        }
      }
    },
    changePersonalGoals() {
      if (!window.location.href.includes("objectives")) {
        prefVisible("true");
      }
      this.$user.savePreferences(
        "preferences.general.typeObjectives",
        this.$store.state.userGoals ? "on" : "off"
      );
    },
    async lastUpdate() {
      if (!this.$sites.currentSite || !this.$sites.currentSite.id) {
        return;
      }

      let data = await RequestHelper.get(
        `sites/${this.$sites.currentSite.id}/access-keys?`,
        {
          query: {
            max: "last_active_at"
          }
        }
      );
      const now = new Date(new Date().toISOString());
      const updated = new Date(
        !data.status && data.data[0] ? data.data[0].last_active_at : 0
      );
      if (!updated || !updated.getTime()) {
        this.lastActiveAt = this.$t("date.UpdatedAgoNever");
        this.diffMinuteCond = 60;
        return;
      }
      const diffMinute = Math.abs(
        Math.round((updated.getTime() - now.getTime()) / 1000 / 60)
      );
      let time = "";
      switch (true) {
        case diffMinute < 60: // less than an hour
          time = diffMinute + " min";
          break;
        case diffMinute < 1440: {
          // less than a day
          const hour = Math.floor(diffMinute / 60);
          const min = diffMinute % 60;
          time = min > 0 ? hour + " h " + min + " min" : hour + " h";
          break;
        }
        default: {
          // more than a day
          const days = Math.floor(diffMinute / 1440);
          const hour = Math.floor((diffMinute - days * 1440) / 60);
          time =
            hour > 0
              ? days === 1
                ? `${days} ${this.$t("date.day").toLowerCase()} ${hour} h`
                : `${days} ${this.$t("date.days").toLowerCase()} ${hour} h`
              : `${days} ${this.$t("date.days").toLowerCase()}`;
          break;
        }
      }
      if (diffMinute < 20) {
        this.lastActiveAt = this.$t("date.UpdatedAgoNow");
      } else {
        this.lastActiveAt = this.$t("date.UpdatedAgo", {
          time: time.toLowerCase()
        });
      }
      this.diffMinuteCond = diffMinute;
    },
    changeSite(siteId) {
      if (siteId != this.siteId) {
        this.$sites.changeSite(siteId);
      }
      this.$refs.input.blur();
      this.hideSites();
    },
    async getNotifications() {
      if (!this.$sites.currentSite || !this.$sites.currentSite.id) {
        return;
      }
      const data = await RequestHelper.getAllPages(
        `sites/${this.$sites.currentSite.id}/notifications`,
        {
          notif_at: `>=${DateTime.now().toFormat("yyyy-LL-dd")}`
        }
      );
      this.notifications = data;
    },
    logout() {
      if (this.syncInterval) {
        clearInterval(this.syncInterval);
      }
      localStorage.clear();
      this.$store.commit("setAllDefault");
      this.$router.push({
        name: "logout",
        params: {
          lang: this.$user.getPreference("preferences.general.Language")
        }
      });
    },
    goToDashboard() {
      if (this.$route.path != "/dashboard") {
        this.$router.push("/dashboard");
      }
    },
    focus() {
      this.$refs.input.focus();
      this.showSites = true;
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      setTimeout(() => {
        this.$refs.input.focus();
        this.showSites = true;
        if (this.timeout) {
          clearTimeout(this.timeout);
          this.timeout = null;
        }
      }, 0);
    },
    hideSites() {
      this.timeout = setTimeout(() => {
        this.showSites = false;
        this.filteredSites = "";
      }, 0);
    }
  },
  beforeCreate() {
    this.$store.commit("setUserGoals", true);
  },
  async mounted() {
    // document.addEventListener("keyup", this.moveInList);
    this.lastUpdate();
    this.syncInterval = setInterval(this.lastUpdate, 10 * 60 * 1000); // each 10 minutes
    this.prefsType = this.$user.getPreference(
      "preferences.general.typeObjectives"
    );
    this.themeSplit();
    //this.$store.commit("setUserGoals", this.prefsType === "on");

    // this.notifInterval = setInterval(
    //   this.getNotifications.bind(this),
    //   1000 * 60
    // );
    // setTimeout(this.getNotifications.bind(this), 0);

    if (this.$refs.user_arrow && this.$refs.popper) {
      createPopper(this.$refs.user_arrow, this.$refs.popper, {
        placement: "bottom",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 10]
            }
          },
          {
            name: "preventOverflow",
            options: {
              padding: 10
            }
          }
        ]
      });
    }
  },
  beforeDestroy() {
    if (this.syncInterval) {
      clearInterval(this.syncInterval);
    }
    // if (this.notifInterval) {
    //   clearInterval(this.notifInterval);
    // }
  }
};
</script>

<style lang="stylus" scoped>
.header
  height 60px
  background-color var(--blue)
  display flex
  align-items center
  padding 0 20px
  color white
  .title
    font-size 24px
    white-space nowrap
  .subtitle
    font-size 18px
    opacity 0.75
    margin-left 8px
    display inline-block
    text-overflow ellipsis
    white-space nowrap
    overflow hidden
    &:before
      content "|"
      margin-right 8px
  .grow
    flex-grow 1

.menutoggle
  height 100%
  min-width: 50px;
  font-size 24px
  margin-left -20px
  padding 0 13px
  line-height 1
  cursor pointer
  display flex
  align-items center
  .menu-closed
    transform scaleX(-1)

.logo
  margin-right 22px
  padding 0 13px
  width 210px
  cursor pointer
  height 60px
  display flex
  align-items center
  justify-content center
  border-right 1px solid rgba(255, 255, 255, 0.1)
  flex-shrink 0
  @media screen and (--max-tablette)
    display none

.notifications
  line-height 1
  font-size 32px
  padding 0 14px 0 10px
  height 100%
  cursor pointer
  display flex
  align-items center
  .notifContainer
    height 32px
    position relative
  .notificationAmount
    position absolute
    top -4px
    right -4px
    background-color red
    font-size 16px
    border-radius 10px
    min-width 20px
    padding 2px
    display flex
    justify-content center
    align-items center
    font-weight bold

.site
  .selectContainer
    position relative
    &:not(.disabled):after
      content "\ea6b"
      font-family Eva-Icons
      position absolute
      right 3px
      top 50%
      transform translateY(-50%)
      pointer-events none
    &.disabled .multiselect
      padding-right 5px
      cursor auto
      pointer-events none
    .icon
      font-size 16px
      position absolute
      top 50%
      transform translateY(-50%)
      left 5px
      color rgba(255, 255, 255, 0.75)
      pointer-events none
  .multiselect
    border 1px solid white
    border-radius 3px
    position relative
    cursor text
    padding 2px 25px
    width 225px
    &.showChoices
      border-bottom-left-radius 0
      border-bottom-right-radius 0
    input
      background-color transparent
      margin 0
      padding 0
      height 26px
      line-height 26px
      font-size 16px
      position absolute
      top 2px
      left 25px
      width calc(100% - 50px)
      color white
      &:focus
        background-color var(--blue)
    .choices
      display none
      position absolute
      background-color var(--blue)
      width calc(100% + 2px)
      left -1px
      top 100%
      border 1px solid
      border-top none
      border-color white
      max-height 200px
      z-index 1
      border-bottom-left-radius 3px
      border-bottom-right-radius 3px
      white-space nowrap
      &.show
        display block
      .optgroup
        padding 2px 4px
        span
          opacity .5
        .choice
          padding-left 8px
      .choice
        padding 2px 4px
        cursor pointer
        &.none
          cursor auto
        &.active:before
            content "\2713"
            font-size 10px
            margin-right 5px
            vertical-align middle
      .scroll
        max-height 200px
        overflow-y auto
    .selected
      min-height 26px
      max-width 100%
      white-space nowrap
      font-size 16px

.user
  height 100%
  display flex
  line-height 1
  margin-left 14px
  align-items center
  cursor pointer
  .name
    width 45px
    height 45px
    border-radius 100%
    background lighten(#0747a6, 75%)
    color var(--black)
    text-align center
    line-height 45px
    margin 10px 5px 10px 0

.lastUpdate
  white-space nowrap
  overflow hidden
  text-overflow ellipsis
  direction rtl
  padding-top 2px

.cloud.dark
  .multiselect input:focus
      background-color var(--dark-BG-light)
  .choices
    background-color #313f52

@media screen and (--min-tablette)
  .menuMobile
    display none

@media screen and (--max-tablette)
  .menuDesktop
    display none
  .header
    .lastUpdate, .site, .objectives, .user, .notifications
      display none

@keyframes flash
  0%
    opacity 0
  50%
    opacity 1
  100%
    opacity 0
</style>
