<template lang="pug">
.column-flex
  Maintenance
  .cloud.login-wrapper
    .login
      .login__content
        .login__header
          cloud-icon.icon(ref="iconDashboard" name="LogoVisionFace")
        h1.login__title {{ $t(forgotPassword ? 'login.recover.title' : 'login.title') }}
        .login__form
          .forgotMessage(v-if="forgotPassword")
            p.login__message {{ $t('login.recover.message') }}
            p.login__message {{ $t('login.recover.noEmail') }}
          .generalError(v-if="serverMessage !== ''")
            p.login__message(:class="[serverClass, {alert: loginError}]" v-html="serverMessage")
          form(v-if="forgotPassword"
            :action="apiUrl"
            method="post"
            id="recover"
            @submit.prevent="recoverPassword")
            .emailSection.login-form-group
              label.label(for="confirmEmail") {{ $t('login.email') }}
              input.input(
                @blur="validateEmail(true)"
                type="text"
                id="confirmEmail"
                v-model="email"
                name="confirmEmail"
                ref="confirmEmail"
                autocomplete="confirmEmail"
                required
                :class="{error: emailMessage && emailMessage != 'valide'}"
              )
              .input-error(v-if="emailMessage && emailMessage != 'valide'")
                p.login__message.error {{ emailMessage }}
            .login-form-actions
              input.log(type="submit" :value="$t('login.recover.email')")
            .login-form-actions
              button.cancelButton(type="button" @click="forgotPassword = false") {{ $t('cancel') }}
          form(
            v-if="!forgotPassword"
            :action="apiUrl"
            method="post"
            id="form"
            @submit.prevent="checkLogin")
            .emailSection.login-form-group
              label.label(for="email") {{ $t('login.email_username') }}
              input.input(
                @blur="validateEmail"
                type="text"
                id="email"
                v-model="email"
                name="email"
                ref="email"
                autocomplete="email"
                required
                :class="{error: emailMessage && emailMessage != 'valide'}"
              )
              .input-error(v-if="emailMessage && emailMessage != 'valide'")
                p.login__message.error {{ emailMessage }}
            .passwordSection.login-form-group
              label.label(for="password") {{ $t('login.password') }}
              input.input(
                @blur="validatePassword"
                type="password"
                id="password"
                v-model="password"
                name="password"
                ref="password"
                autocomplete="current-password"
                required
                :class="{error: passwordMessage && passwordMessage != 'valide'}"
              )
              .input-error(v-if="passwordMessage && passwordMessage != 'valide'")
                p.login__message.error {{ passwordMessage }}
            .login-form-actions
              template(v-if="!isLoading")
                input.log(type="submit" :value="$t('login.title')")
                p.login__message(@click="forgotPassword = !forgotPassword" :style="{cursor: 'pointer'}") {{ $t('login.forgotPW') }}
              .log--spinner(v-else)
                cloud-icon.icon.icon__spinner(name="spinner")
  .cloud.login-footer
    component(:is="{template: $t('infoPolicy')}")
    .text.copyright © Copyright {{ new Date().getFullYear() }} | Jyga Technologies inc.<br>{{ $t('allRights')}}
  Modal.cloud(v-if="!$store.state.general.acceptedPolicy || !$store.state.general.acceptedTerms" :condition="true")
    template(v-if="!$store.state.general.acceptedPolicy")
      component(:is="$i18n.locale == 'fr' ? 'PolicyFr' : 'Policy'")
      .module__buttons
        .deja-vu.deja-vu--all
          input.hidden(type="checkbox" id="acceptPolicy" value="acceptPolicy" name="acceptPolicy" v-model="enableButton")
          label(for="acceptPolicy")
            span
              svg(width='12px', height='10px', viewbox='0 0 12 10')
                polyline(points='1.5 6 4.5 9 10.5 1')
            span {{ $t('acceptPolicy') }}
        button.modal-default-button(@click="$store.commit('general/acceptPolicy'); scrollTop(); enableButton = false" :disabled="!enableButton") {{ $t('next') }}
    template(v-else-if="!$store.state.general.acceptedTerms")
      component(:is="$i18n.locale == 'fr' ? 'TermsFr' : 'Terms'")
      .module__buttons
        .deja-vu.deja-vu--all
          input.hidden(type="checkbox" id="acceptTerms" value="acceptTerms" name="acceptTerms" v-model="enableButton")
          label(for="acceptTerms")
            span
              svg(width='12px', height='10px', viewbox='0 0 12 10')
                polyline(points='1.5 6 4.5 9 10.5 1')
            span {{ $t('acceptTerms') }}
        button.modal-default-button(@click="$store.commit('general/acceptTerms')" :disabled="!enableButton") {{ $t('accept') }}
</template>

<script>
import axios from "axios";
import RequestHelper from "@/functions/RequestHelper";
import Axios from "axios";
import Modal from "@/components/modal";
import Maintenance from "@/components/maintenance.vue";
import Policy from "@/views/political/Policy";
import PolicyFr from "@/views/political/Politique";
import Terms from "@/views/political/Terms";
import TermsFr from "@/views/political/Termes";

export default {
  name: "login",
  components: {
    Modal,
    Maintenance,
    Policy,
    PolicyFr,
    Terms,
    TermsFr
  },
  data() {
    return {
      email: null,
      password: null,
      emailMessage: null,
      passwordMessage: null,
      serverMessage: "",
      serverClass: "",
      loginError: false,
      apiUrl: process.env.VUE_APP_API_URL,
      forgotPassword: false,
      enableButton: false,
      isLoading: false
    };
  },
  methods: {
    themeSplit() {
      let body = document.body;
      body.classList.add("dark-body");
      let cloudLogin = document.getElementsByClassName("cloud")[0];
      let cloudFooter = document.getElementsByClassName("cloud")[1];
      let cloudModal = document.getElementsByClassName("cloud")[2];
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        body.classList.add("dark-body");
        if (cloudLogin) cloudLogin.classList.add("dark");
        if (cloudFooter) cloudFooter.classList.add("dark");
        if (cloudModal) cloudModal.classList.add("dark");
      } else {
        body.classList.remove("dark-body");
        if (cloudLogin) cloudLogin.classList.remove("dark");
        if (cloudFooter) cloudFooter.classList.remove("dark");
        if (cloudModal) cloudModal.classList.remove("dark");
      }
    },
    validateEmail(onlyEmail = false) {
      this.serverMessage = "";
      const emailClass = this.forgotPassword ? "confirmEmail" : "email";
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const usernameRegex = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{5,29}$/;
      if (this.email === "" || !this.email) {
        document.getElementById(emailClass).classList.add("error");
        this.emailMessage = this.$t("login.messages.fieldRequired");
      } else if (
        (typeof onlyEmail === "boolean" &&
          onlyEmail &&
          !emailRegex.test(this.email)) ||
        ((typeof onlyEmail !== "boolean" || !onlyEmail) &&
          !emailRegex.test(this.email) &&
          !usernameRegex.test(this.email))
      ) {
        document.getElementById(emailClass).classList.add("error");
        this.emailMessage = this.$t("settings.invalidEmail");
      } else {
        document.getElementById(emailClass).classList.remove("error");
        this.emailMessage = "valide";
      }
    },
    validatePassword() {
      this.serverMessage = "";
      if (this.password === "" || !this.password) {
        document.getElementById("password").classList.add("error");
        this.passwordMessage = this.$t("login.messages.fieldRequired");
      } else if (this.password.length >= 32) {
        document.getElementById("password").classList.add("error");
        this.passwordMessage = this.$t("login.messages.longPW");
      } else {
        document.getElementById("password").classList.remove("error");
        this.passwordMessage = "valide";
      }
    },
    async checkLogin() {
      // email and password provided
      const acceptedPolicyTerms =
        this.$store.state.general.acceptedPolicy &&
        this.$store.state.general.acceptedTerms;
      if (this.email && this.password && acceptedPolicyTerms) {
        this.isLoading = true;
        axios
          .post(
            `${process.env.VUE_APP_API_URL}auth`,
            {},
            {
              auth: {
                username: this.email,
                password: this.password
              }
            }
          )
          .then(async response => {
            if (response.data.token) {
              this.$store.state.general.token = response.data.token;
              await this.$user.loadFromApi();
              if (this.$user.role === "staff") {
                await this.$sites.loadStaffFromApi();
              } else {
                await RequestHelper.get("members").then(data => {
                  this.$store.commit("general/setMemberships", data.data);
                });
                await this.$sites.loadFromApi();
              }
              this.isLoading = false;
              if (
                this.$user.role === "integrator" ||
                (this.$user.hasAnyAccess() && this.$sites.tenants.length)
              ) {
                if (
                  this.$user.role === "integrator" ||
                  this.$sites.sites.some(s => s.hasFeeding() || s.hasZootech())
                ) {
                  this.$router.push({ name: "root" });
                } else {
                  this.$router.push({ name: "silos" });
                }
              } else {
                this.loginError = true;
                this.serverClass = "error";
                this.serverMessage = this.$t("login.messages.noAccess");
                this.$store.commit("setAllDefault");
              }
            } else {
              // Si la connexion est impossible avec les donnees saisies
              this.loginError = true;
              this.isLoading = false;
              this.serverClass = "error";
              this.serverMessage = this.$t("login.messages.probConnect");
            }

            return response;
          })
          .catch(e => {
            this.isLoading = false;
            if (
              e.response &&
              e.response.status >= 400 &&
              e.response.status < 500
            ) {
              this.loginError = true;
              this.serverClass = "error";
              if (
                e.response.data &&
                e.response.data.message &&
                ["user_notfound", "authentication_failed"].includes(
                  e.response.data.message
                )
              ) {
                this.serverMessage = this.$t(
                  `login.messages.${
                    e.response.data.message == "user_notfound"
                      ? "userNotFound"
                      : "wrongPassword"
                  }`
                );
              } else {
                this.serverMessage = this.$t("login.messages.unauthorized");
              }
            } else {
              this.loginError = true;
              this.serverClass = "error";
              this.serverMessage = this.$t("login.messages.serverError");
            }
            return false;
          });
        return true;
      } else {
        // Policy and terms not accepted
        if (!acceptedPolicyTerms) {
          this.loginError = true;
          this.serverClass = "error";
          this.serverMessage = this.$t("login.messages.policy");
        } else {
          // email and password not provided
          if (!this.email && !this.password) {
            this.loginError = true;
            this.serverClass = "error";
            this.serverMessage = this.$t("login.messages.emailPassRequired");
          } else {
            // email not provided
            if (!this.email) {
              this.loginError = true;
              this.serverClass = "error";
              this.serverMessage = this.$t("login.messages.emailRequired");
            }

            // password not provided
            if (!this.password) {
              this.loginError = true;
              this.serverClass = "error";
              this.serverMessage = this.$t("login.messages.passRequired");
            }
          }
        }
      }
    },
    async recoverPassword() {
      if (this.email) {
        let error = null;
        await Axios.post(`${process.env.VUE_APP_API_URL}user/recover`, {
          email: this.email
        }).catch(e => (error = e.response));
        if (error) {
          if (error.status >= 400 && error.status < 500) {
            this.loginError = true;
            this.serverClass = "error";
            this.serverMessage = this.$t("login.recover.notFound");
          } else {
            this.loginError = true;
            this.serverClass = "error";
            this.serverMessage = this.$t("login.messages.serverError");
          }
          return false;
        } else {
          this.loginError = true;
          this.serverClass = "success";
          this.serverMessage = this.$t("login.recover.link");
        }
        return true;
      } else {
        // email not provided
        this.loginError = true;
        this.serverClass = "error";
        this.serverMessage = this.$t("login.messages.emailRequired");
      }
    },
    scrollTop() {
      let container = document.getElementsByClassName("modal-container")[0];
      container.scrollTo({
        top: 0
      });
    }
  },
  mounted() {
    this.themeSplit();
    if (this.$route.params.message && this.$route.params.messageClass) {
      this.loginError = false;
      this.serverMessage = this.$route.params.message;
      this.serverClass = this.$route.params.messageClass;
    }
  },
  created() {
    this.themeSplit();
  }
};
</script>

<style lang="stylus" scoped>
@import "../assets/css/mixins.styl";

.column-flex
  display flex
  flex-flow column
  height 100%

.login
  align-items center
  background-color white
  box-shadow 0 1px 1px rgba(23, 43, 77, 0.2),
  0 0 0.5px 0 rgba(23, 43, 77, 0.25)
  display flex
  justify-content center
  height 100%
  width 100%
  @media screen and (--min-large-mobile)
    border-radius var(--global-radius)
    height auto
    width 330px
  &-wrapper
    align-items center
    background-color var(--blue)
    display flex
    justify-content center
    width 100vw
    flex-grow 1
  &__content
    width 100%
  &__header
    display flex
    justify-content flex-end
    margin var(--vertical-spacing) 0 0
    padding 0 var(--horizontal-spacing)
  &__host
    align-self center
    margin-left auto
  &__title
    color var(--black)
    flex 0 0 100%
    font-size 32px
    font-weight var(--font-black)
    line-height 32px
    margin 0
    margin-bottom 10px
    padding 0 var(--horizontal-spacing)
  &__message
    font-size 15px
    margin 0 auto
    padding 0 var(--horizontal-spacing)
  &__form
    margin 0 0 calc(var(--vertical-spacing) * 1.5) 0
    form
      margin-top 35px
      & .login-form-group
        margin-top 20px
      @media screen and (--min-large-mobile)
        margin-top 20px
  &-forgot
    margin 0
    text-align center
    a
      color var(--black-clay)
      text-decoration none
      &:hover
        text-decoration underline
.login-form
  display flex
  flex-wrap wrap
  margin calc(var(--vertical-spacing) / 2) 0
  padding 0 var(--horizontal-spacing)
  width 100%
  .generalError
    padding 0 var(--horizontal-spacing)
.login-form-group
  display flex
  flex-direction column
  justify-content flex-start
  margin-top calc(var(--vertical-spacing) / 2)
  padding 0 var(--horizontal-spacing)
  position relative
  &_small, &_medium, &_wide
    flex 0 0 100%
  @media(--medium-up)
    &_small
      flex 0 1 140px
    &_medium
      flex 0 1 280px
    &_wide
      flex 1 1 100%
.login-form-actions
  display flex
  flex-direction column
  margin var(--vertical-spacing) 0
  padding 0 var(--horizontal-spacing)
  width 100%
  & > *
    &:first-child
      margin-left 0
  .cancelButton
    font-size 16px
    margin -10px auto
    padding 10px 0
    width 100%
    background-color transparent
    color var(--blue)
    &:hover, &:focus, &:active
      box-shadow none !important
  @media(--medium-up)
    margin calc(var(--vertical-spacing) / 2) 0
    margin-top calc(var(--vertical-spacing) / 2 + 4px)
    & > *
      &:first-child
        margin-left auto
    &_inline
      align-items flex-start
      flex-direction column
      justify-content center
      width auto
    &_inline > *
      &:first-child
        margin-left 0
.input
  -moz-appearance textfield
  appearance none
  background-color white
  border 1px solid rgba(9, 30, 66, 0.13)
  border-radius calc(var(--global-radius) * 1.5)
  color var(--black)
  font-size 16px
  font-weight var(--font-regular)
  height 54px
  line-height var(--vertical-spacing)
  margin-bottom 10px
  padding 0 .75rem
  transition-property background-color
  @mixin animate
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button
    -webkit-appearance none
    margin 0
  &:focus
    outline 0
  &:focus:not(&_invalid)
    border 2px solid var(--blue)
    box-shadow 0 1px 0 0 rgba(22, 29, 37, 0.05)
  &:disabled
    user-select none
  &_disabled
    background-color var(--athens-gray)
    border-color rgba(9, 30, 66, 0.13)
    color rgba(9, 30, 66, 0.5)
  &_invalid
    background-color var(--linen)
    border-color var(--red)
  &-error
    .error
      margin 0
  @media(--medium-up)
    font-size 16px
    height 36px
.label + .input
  margin-top 6px
  @media(--medium-up)
    margin-top 4px
.label
  align-items center
  color var(--black-clay)
  display flex
  font-size 16px
  font-weight var(--font-bold)
  line-height var(--vertical-spacing)
  @include animate
  &[for]:not(&_disabled):hover
    cursor pointer
  &_disabled
    color rgba(9, 30, 66, 0.5)
  &_invalid
    color var(--red)
.log
  align-items center
  -webkit-appearance none
  border none
  border-radius var(--global-radius)
  box-sizing border-box
  cursor pointer
  display inline-flex
  font-size 16px
  font-weight 700
  justify-content center
  margin 10px 5px
  padding 10px 8px
  position relative
  -webkit-tap-highlight-color rgba(0, 0, 0, 0)
  text-align center
  text-decoration none
  user-select none
  vertical-align middle
  transition-duration 0.25s
  transition-timing-function cubic-bezier(0.4, 0, 0.2, 1)
  text-overflow ellipsis
  white-space initial
  width 100%
  background-color var(--blue)
  color #fff
  &:focus, &:hover, &:active
    background-color rgba(0, 82, 204, 0.9)
    outline 0
  &--spinner
    padding 8px
    background-color var(--blue)
    border-radius 100%
    width 40px
    margin 10px auto 0

.login-footer
  display flex
  flex-flow column wrap
  justify-content space-between
  padding 10px 20px
  height 10vh
  @media screen and (--min-large-mobile)
    flex-flow row wrap
    height auto
  .copyright, a
    font-size 10px
    line-height 1.2
    text-align center
    color var(--black)
  .copyright >>> a
    text-decoration underline
    display inline-block
    margin-right 10px
    &:link, &:visited, &:hover, &:focus, &:active
      color var(--black)

.forgotMessage p
  margin-bottom 10px
.dark
  .login-form-actions .cancelButton
    color var(--athens-gray)
  &.login-wrapper, &.login-footer, &.modal .modal-container
    background-color var(--dark-nav)
    .copyright, a, .copyright >>> a
      color #aaa
  .login
    background-color var(--dark-SB)
    .label
      color var(--athens-gray)
  .login__title, .login-forgot > *
    color var(--athens-gray)
  .icon
    fill var(--athens-gray)

.icon
  fill var(--blue)
  width 30px
  &__spinner
    stroke var(--athens-gray)
</style>
