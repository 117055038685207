<template lang="pug">
nav.menu(:class="{opened: $store.state.menuOpened}" @click="closeMenuOnMobile")
  ul.menuScroll(ref="menuScroll")
    li(v-if="!userIntegrator && $size.currentSize <= $size.TABLET")
      .menu-link.disabled.lastUpdate(:class="{'lastUpdate--late': diffMinuteCond >= 60}" @click.stop) {{ lastActiveAt }}
    li(v-if="userIntegrator || (($user.hasFeeding() || $user.hasZootech()) && $sites.sites.some(s => s.hasFeeding() || s.hasZootech()))")
      router-link.menu-link(:to="{ name: userIntegrator ? 'integrations' : 'dashboard', params: {breadcrumbs: $route.name} }" ref="home"
        @mouseover.native="hover = 'home'" @mouseleave.native="hover = false")
        cloud-icon.icon.nostroke(ref="iconDashboard" name="LogoVisionFace")
        .text {{ userIntegrator ? $t('home') : $t('liveToday') }}
    li(v-if="!userIntegrator && ($user.hasFeeding() || $user.hasZootech()) && $sites.sites.some(s => s.hasFeeding() || s.hasZootech())")
      .menu-link(tabindex="0" ref="favorites" @click.stop="showFav = !showFav" @keyup.enter="showFav = !showFav"
        @mouseover="hover = 'favorites'" @mouseleave="hover = false")
        .icon(ref="iconFavorites")
          .eva.eva-star-outline
        .text {{ $t('favorites.title') }}
        .action
          .eva(:class="showFav ? 'eva-arrow-ios-upward' : 'eva-arrow-ios-downward'")
    li(v-if="!userIntegrator && showFav && Object.keys(favorites).length > 0")
      draggable(
        ref="favoritesList" tag="ul" dragGroup="menuFav" v-bind="dragOptions"
        v-bind:value="favKeys" v-on:input="handleDrag($event)"
        :force-fallback="true" @start="drag=true" @end="drag=false"
        :class="{listFavorites: !$store.state.menuOpened}"
      )
        li(v-for="(favorite, key, index) in favorites" :key="key" v-model="favorites")
          router-link.menu-link.sub-link(:to="{ name: 'favorites', params: { favPage: key, breadcrumbs: $route.name } }" :event="!listFavorites[key].edit ? 'click' : ''"
            @focus="hover = 'favoritesList' + key" @blur="hover = false" :ref="'favoritesList' + key" @mouseover.native="hover = 'favoritesList' + key" @mouseleave.native="hover = false")
            .icon
              .eva.eva-star
              .number {{ index + 1 }}
            template
              .text.text-ellipsis(v-if="!listFavorites[key].edit") {{ key.replace(/-/g, " ") }}
              .text(v-else)
                label.hidden(:for="key") {{ $t('favorites.newPage') }}
                input(type="text" v-model="listFavorites[key].name" :id="key" :name="key" :ref="key" @click.stop @keyup.esc="cancelEditFavPage(key)" @keyup.enter="renameFavoritePage(key)")
            template(v-if="listFavorites[key].edit")
              .action(@click.prevent="renameFavoritePage(key)")
                .eva.eva-save-outline
              .action(@click.prevent="cancelEditFavPage(key)")
                .eva.eva-close-outline
            template(v-else)
              .action.edit(v-if="$store.state.menuOpened && hover == 'favoritesList' + key" @click.prevent="startEditFavPage(key)")
                .eva.eva-edit-outline
              .action(@click.prevent="removeFavoritePage(key)")
                .eva.eva-trash-2-outline
    li(v-if="!userIntegrator && showFav && $store.state.menuOpened")
      form.menu-link.sub-link(@submit.prevent="addFavoritePage()" @click.stop)
        .icon
          .eva.eva-star
          .number +
        .text
          label.hidden(for="favname") {{ $t('favorites.newPage') }}
          input(type="text" v-model="newFavorite" id="favname" name="favname" required)
        button.action(:tabindex="!userIntegrator && showFav && $store.state.menuOpened ? 0 : 1" type="submit" id="submit" name="submit" ref="submitNewFavPage")
          .eva.eva-plus
    li(v-if="false")
      router-link.menu-link(:to="{ name: 'gestation', params: {breadcrumbs: $route.name} }")
        cloud-icon.icon.nostroke.stretch(name="gestation")
        .text {{ $t('gestation.title') }}
    li(v-if="false")
      router-link.menu-link(:to="{ name: 'lactation', params: {breadcrumbs: $route.name} }")
        cloud-icon.icon.nostroke.stretch(name="lactation")
        .text {{ $t('lactation.title') }}
    li(v-if="!userIntegrator && JSON.stringify($sites.currentSite) !== '{}' && $sites.currentSite.hasFeeding() && $user.hasFeeding()")
      router-link.menu-link(:to="{ name: 'feeding', params: {breadcrumbs: $route.name} }" ref="feeding"
        @mouseover.native="hover = 'feeding'" @mouseleave.native="hover = false")
        cloud-icon.icon.nostroke.stretch(name="feed")
        .text {{ $t('feeding') }}
    //- router-link.menu-link(:to="{ name: 'feed-intakes', params: {breadcrumbs: $route.name} }")
      .icon.eva.eva-activity
      .text {{ $t('feedIntakes.title') }}
    li(v-if="!userIntegrator && JSON.stringify($sites.currentSite) !== '{}' && $sites.currentSite.hasZootech() && $user.hasZootech()")
      router-link.menu-link(:to="{ name: 'zootech', params: {breadcrumbs: $route.name} }" ref="zootech"
        @mouseover.native="hover = 'zootech'" @mouseleave.native="hover = false")
        cloud-icon.icon.nostroke(name="zootech")
        .text {{ $t('zootech.title') }}
    li(v-if="!userIntegrator && JSON.stringify($sites.currentSite) !== '{}' && $sites.currentSite.hasZootech() && $user.hasZootech()")
      .menu-link(tabindex="0" ref="reports" @click.stop="showReports = !showReports" @keyup.enter="showReports = !showReports"
        @mouseover="hover = 'reports'" @mouseleave="hover = false")
        .icon.eva.eva-file-text-outline
        .text {{ $t('reports.title') }}
        .action
          .eva(:class="showReports ? 'eva-arrow-ios-upward' : 'eva-arrow-ios-downward'")
    li(v-if="showReports")
      ul(:class="{listFavorites: !$store.state.menuOpened}" ref="reportsList")
        li
          router-link.menu-link.sub-link(:to="{ name: 'gestation-losses', params: {breadcrumbs: $route.name} }" ref="reportsList-gestation-losses"
            @mouseover.native="hover = 'reportsList-gestation-losses'" @mouseleave.native="hover = false")
            cloud-icon.icon.nostroke(name="gestation-losses")
            .text {{ $t('gestation.losses.title') }}
        li(v-if="$sites.currentSite.isManager()")
          router-link.menu-link.sub-link(:to="{ name: 'users-report', params: {breadcrumbs: $route.name} }" ref="reportsList-users-report"
            @mouseover.native="hover = 'reportsList-users-report'" @mouseleave.native="hover = false")
            .icon.eva.eva-people-outline
            .text {{ $t('performances.users.title') }}
        li
          router-link.menu-link.sub-link(:to="{ name: 'reproduction-analysis', params: {breadcrumbs: $route.name} }" ref="reportsList-reproduction-analysis"
            @mouseover.native="hover = 'reportsList-reproduction-analysis'" @mouseleave.native="hover = false")
            cloud-icon.icon.nostroke(name="reproduction-analysis")
            .text {{ $t('reports.repro_analysis.title') }}
        li
          router-link.menu-link.sub-link(:to="{ name: 'performances-monitoring', params: {breadcrumbs: $route.name} }" ref="reportsList-performances-monitoring"
            @mouseover.native="hover = 'reportsList-performances-monitoring'" @mouseleave.native="hover = false")
            .icon.eva.eva-monitor-outline
            .text {{ $t('performances.monitoring.title') }}
        li
          router-link.menu-link.sub-link(:to="{ name: 'piglets-mortality', params: {breadcrumbs: $route.name} }" ref="reportsList-piglets-mortality"
            @mouseover.native="hover = 'reportsList-piglets-mortality'" @mouseleave.native="hover = false")
            cloud-icon.icon.nostroke(name="piglets-mortality")
            .text {{ $t('performances.piglet_mortality') }}
        li
          router-link.menu-link.sub-link(:to="{ name: 'sow-withdrawals', params: {breadcrumbs: $route.name} }" ref="reportsList-sow-withdrawals"
            @mouseover.native="hover = 'reportsList-sow-withdrawals'" @mouseleave.native="hover = false")
            cloud-icon.icon.nostroke(name="withdrawals")
            .text {{ $t('reports.sow_withdrawals.title') }}
        li
          router-link.menu-link.sub-link(:to="{ name: 'locations', params: {breadcrumbs: $route.name} }" ref="reportsList-locations"
            @mouseover.native="hover = 'reportsList-locations'" @mouseleave.native="hover = false")
            .icon.eva.eva-pin-outline
            .text {{ $t('reports.locations.title') }}
        li(v-if="$sites.sites.some(s => s.isManager() && s.hasGestationFeeding() && s.hasLactationFeeding())")
          router-link.menu-link.sub-link(:to="{ name: 'farm-comparison', params: {breadcrumbs: $route.name} }" ref="reportsList-farm-comparison"
            @mouseover.native="hover = 'reportsList-farm-comparison'" @mouseleave.native="hover = false")
            .icon.eva.eva-swap-outline
            .text {{ $t('reports.farm_comparison.title') }}
    li(v-if="!userIntegrator && (($sites.sites.some(s => s.hasSilo()) && $user.hasSilo()) || $sites.sites.length === 0)")
      router-link.menu-link(:to="{ name: 'silos', params: {breadcrumbs: $route.name} }" ref="silos"
        @mouseover.native="hover = 'silos'" @mouseleave.native="hover = false")
        cloud-icon.icon.nostroke(name="l-track-mini")
        .text {{ $t('silos.title') }}
    li(v-if="false && !userIntegrator && $size.currentSize <= $size.TABLET")
      router-link.menu-link(:to="{ name: 'dashboard/notifications', params: {breadcrumbs: $route.name} }")
        .icon.eva.eva-bell-outline
        .text {{ $t('notification.title') }}
    li(v-if="!userIntegrator && $route.name != 'farm-comparison' && $size.currentSize <= $size.TABLET")
      .menu-link( @click.stop)
        .icon.eva.eva-home-outline
        .multiselect(@click="$refs.input.focus()" :class="{showChoices: showSites, disabled: sites.length <= 1}")
          .selected
            span {{ $sites.nameToDisplay(sites.find(x => x.id == siteId)) }}
          input(ref="input" @focus="showSites = true" @blur="hideSites" v-model="filteredSites"
            :placeholder="showSites ? $sites.nameToDisplay(sites.find(x => x.id == siteId)) : null")
          .choices(:class="{show: showSites}" @click.stop.prevent @mousedown="focus")
            .scroll
              template(v-if="getTenants.length + getSharingTenants.length > 1")
                .optgroup(v-for="tenant of getTenants")
                  span {{ tenant.name }}
                  .choice(v-for="site of getSites.filter(s => s.tenant.cloud_id === tenant.cloud_id)" @click.stop.prevent="changeSite(site.id)" :class="{active: siteId == site.id}") {{ $sites.nameToDisplay(site) }}
              template(v-if="getSharingTenants.length")
                template(v-if="getTenants.length")
                  .optgroup
                    span {{ $t('sharedSites') }}
                    template(v-for="tenant of getSharingTenants")
                      .choice(v-for="site of getSharingTenantsSites.filter(s => s.tenant.cloud_id === tenant.cloud_id)" @click.stop.prevent="changeSite(site.id)" :class="{active: siteId == site.id}") {{ $sites.nameToDisplay(site) }}
                template(v-else)
                  template(v-for="tenant of getSharingTenants")
                    .choice(v-for="site of getSharingTenantsSites.filter(s => s.tenant.cloud_id === tenant.cloud_id)" @click.stop.prevent="changeSite(site.id)" :class="{active: siteId == site.id}") {{ $sites.nameToDisplay(site) }}
              .choice(v-if="getTenants.length + getSharingTenants.length == 1" v-for="site of getSites" @click.stop.prevent="changeSite(site.id)" :class="{active: siteId == site.id}") {{ $sites.nameToDisplay(site) }}
              .choice.none(v-if="getSites.length == 0 && getSharingTenantsSites.length ==  0") {{ $t("noResult") }}
    li(v-if="!userIntegrator && $route.name != 'farm-comparison' && ($user.hasFeeding() || $user.hasZootech()) && $sites.sites.some(s => s.hasFeeding() || s.hasZootech())")
      .menu-link
        .icon.eva.eva-flag-outline
        .buttonGroup
          .buttonGroupButton(:class="{active: !$store.state.userGoals }" @click="$store.state.userGoals = false") {{ $t("sites") }}
          .buttonGroupButton(:class="{active: $store.state.userGoals }" @click="$store.state.userGoals = true") {{ $t("users") }}
    li(v-if="$size.currentSize <= $size.TABLET")
      .menu-link(tabindex="0" ref="reports" @click.stop="showUserItems = !showUserItems" @keyup.enter="showUserItems = !showUserItems"
        @mouseover="hover = 'reports'" @mouseleave="hover = false")
        .icon
          .user {{ $user.initials() }}
        .text {{ $user.userName() }}
        .action
          .eva(:class="showUserItems ? 'eva-arrow-ios-upward' : 'eva-arrow-ios-downward'")
    li(v-if="showUserItems && $size.currentSize <= $size.TABLET")
      ul(:class="{listFavorites: !$store.state.menuOpened}" ref="userItemsList")
        li(v-if="$user.isAdmin() && $sites.tenants.some(t => t.ltrack_access) && $user.hasSilo()")
          router-link.menu-link.sub-link(:to="{ name: 'l-track-subscriptions', params: {breadcrumbs: $route.name} }")
            .icon.eva.eva-award-outline
            .text {{ $t('settings.manager.lTrackSubscriptions') }}
        li(v-if="$user.isAdmin()")
          router-link.menu-link.sub-link(:to="{ name: 'sites-manager', params: {breadcrumbs: $route.name} }")
            .icon.eva.eva-home-outline
            .text {{ $t('settings.manager.sites') }}
        li(v-if="$user.isAdmin()")
          router-link.menu-link.sub-link(:to="{ name: 'users-manager', params: {breadcrumbs: $route.name} }")
            .icon.eva.eva-people-outline
            .text {{ $t('settings.manager.users') }}
        li(v-if="$user.isAdmin()")
          router-link.menu-link.sub-link(:to="{ name: 'dictionary-manager', params: {breadcrumbs: $route.name} }")
            .icon.eva.eva-book-open-outline
            .text {{ $t('settings.manager.dictionary') }}
        li(v-if="$user.role !== 'integrator'")
          router-link.menu-link.sub-link(:to="{ name: 'objectives', params: {breadcrumbs: $route.name} }")
            .icon.eva.eva-flag-outline
            .text {{ $t('objectives.title') }}
        li
          router-link.menu-link.sub-link(:to="{ name: 'settings', params: {breadcrumbs: $route.name} }")
            .icon.eva.eva-settings-outline
            .text {{ $t('settings.title') }}
        li(v-if="$user.role !== 'integrator'")
          router-link.menu-link.sub-link(:to="{ name: 'help', params: {breadcrumbs: $route.name} }")
            .icon.eva.eva-book-outline
            .text {{ $t('help.title') }}
    li(v-if="$size.currentSize <= $size.TABLET")
      router-link.menu-link(:to="{ name: 'logout', params: {breadcrumbs: $route.name} }")
        .icon.eva.eva-log-out-outline
        .text {{ $t('logout') }}
  router-link.menu-link(v-if="!userIntegrator && $size.currentSize > $size.TABLET" :to="{ name: 'help', params: {breadcrumbs: $route.name} }" :style="{bottom: '50px'}")
    .icon.eva.eva-book-outline
    .text {{ $t('help.title') }}
  .menu-link.disabled(v-if="$store.state.menuOpened")
    component(:is="{template: $t('infoPolicy')}")
  .menu-link.disabled(tabindex="0" @click="goToWebsite" @keyup.enter="goToWebsite")
    cloud-icon.icon(name="logo")
    .text.copyright © Copyright {{ new Date().getFullYear() }} | Jyga Technologies inc.<br>{{ $t('allRights')}}
</template>

<script>
import _ from "lodash-es";
import Draggable from "vuedraggable";
import { prefVisible } from "@/functions/preferences";
import RequestHelper from "@/functions/RequestHelper";

export default {
  name: "cloud-menu",
  data() {
    return {
      newFavorite: "",
      showFav: false,
      drag: false,
      siteId: this.$sites.currentSite ? this.$sites.currentSite.id : null,
      listFavorites: [],
      favKeys: [],
      diffMinuteCond: 0,
      lastActiveAt: "",
      showReports: false,
      showUserItems: false,
      hover: false,
      showSites: false,
      filteredSites: "",
      timeout: null
    };
  },
  components: {
    Draggable
  },
  computed: {
    favorites() {
      return this.$user.getPreference("favorites");
    },
    sites() {
      return this.$user.role !== "integrator" ? this.$sites.sites : [];
    },
    memberships() {
      return this.$store.state.general.memberships;
    },
    currentSiteId() {
      return this.$sites.currentSite ? this.$sites.currentSite.id : null;
    },
    userIntegrator() {
      return this.$user.role === "integrator";
    },
    dragOptions() {
      return {
        animation: 200,
        group: "menuFav",
        disabled: !this.$store.state.menuOpened,
        ghostClass: "ghost"
      };
    },
    straightString() {
      return string =>
        string
          .toString()
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
    },
    getSharingTenants() {
      return this.$sites.tenants
        .filter(
          c =>
            this.$store.state.general.memberships.some(
              m => m.tenant.cloud_id == c.cloud_id && m.use_sharing_name
            ) &&
            String(c.cloud_id).length > 0 &&
            (this.filteredSites.length == 0 ||
              this.straightString(String(c.name)).indexOf(
                this.straightString(this.filteredSites)
              ) > -1 ||
              this.getSites.find(x => x.tenant.cloud_id == c.cloud_id))
        )
        .sort((a, b) => {
          return String(a).toLowerCase().localeCompare(String(b).toLowerCase());
        });
    },
    getTenants() {
      return this.$sites.tenants
        .filter(
          c =>
            !this.$store.state.general.memberships.some(
              m => m.tenant.cloud_id == c.cloud_id && m.use_sharing_name
            ) &&
            String(c.cloud_id).length > 0 &&
            (this.filteredSites.length == 0 ||
              this.straightString(String(c.name)).indexOf(
                this.straightString(this.filteredSites)
              ) > -1 ||
              this.getSites.find(x => x.tenant.cloud_id == c.cloud_id))
        )
        .sort((a, b) => {
          return String(a).toLowerCase().localeCompare(String(b).toLowerCase());
        });
    },
    getSites() {
      return this.sites
        .filter(
          c =>
            !this.getSharingTenants.some(
              t => t.cloud_id == c.tenant.cloud_id
            ) &&
            String(c.id).length > 0 &&
            (this.filteredSites.length == 0 ||
              this.straightString(String(c.name)).indexOf(
                this.straightString(this.filteredSites)
              ) > -1 ||
              this.straightString(String(c.tenant.name)).indexOf(
                this.straightString(this.filteredSites)
              ) > -1)
        )
        .sort((a, b) => {
          return String(a).toLowerCase().localeCompare(String(b).toLowerCase());
        });
    },
    getSharingTenantsSites() {
      return this.sites
        .filter(
          c =>
            this.getSharingTenants.some(t => t.cloud_id == c.tenant.cloud_id) &&
            String(c.id).length > 0 &&
            (this.filteredSites.length == 0 ||
              this.straightString(String(c.name)).indexOf(
                this.straightString(this.filteredSites)
              ) > -1 ||
              this.straightString(String(c.tenant.name)).indexOf(
                this.straightString(this.filteredSites)
              ) > -1)
        )
        .sort((a, b) => {
          return String(a).toLowerCase().localeCompare(String(b).toLowerCase());
        });
    }
  },
  watch: {
    currentSiteId(newSite) {
      this.siteId = newSite;
      this.calledLastActive = false;
      if (this.$size.currentSize <= this.$size.TABLET) this.lastUpdate();
    },
    sites() {
      this.countMemberships();
    },
    memberships() {
      this.countMemberships();
    },
    favorites(newVal) {
      this.setListFavorites(newVal);
    },
    "$store.state.menuOpened"() {
      if (this.$size.currentSize <= this.$size.TABLET) this.lastUpdate();
    },
    hover(newVal, oldVal) {
      if ((newVal || oldVal) && !this.$store.state.menuOpened) {
        const value = newVal || oldVal;
        let html = this.$refs[value].$el || this.$refs[value];
        if (Array.isArray(html)) html = html[0].$el;
        const top = html.getBoundingClientRect().top;
        if (oldVal && !newVal) {
          html.style.top = null;
        } else if (newVal && !oldVal && !html.style.top) {
          html.style.top = top - this.$refs.menuScroll.scrollTop + "px";
        }
      }
    }
  },
  methods: {
    setListFavorites(object) {
      if (!object) {
        return {};
      }
      this.listFavorites = Object.keys(object).reduce(
        (a, b) => (
          (a[b] = {
            edit: false,
            name: b.replace(/-/g, " ")
          }),
          a
        ),
        {}
      );
      this.favKeys = Object.keys(object);
    },
    handleDrag(order) {
      const fav = _.sortBy(this.listFavorites, obj =>
        _.indexOf(order, obj)
      ).reduce((result, value, index) => {
        result[order[index]] = value;
        return result;
      }, {});
      if (
        JSON.stringify(order) !==
        JSON.stringify(Object.keys(this.listFavorites))
      ) {
        this.$user.savePreferences("favorites", fav, true);
        this.setListFavorites(JSON.parse(JSON.stringify(fav)));
      }
    },
    changePersonalGoals(value) {
      if (!window.location.href.includes("objectives")) {
        prefVisible("true");
      }
      this.$store.commit("setUserGoals", value);
      this.$user.savePreferences(
        "preferences.general.typeObjectives",
        this.$store.state.userGoals
      );
    },
    changeSite(siteId) {
      if (siteId != this.siteId) {
        this.$sites.changeSite(siteId);
      }
      this.$refs.input.blur();
      this.closeMenuOnMobile();
      this.hideSites();
    },
    countMemberships() {
      let sitesIn = "";
      let totalMemberships = 0;
      for (let i = 0; i < this.memberships.length; i++) {
        if (this.memberships[i].sites === "all") {
          totalMemberships = this.sites.length;
          break;
        }
        const arrSites = this.memberships[i].sites.split(",");
        for (let j = 0; j < arrSites.length; j++) {
          if (!sitesIn.includes(arrSites[j])) {
            const index = arrSites[j].indexOf('"');
            totalMemberships++;
            sitesIn = sitesIn.concat(
              arrSites[j].substring(index, arrSites[j].indexOf('"', index + 1))
            );
          }
        }
      }
      this.$store.commit("general/setTotalMemberships", totalMemberships);
    },
    addFavoritePage() {
      let favorites = this.$user.getPreference("favorites", {});
      favorites[this.newFavorite.replace(/ /g, "-")] = [];
      this.$user.savePreferences("favorites", favorites, true);
      this.newFavorite = "";
      this.setListFavorites(favorites);
    },
    removeFavoritePage(key) {
      let remove = confirm(
        "Do you really want to delete " + key.replaceAll("-", " ") + "?"
      );
      if (!remove) {
        return;
      }
      let favorites = this.$user.getPreference("favorites", {});
      delete favorites[key];
      this.$user.savePreferences("favorites", favorites, true);
    },
    renameFavoritePage(key) {
      this.listFavorites[key].edit = false;
      const myFavorites = this.$user.getPreference("favorites");
      if (key.replaceAll("-", " ") !== this.listFavorites[key].name) {
        myFavorites[this.listFavorites[key].name.replace(/ /g, "-")] =
          myFavorites[key];
        delete myFavorites[key];
        this.setListFavorites(myFavorites);
        this.$user.savePreferences("favorites", myFavorites, true);
      }
    },
    cancelEditFavPage(key) {
      this.listFavorites[key].edit = false;
      this.listFavorites[key].name = key.replace(/-/g, " ");
    },
    startEditFavPage(key) {
      for (const k in this.listFavorites) this.listFavorites[k].edit = false;
      this.listFavorites[key].edit = true;
      this.$nextTick(() => this.$refs[key][0].focus());
    },
    closeMenuOnMobile() {
      if (window.innerWidth <= 800) {
        this.$store.state.menuOpened = !this.$store.state.menuOpened;
      }
    },
    goToWebsite() {
      window.open("https://jygatech.com/", "_blank");
    },
    async lastUpdate() {
      if (!this.$sites.currentSite || !this.$sites.currentSite.id) {
        return;
      }

      this.calledLastActive = true;
      let data = await RequestHelper.get(
        `sites/${this.$sites.currentSite.id}/access-keys?`,
        {
          query: {
            max: "last_active_at"
          }
        }
      );
      const now = new Date(new Date().toISOString());
      const updated = new Date(
        !data.status && data.data[0] ? data.data[0].last_active_at : 0
      );
      if (!updated || !updated.getTime()) {
        this.lastActiveAt = this.$t("date.UpdatedAgoNever");
        this.diffMinuteCond = 60;
      } else {
        const diffMinute = Math.abs(
          Math.round((updated.getTime() - now.getTime()) / 1000 / 60)
        );
        let time = "";
        switch (true) {
          case diffMinute < 60: // less than an hour
            time = diffMinute + " min";
            break;
          case diffMinute < 1440: {
            // less than a day
            const hour = Math.floor(diffMinute / 60);
            const min = diffMinute % 60;
            time = min > 0 ? hour + " h " + min + " min" : hour + " h";
            break;
          }
          default: {
            // more than a day
            const days = Math.floor(diffMinute / 1440);
            const hour = Math.floor((diffMinute - days * 1440) / 60);
            time =
              hour > 0
                ? days === 1
                  ? `${days} ${this.$t("date.day").toLowerCase()} ${hour} h`
                  : `${days} ${this.$t("date.days").toLowerCase()} ${hour} h`
                : `${days} ${this.$t("date.days").toLowerCase()}`;
            break;
          }
        }
        if (diffMinute < 5) {
          this.lastActiveAt = this.$t("date.UpdatedAgoNow");
        } else {
          this.lastActiveAt = this.$t("date.UpdatedAgo", {
            time: time.toLowerCase()
          });
        }
        this.diffMinuteCond = diffMinute;
      }
    },
    focus() {
      this.$refs.input.focus();
      this.showSites = true;
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      setTimeout(() => {
        this.$refs.input.focus();
        this.showSites = true;
        if (this.timeout) {
          clearTimeout(this.timeout);
          this.timeout = null;
        }
      }, 0);
    },
    hideSites() {
      this.timeout = setTimeout(() => {
        this.showSites = false;
        this.filteredSites = "";
      }, 0);
    }
  },
  mounted() {
    this.setListFavorites(this.favorites);
    if (this.$size.currentSize <= this.$size.TABLET && !this.calledLastActive)
      this.lastUpdate();
  }
};
</script>

<style lang="stylus" scoped>
.marginLeftZero
  margin-left 0

ul, li
  padding 0
  margin 0
  list-style none
  min-height 50px
.menu
  width 50px
  background-color white
  box-shadow 0 2px 6px 2px rgba(0, 0, 0, 0.2)
  z-index 100
  flex-shrink 0
  transition width 0.3s
  display flex
  flex-direction column
  overflow-x hidden
  @media screen and (--max-tablette), print
    width 0
  &.opened
    width 260px
    @media print
      width 0
  &:not(.opened)
    .menu-link:hover:not(.disabled), .menu-link.hover:not(.disabled)
      position absolute
      -webkit-transform translateZ(0)
      z-index 1
    @media screen and (--max-tablette)
        .menu-link:hover:not(.disabled), .menu-link.hover:not(.disabled)
            display none
  .menuScroll
    flex-grow 1
    overflow-y auto
    overflow-x hidden
.menu-link
  width 260px
  min-height 50px
  display flex
  align-items center
  color #666
  fill var(--blue)
  stroke var(--blue)
  border-radius 0
  padding 0
  cursor pointer
  flex-shrink 0
  &.favorites-popper
    height auto
    flex-direction column
  &:hover:not(.disabled), &.hover:not(.disabled)
    background-color lighten(#0747a6, 90%)
    color #333
  &.router-link-exact-active:focus
    outline var(--focus-outline)
  &:focus:not(.disabled):not(.router-link-exact-active)
    outline none
  &.router-link-exact-active, &.router-link-exact-active:hover
    background-color lighten(#0747a6, 75%)
    color #333
  &.disabled .icon
    color lighten(#0747a6, 70%)
    stroke lighten(#0747a6, 70%)
    fill lighten(#0747a6, 70%)
  &.lastUpdate
    padding-left 10px
    pointer-events visible
    text-overflow ellipsis
    overflow hidden
    display -webkit-box
    -webkit-line-clamp 2
    -webkit-box-orient horizontal
    &--late
      color var(--red)
  .icon
    width 50px
    display flex
    align-items center
    justify-content center
    font-size 32px
    color #0747a6
    flex-shrink 0
    position relative
    &.nostroke
      stroke-width 0
    &.stretch
      transform scale(120%, 150%)
    .number
      font-size 12px
      font-weight bold
      color white
      position absolute
      top 50%
      left 50%
      transform translate(-50%, -50%)
    .user
      width 32px
      height 32px
      border-radius 100%
      outline 3px solid var(--blue)
      color var(--blue)
      text-align center
      line-height 32px
      font-size 14px
      font-weight bold
      margin 10px 0 10px
  .text
    font-size 20px
    line-height 1
    display flex
    align-items center
    padding-right 10px
    flex-grow 1
    &-ellipsis
      display -webkit-box
      word-wrap break-word
      -webkit-line-clamp 2
      -webkit-box-orient vertical
      overflow hidden
      text-overflow ellipsis
    input
      border 1px solid var(--blue)
      padding 0.3rem 0.2rem
      flex-grow 1
      width 10px
      margin-right -10px
    &.copyright
      font-size 10px
      line-height 1.2
      text-align center
      color #aaa
      display block
      & >>> a
        text-decoration underline
        display inline-block
        margin-left 10px
        &:link, &:visited, &:hover, &:focus, &:active
          color #aaa
  .action
    display flex
    align-items center
    font-size 24px
    padding 0 10px
    color #0747a6
    background none
    border none
    cursor pointer
    outline inherit
    &:hover, &:active, &:focus
      box-shadow none !important

.sub-link
  position relative
  &:before
    content ""
    background-color var(--blue)
    position absolute
    left 0
    width 5px
    top 0
    bottom 0
  &:hover .edit
    visibility visible
  .edit
    visibility hidden

.buttonGroup
  margin-right 10px

.listFavorites
  flex-direction column
  display flex
  flex-shrink 0
  overflow-x hidden

.multiselect
  border 1px solid var(--blue)
  border-radius 3px
  position relative
  cursor text
  padding 2px 25px 2px 10px
  width 200px
  &.showChoices
    border-bottom-left-radius 0
    border-bottom-right-radius 0
  &:not(.disabled):after
    content "\ea6b"
    font-family Eva-Icons
    position absolute
    right 3px
    top 50%
    transform translateY(-50%)
    pointer-events none
    color #666
  &.disabled
    padding-right 5px
    cursor auto
    pointer-events none
  input
    background-color transparent
    margin 0
    padding 0
    height 26px
    line-height 26px
    font-size 16px
    position absolute
    top 2px
    left 10px
    width calc(100% - 35px)
    color #666
    &:focus
      background-color lighten(#0747a6, 90%)
  .choices
    display none
    position absolute
    background-color lighten(#0747a6, 90%)
    width calc(100% + 2px)
    left -1px
    top 100%
    border 1px solid
    border-top none
    border-color var(--blue)
    max-height 200px
    z-index 1
    border-bottom-left-radius 3px
    border-bottom-right-radius 3px
    &.show
      display block
    .optgroup
      padding 2px 4px
      span
        opacity .5
      .choice
        padding-left 8px
    .choice
      padding 2px 4px
      cursor pointer
      &.none
        cursor auto
      &.active:before
        content "\2713"
        font-size 10px
        margin-right 5px
        vertical-align middle
    .scroll
      max-height 200px
      overflow-y auto
  .selected
    min-height 26px
    max-width 100%
    white-space nowrap
    font-size 16px

.cloud.dark
  .text input
    border none
  .sub-link:before
    background-color lighten(#313f52, 20%)
  .icon .user
    outline-color rgba(255, 255, 255, 0.6)
    color rgba(255, 255, 255, 0.6)
  .menu-link.lastUpdate--late
    color var(--dark-mode-red)
  .multiselect
    &:after
      color white
    input
      color white
      &:focus
        background-color var(--dark-BG-light)
  .choices
    background-color #313f52

.tooltip
  height 50px
  display flex
  align-items center
  background-color lighten(#0747a6, 90%)
  padding 5px 10px
</style>

<style lang="stylus">
.menu-link .icon svg
  width 24px
</style>
