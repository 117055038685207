<template lang="pug">
  div
    h1 Privacy policy
    p Your privacy is important to us. We want you to understand how we safeguard your personal information and what
      | &nbsp;types of information we receive and collect when you use this application. This privacy policy describes our
      | &nbsp;practices that we or our service providers collect through the web application “Vision”, operated and controlled
      | &nbsp;by LES LOGICIELS GESTAL INC. or its affiliates (“GESTAL”), through which you are accessing this privacy policy
      | &nbsp;or if you contact our customer care team. If local laws in your country require that we process your personal
      | &nbsp;data in a different way, we will comply with those local laws. Please read this entire privacy policy before
      | &nbsp;using this application.

    section
      h2 Personal information and online security
      p The term “personal information” includes any individually identifiable information about you that we may collect
        | &nbsp;from you online, such as your name, address, email address or telephone number, or other data that can be reasonably
        | &nbsp;linked to such information by GESTAL, such as information we associate with your account (username and password).
      p When you visit or use a GESTAL’s application, you are anonymous unless you choose to identify yourself. You are
        | &nbsp;not required to provide personal information to use our application, unless a feature requires it. For example,
        | &nbsp;if you have a contract with us and wish to access your account online, you will need to provide identifying
        | &nbsp;information or when you create an account, you provide us with personal information that includes your email
        | &nbsp;and a password. You can also choose to add a phone number, your first and last name to your account. Even if you
        | &nbsp;aren’t signed in to an account, you might choose to provide us with information — like an email address or a
        | &nbsp;telephone number to receive updates about our services and about the computer software. In these cases, we have
        | &nbsp;physical, electronic and procedural safeguards in place to help protect your online personal information from
        | &nbsp;unauthorized use or improper access. These safeguards may include:
      p Passwords and personal identification numbers (PINs): Clients can access their account information online only
        | &nbsp;after providing identifying information and a password or PIN. This personal information is unique to you, and
        | &nbsp;is an important first step in protecting your information online.
      p 128-bit encryption: GESTAL uses a Secure Sockets Layer (SSL) protocol to protect personal information transmitted
        | &nbsp;to us electronically. SSL transmitted information is encrypted, or scrambled, to prevent unauthorized reading
        | &nbsp;of the electronic communication. If we do detect something risky that we think you should know about, we’ll
        | &nbsp;notify you and help guide you through steps to stay better protected.
      p Although we have physical, electronic and procedural safeguards that we believe are adequate to protect personal
        | &nbsp;information, any such systems may be subject to human and operational error. In particular, because the Internet
        | &nbsp;is an open system, we cannot and do not guarantee that personal information entered by you online will not be
        | &nbsp;misappropriated, intercepted, modified, deleted or used by others.
      p If you think that any personal information we have collected about you is inaccurate, you may send a written
        | &nbsp;request to us at the following address: GESTAL, 175 rue Damase-Breton Saint-Lambert-de-Lauzon (Québec) G0S 2W0,
        | &nbsp;Canada or by email at&nbsp;
        a(href="mailto:support@jygatech.com") support@jygatech.com
        | . We will review your request and make appropriate changes if warranted.
      p You may choose not to provide your personal information through GESTAL’s application and use an alternative
        | &nbsp;form of communication such as regular mail.

    section
      h2 Online data collection tools: To improve and evaluate application functionality and effectiveness
      p GESTAL, like many organizations, sometimes gathers data such as : your IP address (regarding how visitors explore
        | &nbsp;our application), information about the number of new and repeated visitors, where on the application a visitor
        | &nbsp;explores, how long they visit, where they are geographically located and similar details. GESTAL may also track
        | &nbsp;how many users visit advertisements promoting our products and services that appear on other company applications.
        | &nbsp;All such information is collected in an unidentifiable form and is used to improve our application’s performance
        | &nbsp;and functionality, create a better experience for our visitors, measure the response to web marketing campaigns and
        | &nbsp;provide you with more relevant content based on your possible interests in GESTAL’s content, products and services.
      p GESTAL or its agents on our behalf may collect this information through the use of cookies, local storage (
        a(href="https://javascript.info/localstorage") https://javascript.info/localstorage
        | ), electronic images known as web beacons or any other available means that the technology allows. Although
        | &nbsp;rules differ from different countries to another, GESTAL’s application implements technologies that respects
        | &nbsp;Canada, US and Europe regulations.
      p All the information collected by any of these online tracking tools is non-personal and is not used in connection
        | &nbsp;with any other information that could identify you personally unless you have entered your access ID and
        | &nbsp;password or provided it to us.

    section
      h2 Control
      p If you are a registered user of our application, you can view or update your registration information by logging
        | &nbsp;into your account or request GESTAL to do so.
      p Any personal information as an email or a username provided to create a profile may be modified by GESTAL only.
      p You can modify any password, name, telephone number you have provided in order to create an account in the profile
        | &nbsp;page. The password can also be modified by GESTAL and any account can be deleted by GESTAL on request.
      p We strive to keep accurate information by using technology and management processes and policies. Upon receipt
        | &nbsp;of a written request, GESTAL will provide you with reasonable access to the personal information, you have
        | &nbsp;provided to GESTAL as well as the ability to correct the information, if necessary. To protect your privacy
        | &nbsp;and security, GESTAL will also take reasonable steps to verify your identity before releasing any information
        | &nbsp;to the requesting party and before any corrections will be made. GESTAL may request appropriate proof of the
        | &nbsp;requested corrections.
      p To get any assistance or to request some information, you can contact the support team at&nbsp;
        a(href="mailto:support@jygatech.com") support@jygatech.com
        | .

    section
      h2 Communication
      p We use information we collect, like your email address or phone number, to interact with you directly. We may let
        | &nbsp;you know about upcoming changes or improvements to our services, our products, our computer software or our
        | &nbsp;affiliates. And if you contact us, we’ll keep a record of your request in order to help solve any issues you
        | &nbsp;might be facing.

    section
      h2 Services
      p We collect and use personal information to fulfil orders, to process returns and for customer care purposes.

    section
      h2 When GESTAL shares your information
      p We do not share your personal information with companies, organizations, or individuals outside of GESTAL except
        | &nbsp;in the following cases:
      p We may share the site visit information obtained with Google Analytics or other agents to analyze online activity
        | &nbsp;on our application or in reaction to our online advertisements.
      p We’ll share personal information outside of GESTAL to your affiliates or anytime when we have your consent.
      p We also share personal data to our affiliates in order to provide you adequate technical support, to complete
        | &nbsp;invoices, to improve our products and services, including improving store layouts and digital content.
      p We provide personal information to our affiliates and other trusted businesses or persons to process it for us,
        | &nbsp;based on our instructions and in compliance with our Privacy Policy and any other appropriate confidentiality
        | &nbsp;and security measures.
      p We will share personal information outside of GESTAL if we have a good-faith belief that access, use, preservation,
        | &nbsp;or disclosure of the information is reasonably necessary to:
      ul
        li Meet any applicable law, regulation, legal process, or enforceable governmental request. We share information
          | &nbsp;about the requests we receive from governments in order to comply with the law.
        li Enforce applicable terms of contracts, including investigation of potential violations.
        li Detect, prevent, or otherwise address fraud, security, or technical issues.
        li Protect against harm to the rights, property or safety of GESTAL, our users, or the public as required or
          | &nbsp;permitted by law.
      p We may share non-personally identifiable information publicly and with our partners — like publishers, advertisers,
        | &nbsp;developers, or rights holders. For example, we share information publicly to show trends about the general use
        | &nbsp;of our services. We also allow specific partners to collect information from your browser or device for advertising
        | &nbsp;and measurement purposes using their own cookies or similar technologies.
      p If GESTAL is involved in a merger, acquisition, or sale of assets, we’ll continue to ensure the confidentiality
        | &nbsp;of your personal information and give affected users notice before personal information is transferred or
        | &nbsp;becomes subject to a different privacy policy.

    section
      h2 Links to websites outside of GESTAL
      p To provide website visitors with more information that may be helpful to them, some of our application may contain
        | &nbsp;links to other websites that may not be affiliated with or maintained by GESTAL. We do not monitor the privacy
        | &nbsp;practices of these third-party sites, and do not have authority over them. As a result, we do not assume any
        | &nbsp;responsibility for the data collection policies and procedures of these sites.
</template>

<script>
export default {
  name: "Policy",
  mounted() {
    const body = document.getElementsByTagName("body")[0];
    body.style.overflow = "auto";
    body.style.backgroundColor = "white";
  },
  destroyed() {
    const body = document.getElementsByTagName("body")[0];
    body.style.overflow = null;
    body.style.backgroundColor = null;
  }
};
</script>

<style lang="stylus" scoped>
div
  max-width 1024px
  padding 0 12px
  margin 0 auto
h1
  position sticky
  top 0
  background-color white
  margin 0 0 -16px
  padding 20px 0
p, li
  text-align justify
  a
    color var(--black)
    text-decoration underline

@media not print
  .dark
    h1
      background-color var(--dark-SB)
    a
      color var(--athens-gray)
</style>
