<template lang="pug">
router-view(v-if="$user.loaded && $sites.loaded")
transition(name="fade" :duration="300" v-else)
  .overlay
    .loading
      img.logo(v-lazy="'img/LogoVisionx2.png'")
      .spinner
        cloud-icon.icon.icon__spinner(name="spinner")
</template>

<script>
import $ from "jquery";

export default {
  name: "app",
  methods: {
    changeGraphTextColor() {
      const prefTheme = this.$user.getPreference("preferences.general.theme");
      const light =
        document.documentElement.style.getPropertyValue("--athens-gray");
      const theme =
        prefTheme && prefTheme !== ""
          ? prefTheme === "Light"
            ? "#0e1624"
            : light
          : window.matchMedia("(prefers-color-scheme: dark)").matches
          ? light
          : "#0e1624";
      Chart.defaults.global.defaultFontColor = theme;
      Chart.defaults.global.legend.labels.fontColor = theme;
    },
    setLightColorsConstants() {
      const element = document.documentElement;
      if (navigator.appVersion.indexOf("Win") >= 0) {
        element.style.setProperty("--light-gray", "#d7dae0");
        element.style.setProperty("--athens-gray", "#e8ebef");
      } else {
        element.style.setProperty("--light-gray", "#ebeced");
        element.style.setProperty("--athens-gray", "#f4f5f7");
      }
    }
  },
  async mounted() {
    await this.$user.loadFromApi();
    if (this.$user.role === "staff") {
      await this.$sites.loadStaffFromApi();
    } else {
      await this.$sites.loadFromApi();
    }

    this.changeGraphTextColor();

    const jquery = $("body:has(.dark)");
    jquery.addClass("dark-body");
    if (jquery.length > 0) {
      $("meta[name=theme-color]").remove();
      $("head").append('<meta name="theme-color" content="#10151C" />');
    } else {
      $("meta[name=theme-color]").remove();
      $("head").append('<meta name="theme-color" content="#0747a6" />');
    }
  },
  created() {
    this.setLightColorsConstants();
  }
};
</script>

<style lang="stylus" scoped>
.overlay
  background-color var(--blue)
  position fixed
  top 0
  left 0
  bottom 0
  right 0
  z-index 999
  display flex
  align-items center
  justify-content center
  padding 20px
  .logo
    max-width 100%
  .spinner
    width 24px
    stroke var(--white)
    margin 20px auto 0
</style>

<style lang="stylus">
@import "assets/css/normalize.styl";
@import "assets/css/material.styl";
@import "assets/css/popper.styl";
</style>
