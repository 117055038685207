<template lang="pug">
  div
    h1 Politique de confidentialité
    p Nous accordons une grande importance à la confidentialité de vos renseignements personnels. Nous tenons à vous
      | &nbsp;expliquer la façon dont nous assurons la protection de vous renseignements personnels ainsi que les types
      | &nbsp;de renseignements que nous recevons et recueillons lorsque vous utilisez l’application. La présente politique
      | &nbsp; de confidentialité décrit nos pratiques et celles de nos fournisseurs de services en matière de cueillette
      | &nbsp;de renseignements par le biais de l’application Web « Vision » exploitée et contrôlée par LES LOGICIELS
      | &nbsp;GESTAL INC. ou sa société affiliée (« GESTAL »), par laquelle vous accédez à la présente politique
      | &nbsp;de confidentialité ou si vous communiquez avec notre équipe de service à la clientèle. Nous nous conformerons
      | &nbsp;aux lois de votre pays si celles-ci requièrent que nous traitions vos données personnelles d’une manière
      | &nbsp;différente. Veuillez lire attentivement la politique complète avant d’utiliser l’application.

    section
      h2 Renseignements personnels et sécurité en ligne
      p L’expression « renseignements personnels » comprend, sans s’y limiter, tout renseignement permettant de
        | &nbsp;vous identifier que vous pouvez nous transmettre en ligne, comme votre nom, votre adresse, votre numéro de
        | &nbsp;téléphone ou toute autre information qui peut être raisonnablement liée à de tels renseignements par GESTAL,
        | &nbsp;comme les renseignements liés à votre compte (nom d’utilisateur et mot de passe).
      p Lorsque vous vous rendez sur le site d’une application de GESTAL ou que vous utilisez une telle application, votre
        | &nbsp;anonymat est assuré à moins que vous décidiez de vous identifier. Vous n’avez pas l’obligation de fournir
        | &nbsp;des renseignements personnels pour utiliser notre application, à moins qu’une fonction ne le requière. Par
        | &nbsp;exemple, si vous êtes lié par contrat et que vous souhaitez accéder à votre compte en ligne, vous devrez
        | &nbsp;fournir des identifiants; lorsque vous créez un compte, vous devez nous fournir des renseignements personnels,
        | &nbsp;notamment votre adresse électronique et un mot de passe. Vous pouvez également ajouter un numéro de téléphone
        | &nbsp;et votre nom complet à votre compte. Même si vous n’êtes pas lié à un compte, vous pouvez nous transmettre
        | &nbsp;des renseignements, comme une adresse électronique ou un numéro de téléphone afin de recevoir des mises à
        | &nbsp;jour sur nos services et notre logiciel. Dans un tel cas, nous disposons de dispositifs de protection physiques,
        | &nbsp;électroniques et administratifs afin de protéger vos renseignements personnels en ligne contre une utilisation
        | &nbsp;non autorisée ou un accès inapproprié. Ces dispositifs de protection peuvent comprendre, sans s’y limiter,
        | &nbsp;ce qui suit :
      p Des mots de passe et des numéros d’identification personnelle (NIP) : Les clients peuvent accéder aux renseignements
        | &nbsp;de leur compte en ligne seulement après avoir transmis des identifiants et un NIP. Ces renseignements personnels
        | &nbsp;vous sont propres et constituent la première étape essentielle à la protection de vos renseignements en ligne.
      p Chiffrement à 128 bits : GESTAL utilise un protocole SSL (Secure Sockets Layer) pour protéger les renseignements
        | &nbsp;personnels qui nous sont transmis en format électronique. Les renseignements transmis par le protocole SSL
        | &nbsp;sont chiffrés, ou brouillés, afin d’empêcher la lecture non autorisée de toute communication électronique.
        | &nbsp;Si nous constatons un risque lié à la sécurité de vos renseignements que vous devriez connaître, nous vous
        | &nbsp;en aviserons et vous aiderons à prendre les mesures qui vous assureront une meilleure protection.
      p Même si nous disposons de dispositifs de protection physiques, électroniques et administratifs que nous croyons
        | &nbsp;adéquats pour assurer la protection de vos renseignements personnels, un tel système peut être sujet à une
        | &nbsp;erreur humaine ou opérationnelle, particulièrement dans le cas d’Internet qui est un système ouvert. Nous
        | &nbsp;ne pouvons donc pas garantir que les renseignements que vous saisissez en ligne ne feront pas l’objet d’une
        | &nbsp;appropriation, d’un vol, d’une modification, d’une suppression ou d’une utilisation inappropriée par des tiers.
      p Si vous croyez que des renseignements personnels recueillis vous concernant sont inexacts, vous pouvez nous transmettre
        | &nbsp;une demande par écrit à l’adresse suivante : GESTAL, 175 rue Damase-Breton Saint-Lambert-de-Lauzon
        | &nbsp;(Québec) G0S 2W0, Canada ou par courriel à&nbsp;
        a(href="mailto:support@jygatech.com") support@jygatech.com
        | . Nous examinerons votre demande et nous apporterons les modifications appropriées, le cas échéant.
      p Vous pouvez choisir de transmettre vos renseignements personnels par le biais de l’application de GESTAL ou par
        | &nbsp;un autre moyen de communication, comme le courrier.

    section
      h2 Outils de cueillette de données en ligne : Améliorer et évaluer l’efficacité et la fonctionnalité de l’application
      p GESTAL, comme de nombreuses organisations, recueille parfois les renseignements suivants : votre adresse IP
        | &nbsp;(concernant la façon dont les visiteurs examinent notre application), des renseignements sur le nombre de
        | &nbsp;nouveaux visiteurs et de visiteurs récurrents, le contenu que le visiteur examine, la durée de la visite,
        | &nbsp;où le visiteur se trouve et autres renseignements similaires. GESTAL peut également faire le suivi du nombre
        | &nbsp;d’utilisateurs qui consultent les publicités qui font la promotion de nos produits et services qui s’affichent
        | &nbsp;sur les applications d’autres entreprises. Ces renseignements sont recueillis dans un formulaire anonyme
        | &nbsp;et servent à améliorer la performance et la fonctionnalité de notre application, à rendre l’expérience plus
        | &nbsp;agréable pour nos visiteurs, à évaluer la réponse aux campagnes de marketing Web et à vous présenter un
        | &nbsp;contenu pertinent en fonction de votre intérêt envers le contenu, les produits et les services de GESTAL.
      p GESTAL, ou un de ses représentants, peut, en notre nom, recueillir ces renseignements au moyen de l’utilisation
        | &nbsp;de témoins et de stockage local (
        a(href="https://javascript.info/localstorage") https://javascript.info/localstorage
        | ), d’images électroniques connues sous le nom de pixels espions ou tout autre moyen permis par la technologie.
        | &nbsp;Même si les règles changent d’un pays à l’autre, l’application de GESTAL met en place des technologies
        | &nbsp;conformes aux réglementations en vigueur au Canada, aux États-Unis et en Europe.
      p Tous les renseignements recueillis par l’un de ces outils de suivi en ligne ne sont pas personnels et ne sont pas
        | &nbsp;utilisés conjointement avec d’autres renseignements qui pourraient vous identifier à moins que vous n’ayez
        | &nbsp;saisi votre ID d’accès et votre mot de passe ou que vous nous ayez transmis ces renseignements.

    section
      h2 Contrôle
      p Si vous êtes un utilisateur inscrit sur notre application, vous pouvez visualiser ou mettre à jour vos renseignements
        | &nbsp;d’inscription en vous connectant à votre compte ou demander à GESTAL de le faire pour vous.
      p Seul GESTAL peut modifier les renseignements personnels comme un courriel ou un nom d’utilisateur fournis pour créer un profil.
      p Vous pouvez modifier un mot de passe, un nom et un numéro de téléphone que vous avez fournis afin de créer un compte
        | &nbsp;dans la page de profil. GESTAL peut également modifier le mot de passe et supprimer un compte sur demande.
      p Nous nous efforçons de conserver des renseignements exacts au moyen de la technologie et de politiques et de processus
        | &nbsp;de gestion. À la réception d’une demande écrite, GESTAL vous accordera un accès raisonnable aux renseignements
        | &nbsp;personnels que vous avez transmis à GESTAL ainsi que la possibilité de corriger ces renseignements, le cas
        | &nbsp;échéant. GESTAL prendra également les mesures nécessaires pour vérifier votre identité avant de diffuser
        | &nbsp;ces renseignements aux parties demanderesses, et ce, avant d’effectuer des modifications afin d’assurer la
        | &nbsp;sécurité et la confidentialité de vos renseignements. GESTAL peut raisonnablement demander une preuve des
        | &nbsp;modifications à apporter.
      p Pour obtenir de l’aide ou pour toute demande de renseignements, vous pouvez communiquer avec l’équipe du soutien
        | &nbsp;technique par courriel à l’adresse suivante :
        a(href="mailto:support@jygatech.com") support@jygatech.com
        | .

    section
      h2 Communication
      p Nous utilisons les renseignements que nous recueillons, comme votre adresse électronique ou votre numéro de téléphone
        | &nbsp;pour interagir avec vous directement. Nous pouvons ainsi vous informer des modifications ou améliorations
        | &nbsp;futures de nos services, nos produits, notre logiciel ou nos sociétés affiliées. Si vous communiquez avec
        | &nbsp;nous, nous conserverons un dossier de votre demande pour vous aider à résoudre tout problème auquel vous
        | &nbsp;pourriez être confronté.

    section
      h2 Services
      p Nous recueillons et utilisons vos renseignements personnels pour remplir des commandes, traiter les retours, et
        | &nbsp;aux fins de service à la clientèle.

    section
      h2 Partage de vos renseignements par GESTAL
      p Nous ne partageons pas vos renseignements personnels avec des compagnies, des organisations ou des individus,
        | &nbsp;autre que GESTAL, sauf dans les cas suivants :
      p Nous pouvons partager les renseignements relatifs à la visite du site obtenus par le biais de Google Analytics ou
        | &nbsp;autres organismes pour analyser l’activité en ligne sur notre application ou en réponse à nos publicités en ligne.
      p Nous transmettrons vos renseignements personnels à vos filiales, ainsi qu’à d’autres parties que Gestal, lorsque vous y consentirez.
      p Nous partagerons également des données personnelles avec nos sociétés affiliées afin de vous offrir un soutien technique
        | &nbsp;adéquat, d’émettre des factures, d’améliorer nos produits et services, y compris notre contenu numérique.
      p Nous fournissons des renseignements personnels à nos sociétés affiliées ainsi qu’à d’autres entreprises et personnes
        | &nbsp;de confiance afin que celles-ci traitent les renseignements en notre nom selon nos directives et en conformité
        | &nbsp;avec notre politique de confidentialité et toute autre mesure de sécurité et de confidentialité jugée appropriée.
      p Nous partagerons les renseignements personnels, à d’autres parties que GESTAL, si nous avons une confiance absolue
        | &nbsp;que l’accès, l’utilisation, la conservation ou la divulgation de ces renseignements est raisonnablement
        | &nbsp;nécessaire aux fins suivantes :
      ul
        li Respecter les lois, les règlements, les processus juridiques ou une demande exécutoire du gouvernement. Nous
          | &nbsp;partageons les renseignements concernant les demandes que nous recevons du gouvernement afin de respecter la loi.
        li Appliquer les modalités des contrats, y compris mener des enquêtes sur des manquements possibles.
        li Détecter, prévenir ou résoudre des problèmes liés à des fraudes, à la sécurité et d’ordre technique.
        li Protéger contre l’atteinte aux droits, aux biens ou à la sécurité de GESTAL, de nos utilisateurs ou du public
          | &nbsp;comme l’exige ou le permet la loi.
      p Nous pouvons partager des renseignements non personnels permettant votre identification avec le public et avec nos
        | &nbsp;partenaires, comme les éditeurs, les annonceurs, les développeurs et les détenteurs de droit. Par exemple,
        | &nbsp;nous partageons publiquement des renseignements pour montrer l’utilisation générale de nos services. Nous
        | &nbsp;permettons également à certains de nos partenaires de recueillir des renseignements à partir de votre navigateur
        | &nbsp;ou de votre appareil à des fins de publicité et d’évaluation au moyen de leurs propres témoins ou de technologies similaires.
      p Si GESTAL participe à une fusion, à une acquisition ou à une vente d’actifs, nous continuerons d’assurer la confidentialité
        | &nbsp;de vos renseignements personnels et d’aviser les utilisateurs touchés avant de transférer leurs renseignements
        | &nbsp;personnels avant qu’ils ne soient soumis à une autre politique de confidentialité.

    section
      h2 Liens vers des sites Web autres que GESTAL
      p Afin de fournir plus de renseignements utiles aux visiteurs du site Web, notre application peut contenir des liens
        | &nbsp;vers d’autres sites Web qui ne sont pas associés ou maintenus par GESTAL. Nous ne vérifions pas les pratiques
        | &nbsp;de confidentialité de ces sites tiers et n’avons pas autorité sur eux. Par conséquent, nous n’assumons aucune
        | &nbsp;responsabilité en ce qui a trait aux politiques de cueillette de renseignements de ces sites.
</template>

<script>
export default {
  name: "Polique",
  mounted() {
    const body = document.getElementsByTagName("body")[0];
    body.style.overflow = "auto";
    body.style.backgroundColor = "white";
  },
  destroyed() {
    const body = document.getElementsByTagName("body")[0];
    body.style.overflow = null;
    body.style.backgroundColor = null;
  }
};
</script>

<style lang="stylus" scoped>
div
  max-width 1024px
  padding 0 12px
  margin 0 auto
h1
  position sticky
  top 0
  background-color white
  margin 0 0 -16px
  padding 20px 0
p, li
  text-align justify
  a
    color var(--black)
    text-decoration underline

@media not print
  .dark
    h1
      background-color var(--dark-SB)
    a
      color var(--athens-gray)
</style>
