let kgLb = "kg";

export function conversionTime(event) {
  let eTarget = event;
  let minutes = Math.floor(eTarget / 60);
  let seconds = eTarget - minutes * 60;
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  let temps = minutes + "m " + seconds;
  return temps;
}

export function hour24(event, prefs) {
  const time = typeof event === "string" ? event : event / 4;
  let hourFinal;
  let hour;
  let minute;
  if (typeof time === "number" && time % 1 === 0) {
    hourFinal = time.toString() + ":";
    hour = time;
  } else {
    const strTime = time.toString();
    minute =
      typeof event === "string"
        ? strTime.substr(strTime.indexOf(".") + 1, strTime.length)
        : strTime.substr(strTime.indexOf(".") + 1, 3);
    minute =
      typeof event === "string"
        ? Math.floor((minute * 100) / 60)
        : (minute * 60) / 100;
    if (minute.toString().length !== 2) {
      minute = minute + "0";
    }
    hour = parseInt(strTime.substr(0, strTime.indexOf(".")));
    hourFinal = hour + ":" + minute;
  }
  if (prefs !== undefined) {
    if (prefs === "12 hours") {
      if (parseFloat(hour) < 13) {
        if (minute !== undefined) {
          hourFinal = hour + ":" + minute + " AM";
        } else {
          if (!hourFinal.includes(":")) {
            hourFinal = hourFinal + ":" + minute + " AM";
          } else {
            hourFinal = hourFinal + "00" + " AM";
          }
        }
      } else {
        if (minute !== undefined) {
          hourFinal = parseFloat(hour) - 12 + ":" + minute + " PM";
        } else {
          hourFinal = parseFloat(hourFinal) - 12 + ":00" + " PM";
        }
      }
    }
    if (prefs === "24 hours") {
      if (minute === undefined) {
        hourFinal = hourFinal + "00";
      }
    }
  }
  if (parseFloat(hour) === 12) {
    if (hourFinal.includes("AM")) {
      hourFinal = hourFinal.replace("AM", "PM");
    } else if (hourFinal.includes("PM")) {
      hourFinal = hourFinal.replace("PM", "AM");
    }
  }
  return hourFinal;
}

export function recupererHour(event) {
  let temps = event.split("h");
  let minutesSecondes = temps[1].replace("m", ".");
  minutesSecondes = minutesSecondes.replace(/\s/g, "");
  minutesSecondes = minutesSecondes.split(".");
  minutesSecondes = minutesSecondes[0] * 60 + minutesSecondes[1];
  temps = temps[0] * 60 * 60;
  temps = parseFloat(temps) + parseFloat(minutesSecondes);
  return temps;
}

export function recupererMinutes(event) {
  let temps = event.split("m");
  if (temps[1] !== "") {
    temps = parseFloat(temps[0] * 60) + parseFloat(temps[1]);
  } else {
    temps = parseFloat(temps[0] * 60);
  }
  temps = parseFloat(temps);
  return temps;
}

export function conversionPourcent(event) {
  let eTarget;
  let test = parseFloat(event).toFixed(2);
  test = test.split(".");
  if (test[1] && test[1].toString() === "00") {
    eTarget = parseInt(event) + " % ";
  } else {
    eTarget = parseFloat(event).toFixed(2) + " % ";
  }
  return eTarget;
}

export function gererKg(event, unit) {
  kgLb = unit.toLowerCase();
  let eTarget = parseFloat(event).toFixed(2) + " kg ";
  if (kgLb === "lbs") {
    eTarget = parseFloat(event * 2.2046).toFixed(2) + " lb ";
  }
  let verif = eTarget.split(".");
  if (verif[1] && verif[1].includes("00")) {
    if (kgLb === "lbs") {
      eTarget = parseInt(event * 2.2046) + " lb ";
    } else {
      eTarget = parseInt(event) + " kg ";
    }
  }
  return eTarget;
}

export function convertLitter(event) {
  let galon = event * 3.785;
  return galon + " gal";
}

export function gererLitter(event) {
  let litter = event;
  return litter + " L";
}

export function convertF(celsius) {
  let fahrenheit = celsius * (9 / 5) + 32;
  return fahrenheit + " °F";
}

export function gererCelcius(event) {
  let celsius = event;
  return celsius + " °C";
}
