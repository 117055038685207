<template lang="pug">
footer.footer
    p © Copyright 2020 | Jyga Technologies inc. {{ $t('allRights')}}
</template>

<script>
export default {
  name: "cloud-footer"
};
</script>

<style lang="stylus" scoped>
.footer
  background-color var(--blue)
  bottom 0
  color white
  left calc(-50vw + 50%)
  padding 10px 10px 20px
  position relative
  text-align center
  width 100vw
  @media screen and (--min-tablette)
    padding-bottom 35px
</style>
