<template lang="pug">
.modal(v-if="condition")
  transition(v-if="condition" name="modal")
    .modal-mask
      .modal-wrapper
        .modal-container(@scroll="onScroll")
          slot
</template>

<script>
export default {
  name: "modal",
  props: {
    condition: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (Math.ceil(scrollTop + clientHeight) >= scrollHeight)
        this.$emit("isAtBottom", true);
      else this.$emit("isAtBottom", false);
    }
  }
};
</script>
